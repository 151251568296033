import React from "react";
import { useState, useEffect, useRef } from "react";
import Main from "../../../specific/components/Main/Main";
import i18n from "../../utils/Localization/Localization";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image,
    ScrollView,
    Modal,
    SafeAreaView,
    Animated,
    FlatList,
} from 'react-native';

import * as Navigation from "../../../specific/utils/Navigation/Navigation";

import AppTheme, { Theme } from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// UI
import Style from "../../utils/Style";

// Assets
import Images from "../../../specific/utils/Images";
import Button from "../../designSystem/Button";
import { createGiftForUser, getUserWithEmail } from "../../../specific/services/Specific_Database";
import * as DateUtils from "../../utils/DateUtils";
import { useNavigate } from "react-router-dom";

type SubProfile = "teacher" | "family" | "animator";

type sub = {
    auto_renewing: boolean,
    creationDate: number,
    date: number,
    expires_date: number,
    origin: string,
    product_id: string,
    start_date: number
}

type giftUser = {
    liliMember: string,
    userName: string,
    userEmail: string,
    card_code: string,
    subCode: string,
    subDurationInMonth: number,
    subProfile: SubProfile,
    sub: sub,
    userKey: string,
    canReceiveGift: boolean,
    message: string,
    giftCreated?: boolean,
    giftId?: string,
    status?: string,
    created: number,
    updated: number
}

export const subCaracteristics = {
    "LAUREAT_LAE1": {
        duration_in_monts: 1,
        product_id: "cool.lili.gift.laspremiumone",
        las: true, lae:false, lep:false
    },
    "LAUREAT_LAE3": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.laspremiumthree",
        las: true, lae:false, lep:false
    },
    "LAUREAT_LAE12": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.laspremiumtwelve",
        las: true, lae:false, lep:false
    },
    "LAUREAT_LAM1": {
        duration_in_monts: 1,
        product_id: "cool.lili.gift.lahpremiumone",
        las: false, lae:true, lep:false
    },
    "LAUREAT_LAM3": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.lahpremiumthree",
        las: false, lae:true, lep:false
    },
    "LAUREAT_LAM12": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.lahpremiumtwelve",
        las: false, lae:true, lep:false
    },
    "PROF_COMM": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.laspremiumthree",
        las: true, lae:false, lep:false
    },
    "PROF_RS": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.laspremiumthree",
        las: true, lae:false, lep:false
    },
    "PROF_AMB": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.laspremiumtwelve",
        las: true, lae:false, lep:false
    },
    "PARENTALITE_RS": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.lahpremiumthree",
        las: false, lae:true, lep:false
    },
    "PARENTALITE_AMB": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.lahpremiumtwelve",
        las: false, lae:true, lep:false
    },
    "JOURNALISTE_LAM": {
        duration_in_monts: 1,
        product_id: "cool.lili.gift.lahpremiumone",
        las: false, lae:true, lep:false
    },
    "JOURNALISTE_LAE": {
        duration_in_monts: 1,
        product_id: "cool.lili.gift.laspremiumone",
        las: true, lae:false, lep:false
    },
    "JOURNALISTE_AMB": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.lahpremiumtwelve",
        las: false, lae:true, lep:false
    },
    "INSTITUTIONNEL": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.laspremiumthree",
        las: true, lae:false, lep:false
    },
    "INSTITUTIONNEL_AMB": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.laspremiumtwelve",
        las: true, lae:false, lep:false
    },
    "PARTENAIRE": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.laspremiumthree",
        las: true, lae:false, lep:false
    },
    "PARTENAIRE_AMB": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.laspremiumtwelve",
        las: true, lae:false, lep:false
    },
    "LILI_FAMILY": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.laspremiumthree",
        las: true, lae:false, lep:false
    },
    "LILI_TEAM": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.laspremiumtwelve",
        las: true, lae:false, lep:false
    },
    "PETITCHAMPION_LAE2": {
        duration_in_monts: 2,
        product_id: "cool.lili.gift.laspremiumtwo",
        las: true, lae:false, lep:false
    },
    "LEP1": {
        duration_in_monts: 1,
        product_id: "cool.lili.gift.leppremiumone",
        las: false, lae:false, lep:true
    },
    "LEP3": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.leppremiumthree",
        las: false, lae:false, lep:true
    },
    "LEP12": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.leppremiumtwelve",
        las: false, lae:false, lep:true
    }
}

let giftCount = 0;

const AdminGiftTreatment = (props: any) => {
    // MainOptions
    const { componentId = "" } = props;
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const { data } = props;

    const appTheme: AppTheme = new AppTheme();

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [userList, setUserList] = useState<giftUser[]>([]);

    const onViewDidAppear = () => {

    }

    useEffect(() => {
        if (userList.length > 0) {
            setViewDidAppear(true);
        }
    }, [userList]);

    useEffect(() => {
        if (props.data.length > 0) {
            setViewDidAppear(false);
            treatUserList();
        }
    }, [props]);

    const treatUserList = async () => {
        let newUsers: giftUser[] = [];
        const dataAsJSON = JSON.parse(props.data);
        for (const aKeyIndex in dataAsJSON) {
            if (Object.prototype.hasOwnProperty.call(dataAsJSON, aKeyIndex)) {
                const aUserDataArray = dataAsJSON[aKeyIndex];
                let userEmail: string = aUserDataArray["E-mail"];
                if (userEmail !== undefined) {
                    userEmail = userEmail.toLocaleLowerCase();
                    userEmail = userEmail.trim();
                    let userData = [];
                    if (userEmail.length > 0) {
                        const userFirebaseData = await getUserWithEmail({ user_email: userEmail });
                        if (userFirebaseData !== undefined) {
                            userData = userFirebaseData;
                        }
                    }
                    let userKey = "";
                    let canReceiveGift = false;
                    let message = "";
                    const today = new Date().getTime();
                    let user = [];
                    if (userData.length === 1) {
                        const userDBData = userData[0].data;
                        userKey = userData[0].key;
                        if (userDBData.sub === undefined) {
                            canReceiveGift = true;
                        } else if (userDBData.sub.expires_date < today) {
                            canReceiveGift = true;
                        } else {
                            message = "Cet utilisateur a déjà un abonnement actif:\nType d'abo : " + userDBData.sub.product_id + "\nDate de fin d'abo : " + DateUtils.getFullDateForTimestamp(userDBData.sub.expires_date);
                        }
                    } else if (userData.length === 0) {
                        canReceiveGift = true;
                    } else {
                        canReceiveGift = false;
                        message = "Cet utilisateur a plusieurs comptes :";
                        for (const aUserFBDataIndex in userData) {
                            if (Object.prototype.hasOwnProperty.call(userData, aUserFBDataIndex)) {
                                const aUserFBData = userData[aUserFBDataIndex];
                                message = message + "\n" + aUserFBData.key;
                            }
                        }
                    }
                    const subCode = aUserDataArray["Type"];
                    let subDurationInMonth = 1;
                    let subProductId = "cool.lili.gift.lahpremiumone";
                    let subProfile: SubProfile = "family";
                    if (subCaracteristics[subCode] !== undefined) {
                        subDurationInMonth = subCaracteristics[subCode].duration_in_monts;
                        subProductId = subCaracteristics[subCode].product_id;
                        subProfile = subCaracteristics[subCode].las === true ? "teacher" : subCaracteristics[subCode].lae === true ? "family" : "animator";
                     }
                    const todayDate = new Date();
                    const expireDate = new Date(todayDate.setMonth(todayDate.getMonth() + subDurationInMonth));
                    let card_code = "";
                    if (userEmail.length === 0) {
                        canReceiveGift = false;
                        message = "Cet utilisateur n'a pas d'email renseigné";
                    }
                    if (canReceiveGift === true) {
                        giftCount = giftCount + 1;
                        card_code = today.toString();
                        card_code = card_code + giftCount.toString();
                        card_code = card_code.replace("1", "I");
                        card_code = card_code.replace("4", "A");
                        card_code = card_code.replace("3", "E");
                        card_code = card_code.replace("7", "L");
                        card_code = card_code.replace("0", "O");
                    }
                    const newUser: giftUser = {
                        liliMember: aUserDataArray["Demandeur"],
                        userName: aUserDataArray["Destinataire (Prénom Nom)"],
                        userEmail,
                        subCode,
                        subDurationInMonth,
                        subProfile,
                        card_code,
                        sub: {
                            auto_renewing: false,
                            origin: "LILI_GIFT",
                            creationDate: new Date().getTime(),
                            date: new Date().getTime(),
                            expires_date: expireDate.getTime(),
                            product_id: subProductId,
                            start_date: new Date().getTime(),
                        },
                        canReceiveGift,
                        userKey,
                        message,
                        created: new Date().getTime(),
                        updated: new Date().getTime()
                    };
                    newUsers.push(newUser);
                }
            }
        }
        setUserList(newUsers);
    }

    const keyExtractor = (item, index) => {
        return (index.toString());
    }

    const aboutCell = ({ item, index }) => {
        const user: giftUser = item;
        const subTitle = user.subProfile === "teacher" ? "Abonnement LILI à l'école" : user.subProfile === "family" ? "Abonnement LILI à la maison" : "Abonnement LILI en Périscolaire";
        const cellColor = user.canReceiveGift === false ? appTheme.alertOverlayColor : appTheme.grow;
        let giftStatus = <View />;
        if (user.giftCreated !== undefined) {
            let giftStatusLabel = user.giftCreated === true ? "CADEAU CRÉÉ" : "ERREUR LORS DE LA CRÉATION DU CADEAU";
            if (user.giftId !== "") {
                giftStatusLabel = giftStatusLabel + "\n" + user.giftId;
            }
            giftStatus = <Text style={{ color: cellColor, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), marginTop: appTheme.pixelPerfect(5) }}>
                {giftStatusLabel}
            </Text>
        }
        return (
            <View style={{ width: appTheme.getFullAppWidth(), marginTop: appTheme.pixelPerfect(10), justifyContent: 'center', alignItems: 'center' }}>
                <View style={{ flexDirection: 'row', width: appTheme.getBlockWidth(), backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(21), alignItems: 'center', padding: appTheme.pixelPerfect(10), borderWidth: 1, borderColor: cellColor }}>
                    <View style={{ flex: 1, paddingLeft: appTheme.pixelPerfect(10), justifyContent: 'space-around' }}>
                        <Text style={{ color: cellColor, fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(10) }}>
                            {user.userName.toLocaleUpperCase()}
                        </Text>
                        <Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(8) }}>
                            {user.userEmail + " " + user.userKey}
                        </Text>
                        <Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(8) }}>
                            {subTitle}
                        </Text>
                        <Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(6) }}>
                            {user.subCode + " / " + user.sub.product_id + " / " + user.subDurationInMonth + " mois"}
                        </Text>
                        <Text style={{ color: cellColor, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), marginTop: appTheme.pixelPerfect(5) }}>
                            {user.message}
                        </Text>
                        {giftStatus}
                    </View>
                </View>
            </View>
        )
    }

    const sendInvitation = async () => {
        let userListUpdated = [];
        for (const aUserDataIndex in userList) {
            if (Object.prototype.hasOwnProperty.call(userList, aUserDataIndex)) {
                let aUserData = userList[aUserDataIndex];
                if (aUserData.canReceiveGift) {
                    aUserData.status = "created";
                    const isGiftCreated = await createGiftForUser({ gift: aUserData });
                    aUserData.giftCreated = isGiftCreated.isCreated;
                    aUserData.giftId = isGiftCreated.id;
                }
                userListUpdated.push(aUserData);
            }
        }
        if (props.onCreationCompleted !== undefined) {
            props.onCreationCompleted(userListUpdated);
        }
        setUserList(userListUpdated);
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View />
        }
        const actionButton = userList.length > 0 ? <View style={{ marginVertical: appTheme.pixelPerfect(10), width: appTheme.getBlockWidth() }}>
            <Button title={"Envoyer"} onPress={sendInvitation} />
        </View> : <View />;
        return <View style={{ flex: 1, alignItems: "center", width: appTheme.getBlockWidth() }}>
            {actionButton}
            <FlatList
                data={userList}
                renderItem={aboutCell}
                keyExtractor={keyExtractor}
                style={{ flex: 1 }} />
        </View >;
    }

    return (
        <View onLayout={onViewDidAppear} style={[styles.main]}>
            {getContent()}
        </View>
    )
}

export default AdminGiftTreatment;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#fff",
        marginTop:20,
        borderRadius: 10
    },
    switchMenu: {
        position: "absolute",
        left: 0,
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "flex-start"
    }
});