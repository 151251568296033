import React from "react";

import WebModal from "react-modal";

interface ModalProps {
    visible: boolean,
    animationType?: string,
    transparent?: boolean,
    style: any
}

const Modal = (props: ModalProps) => {

    const { visible, animationType = "", transparent = false, style } = props;

    const backgroundColor: string = transparent === true ? "rgba(0,0,0,0)" : 'rgba(255, 255, 255, 0.75)';

    return <WebModal
        style={{
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor
            }
        }}
        ariaHideApp={false}
        isOpen={visible}>
        {props.children}
    </WebModal>
}

export default Modal;