import React, { useEffect, useRef, useState } from "react";

import {
  View,
  Text,
  TouchableOpacity,
  TextStyle,
  TextInput,
  Image,
  Dimensions,
  Modal,
  Platform,
  Animated
} from "react-native";
import AppTheme from "../../utils/Theme";
import Divider from "../../designSystem/Divider/Divider";

import Select from 'react-select';

import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import { getSessionsActivities, getSessionsCategories } from "../../../specific/utils/LocalStorage";
import Button from "../../designSystem/Button";
import Activity from "../../models/Activity";
import RadioButton from "../../designSystem/RadioButton";
import { app, setDBActivity } from "../../../specific/services/Specific_Database";
import { resizeFile, updloadActivityAudio, updloadActivityImage, updloadImage, updloadMediaAudio } from "../../../specific/services/Specific_Storage";
import ActivityPlayer from "../Audio/ActivityPlayer";
import { getLanguage } from "../../utils/Localization/Localization";

import { default as IcPlay } from "../../assets/svg/icons/solid/play.svg";
import { default as IcTrash } from "../../assets/svg/lili/ic_trash.svg";
import { default as IcUpload } from "../../assets/svg/icons/solid/paper-plane-solid.svg";
import SVGView from "../../utils/SvgView";
import Alert from "../Notification/Alert";
import Images from "../../../specific/utils/Images";

const activitiesKeyIndexByCategoryKey = {
  "game_to_build": "JAC",
  "yoga": "YOG",
  "my_emotion": "EMO",
  "breath": "RESP",
  "others": "AUT",
  "dedicaces": "DED",
  "meditation": "MED",
  "emotion_game": "JEM",
  "finger_yoga": "YDD",
  "concentration_game": "JAT",
  "impro_game": "JIM",
  "my_entourage": "PRO",
  "mime_game": "JMI",
  "introspection": "REG",
  "world": "MON",
  "poster_instructions": "PAC",
  "workshops": "WOK",
  "musical_yoga": "YOG",
  "school_advices": "PAC",
  "little_champions": "LPC",
  "virelangues": "VIR",
  "advicesForTheCoach": "ADV",
  "trainingWorkshops": "TWK",
  "superHeroesEquipments": "SHE",
  "children_philo_introduction": "SPC",
  "sleepMeditation": "MED",
  "routineSepUp": "ROU",
  "sleepMusic": "SLM",
  "phi_questions": "VQP",
  "wellBeing": "WLB",
  "onboarding_advices": "ADV",
  "super_posters": "POS",
  "super_activities": "WOK",
  "quotes": "QTS",
  "inspiringBio": "BIO",
  "inpireSportAndAdventure": "BIOSA",
  "inpireScienceAndInnovation": "BIOSI",
  "inspireArts": "BIOA",
  "inspireBookAndEducation": "BIOBE",
  "inspireMovieAndMusic": "BIOMM",
  "inspireNatureAndSpace": "BIONS",
  "inspirePolitic": "BIOP"
}

const languagesByKey = {
  "fr": "Français",
  "en": "Anglais",
  "es": "Espagnol",
  "it": "Italien",
  "pt": "Portugais",
  "ar": "Arabe",
  "de": "Allemand",
  "zh": "Chinois",
  "hi": "Hindi",
  "bn": "Bengali",
  "ru": "Russe",
  "ja": "Japonais",
  "ko": "Coréen",
  "vi": "Vietnamien",
  "ur": "Ourdou",
  "mr": "Marathi",
  "ta": "Tamoul",
  "tr": "Turc",
  "te": "Télougou",
  "fa": "Persan",
  "pa": "Pendjabi",
  "id": "Indonésien",
  "pl": "Polonais",
  "uk": "Ukrainien",
  "ro": "Roumain",
  "nl": "Néerlandais",
  "th": "Thaï",
  "ms": "Malais",
  "my": "Birman",
  "hu": "Hongrois",
  "el": "Grec",
  "cs": "Tchèque",
  "sv": "Suédois",
  "bg": "Bulgare",
  "ab": "Abkhaze",
  "aa": "Afar",
  "af": "Afrikaans",
  "ak": "Akan",
  "sq": "Albanais",
  "am": "Amharique",
  "an": "Aragonais",
  "hy": "Arménien",
  "as": "Assamais",
  "av": "Avar",
  "ae": "Avestique",
  "ay": "Aymara",
  "az": "Azéri",
  "bm": "Bambara",
  "ba": "Bachkir",
  "eu": "Basque",
  "be": "Biélorusse",
  "bh": "Bihari",
  "bi": "Bichelamar",
  "bs": "Bosnien",
  "br": "Breton",
  "ca": "Catalan",
  "ch": "Chamorro",
  "ce": "Tchétchène",
  "ny": "Chichewa",
  "cu": "Slavon d'église",
  "cv": "Tchouvache",
  "kw": "Cornique",
  "co": "Corse",
  "cr": "Cree",
  "hr": "Croate",
  "da": "Danois",
  "dv": "Divehi",
  "dz": "Dzongkha",
  "eo": "Espéranto",
  "et": "Estonien",
  "ee": "Ewe",
  "fo": "Féroïen",
  "fj": "Fidjien",
  "fi": "Finnois",
  "ff": "Peul",
  "gl": "Galicien",
  "ka": "Géorgien",
  "gn": "Guarani",
  "gu": "Gujarati",
  "ht": "Haïtien",
  "ha": "Haoussa",
  "he": "Hébreu",
  "hz": "Herero",
  "ho": "Hiri Motu",
  "ia": "Interlingua",
  "ie": "Interlingue",
  "ga": "Irlandais",
  "ig": "Igbo",
  "ik": "Inupiaq",
  "io": "Ido",
  "is": "Islandais",
  "iu": "Inuktitut",
  "jv": "Javanais",
  "kl": "Groenlandais",
  "kn": "Kannada",
  "kr": "Kanouri",
  "ks": "Kashmiri",
  "kk": "Kazakh",
  "km": "Khmer",
  "ki": "Kikuyu",
  "rw": "Kinyarwanda",
  "ky": "Kirghiz",
  "kv": "Komi",
  "kg": "Kongo",
  "kj": "Kwanyama",
  "ku": "Kurde",
  "lo": "Laotien",
  "la": "Latin",
  "lv": "Letton",
  "li": "Limbourgeois",
  "ln": "Lingala",
  "lt": "Lituanien",
  "lu": "Luba-Katanga",
  "lg": "Ganda",
  "lb": "Luxembourgeois",
  "mk": "Macédonien",
  "mg": "Malgache",
  "ml": "Malayâlam",
  "mt": "Maltais",
  "gv": "Mannois",
  "mi": "Maori",
  "mh": "Marshallais",
  "mn": "Mongol",
  "na": "Nauruan",
  "nv": "Navajo",
  "nd": "Ndébéle du Nord",
  "nr": "Ndébéle du Sud",
  "ng": "Ndonga",
  "ne": "Népalais",
  "no": "Norvégien",
  "ii": "Yi du Sichuan",
  "oc": "Occitan",
  "oj": "Ojibwé",
  "or": "Oriya",
  "om": "Oromo",
  "os": "Ossète",
  "pi": "Pâli",
  "ps": "Pachto",
  "qu": "Quechua",
  "rm": "Romanche",
  "rn": "Kirundi",
  "sa": "Sanskrit",
  "sc": "Sarde",
  "sd": "Sindhi",
  "se": "Sami du Nord",
  "sm": "Samoan",
  "sg": "Sango",
  "sr": "Serbe",
  "gd": "Gaélique écossais",
  "sn": "Shona",
  "si": "Singhalais",
  "sk": "Slovaque",
  "sl": "Slovène",
  "so": "Somali",
  "st": "Sotho du Sud",
  "su": "Soundanais",
  "sw": "Swahili",
  "ss": "Swati",
  "tl": "Tagalog",
  "tn": "Tswana",
  "to": "Tongien",
  "tk": "Turkmène",
  "ts": "Tsonga",
  "tt": "Tatar",
  "tw": "Twi",
  "ty": "Tahitien",
  "ug": "Ouïghour",
  "vo": "Volapük",
  "wa": "Wallon",
  "cy": "Gallois",
  "wo": "Wolof",
  "xh": "Xhosa",
  "yi": "Yiddish",
  "yo": "Yoruba",
  "za": "Zhuang",
  "zu": "Zoulou"
}


const gamingCategories: string[] = ["impro_game"];
const inspiringCategories: string[] = ["quotes", "inspiringBio", "inpireSportAndAdventure", "inpireScienceAndInnovation", "inspireArts", "inspireBookAndEducation", "inspireMovieAndMusic", "inspireNatureAndSpace", "inspirePolitic"];

interface LHQActivitiesManagementProps {
  goBack?: () => void
}

const LHQActivitiesManagement = (props: LHQActivitiesManagementProps) => {

  const { goBack } = props;

  const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
  const [categories, setCategories] = useState<any>(undefined);
  const [activities, setActivities] = useState<any>(undefined);
  const [category, setCategory] = useState<{ value: string, label: string, section: string } | undefined>(undefined);
  const [activityKey, setActivityKey] = useState<{ value: string, label: string } | undefined | null>(undefined);
  const [activity, setActivity] = useState<Activity | undefined>(undefined);
  const [newActivity, setNewActivity] = useState<boolean>(false);
  const [newImage, setNewImage] = useState<any>(undefined);
  const [newAudioUrls, setNewAudioUrls] = useState<{ file: any, language: string }[]>([]);
  const [launchPlayer, setLaunchPlayer] = useState<boolean>(false);
  const [selectedAudio, setSelectedAudio] = useState<string[]>([]);
  const [newAudioFilesForMedia, setNewAudioFilesForMedia] = useState<{ media_id: string, file: any, language: string }[]>([]);

  // ACCESS
  const [isFree, setIsFree] = useState<boolean>(false)
  const [isPremium, setIsPremium] = useState<boolean>(true)
  const [isPremiumChanged, setIsPremiumChanged] = useState<boolean>(false)

  // TITLE
  const [selectedTitleLanguage, setSelectedTitleLanguage] = useState<LanguageOption | null>(null);

  // INTORDUCTION
  const [selectedIntroductionLanguage, setSelectedIntroductionLanguage] = useState<LanguageOption | null>(null);

  const appTheme: AppTheme = new AppTheme();
  const images: Images = new Images();

  const alertRef = useRef<Alert>(null);

  const h1Style: TextStyle = {
    fontFamily: appTheme.primaryBoldFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(18)
  }

  const h2Style: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(16)
  }

  const h3Style: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(14)
  }

  const labelStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(10)
  }

  const mediaTitleStyle: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(10)
  }

  const attributeTitleStyle: TextStyle = {
    fontFamily: appTheme.primaryBoldFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(12),
    textDecorationLine: "underline"
  }

  const inputStyle: TextStyle = {
    height: appTheme.pixelPerfect(40),
    borderRadius: appTheme.pixelPerfect(10),
    borderWidth: 1,
    borderColor: appTheme.grow,
    padding: appTheme.pixelPerfect(4),
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(6)
  }

  const pStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.textColor,
    fontSize: appTheme.pixelPerfectForFont(14)
  }

  const aStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.textColor,
    fontSize: appTheme.pixelPerfectForFont(14),
    textDecorationLine: "underline"
  }

  const onViewWillAppear = () => {
    getCategories();
  }

  const getCategories = async () => {
    const sessionCategories = await getSessionsCategories();
    setCategories(sessionCategories);
  }

  const getActivities = async () => {
    const sessionActivities = await getSessionsActivities();
    setActivities(sessionActivities);
  }

  useEffect(() => {
    if (categories !== undefined) {
      getActivities();
    }
  }, [categories]);

  useEffect(() => {
    if (categories !== undefined) {
      setViewDidAppear(true);
    }
  }, [activities]);

  useEffect(() => {
    if (category !== undefined) {
      setActivityKey(undefined);
    }
  }, [category]);

  useEffect(() => {
    if (selectedAudio.length > 0) {
      setLaunchPlayer(true);
    } else {
      setLaunchPlayer(false);
    }
  }, [selectedAudio]);

  useEffect(() => {
    if (newActivity === true) {
      // En fonction de la catégorie, on va avoir certain champs disponible ou non
      // On va d'abord définir la clé de l'activité
      const activityKeyIndex: string = activitiesKeyIndexByCategoryKey[category.value];
      let newActivityKey: string = "";
      let activityNumber: number = 1;
      while (newActivityKey.length === 0) {
        let activityNumberAsString: string = activityNumber.toString();
        if (activityNumber < 10) {
          activityNumberAsString = "0" + activityNumberAsString;
        }
        const tempActivityKey: string = activityKeyIndex + "_" + activityNumberAsString;
        if (activities.data[tempActivityKey] === undefined) {
          newActivityKey = tempActivityKey;
        } else {
          activityNumber = activityNumber + 1;
        }
      }
      let action_type: "audio" | "game" | "audio_chapters" = "audio";
      let action_title: string = "écouter";
      if (gamingCategories.indexOf(category.value) !== -1) {
        action_type = "game";
        action_title = "jouer";
      } else if (inspiringCategories.indexOf(category.value) !== -1) {
        action_type = "audio_chapters";
        action_title = "écouter";
      }

      let activityToAdd: Activity = {
        key: newActivityKey,
        title: {
          fr: ""
        },
        section_key: category.section,
        access: {
          is_essential: isFree,
          is_premium: isPremium
        },
        action: {
          audio: {
            fr: []
          },
          title: {
            fr: action_title
          },
          type: action_type
        },
        body: {
          fr: ""
        },
        category_keys: [category.value],
        img_url: "",
        last_update: new Date().getTime(),
        media: [],
        min_build_number: 5790,
        order: 1,
        status: "draft",
        tags: []
      };
      if (category.value === "impro_game") {
        activityToAdd.duration = 360;
      }
      setActivity(activityToAdd);
    }
  }, [newActivity]);

  // RECUPÉRATION DE IS_PREMIUM DANS LA BD
  useEffect(() => {
    if (activity?.access.is_premium !== undefined) {
      setIsPremium(activity?.access.is_premium)
    }
  })

  // UPDATE AUTOMATIQUE DE LA QUALITÉ PAYANTE OU GRATUITE DE L'ACTIVITÉ
  useEffect(() => {
    let updatedActivity: Activity = { ...activity };
    updatedActivity.access = {
      is_essential: false,
      is_premium: isPremium
    }
    setActivity(updatedActivity);
  }, [isPremiumChanged]);

  useEffect(() => {
    if ((activityKey !== undefined) && (activityKey !== null)) {
      if (activities.data[activityKey.value] !== undefined) {
        setActivity(activities.data[activityKey.value]);
      }
    } else {
      setActivity(undefined);
    }
  }, [activityKey]);

  useEffect(() => {
    if (activity !== undefined) {
      if (category === undefined) {
        const categoryKey: string = activity.category_keys[0];
        if (categories.data[categoryKey] !== undefined) {
          const category = categories.data[categoryKey];
          setCategory({ value: categoryKey, label: category.title.fr, section: category.sectionId });
        }
      }
    }
  }, [activity]);

  // ********** SELECTION DE LA CATEGORIE **********
  const onCategorySelectionChange = (e: { value: string, label: string, section: string }) => {
    setCategory(e);
    setActivityKey(null);
  };

  const getCategoriesSelectInput = () => {
    let subTypeOptions: { value: string, label: string, section: string }[] = [];
    for (const anActivityTypeKey in categories.data) {
      if (Object.prototype.hasOwnProperty.call(categories.data, anActivityTypeKey)) {
        const element = categories.data[anActivityTypeKey];
        subTypeOptions.push({
          value: element.key,
          label: element.key + " - " + element.title.fr,
          section: element.sectionId
        })
      }
    }
    return (
      <Select
        value={category}
        onChange={(e) => onCategorySelectionChange(e)}
        options={subTypeOptions}
        placeholder={"Sélectionnez une categorie"}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: appTheme.pixelPerfect(30),
            minHeight: appTheme.pixelPerfect(30),
            borderRadius: appTheme.pixelPerfect(10),
            fontFamily: appTheme.primaryFont,
            borderColor: appTheme.grow,
            fontSize: appTheme.pixelPerfectForFont(6),
            display: "flex",
            alignItems: "center",
            paddingTop: 0,
            paddingBottom: 0,
            flexWrap: "unset",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            fontFamily: appTheme.primaryFont,
            fontSize: appTheme.pixelPerfectForFont(6),
          })
        }}
      />
    )
  };


  // ********** SELECTION DE LA L'ACTIVITÉ (À MODIFIER) DANS LA CATEGORIE PRÉCÉDEMMENT SÉLECTIONNÉE **********
  const getActivitiesSelectInput = () => {
    let subTypeOptions: { value: string, label: string, section: string }[] = [];
    for (const anActivityTypeKey in activities.data) {
      if (Object.prototype.hasOwnProperty.call(activities.data, anActivityTypeKey)) {
        const element = activities.data[anActivityTypeKey];
        let addActivity: boolean = false;
        if (category === undefined) {
          addActivity = true;
        } else if ((element.category_keys !== undefined) && (element.category_keys.indexOf(category.value) !== -1)) {
          addActivity = true;
        }
        if (addActivity === true) {
          subTypeOptions.push({
            value: element.key,
            label: element.key + " - " + element.title.fr,
            section: element.sectionId
          });
        }
      }
    }
    return (
      <Select
        value={activityKey}
        onChange={(e) => setActivityKey(e)}
        options={subTypeOptions}
        placeholder={"Sélectionnez une activité"}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: appTheme.pixelPerfect(30),
            minHeight: appTheme.pixelPerfect(30),
            borderRadius: appTheme.pixelPerfect(10),
            fontFamily: appTheme.primaryFont,
            borderColor: appTheme.grow,
            fontSize: appTheme.pixelPerfectForFont(6),
            display: "flex",
            alignItems: "center",
            paddingTop: 0,
            paddingBottom: 0,
            flexWrap: "unset",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            fontFamily: appTheme.primaryFont,
            fontSize: appTheme.pixelPerfectForFont(6),
          })
        }}
      />
    )
  };

  //********** PARTIE POUR MODIFIER LA QUALITÉ GRATUITE OU PREMIUM DE L'ACTIVITÉ **********
  const [animatedValue] = useState(new Animated.Value(0));

  const toggleSwitch = () => {
    setIsPremium(!isPremium);
    setIsPremiumChanged(!isPremiumChanged)
    // Animation du bouton
    Animated.timing(animatedValue, {
      toValue: isPremium ? 1 : 0,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  const getSwitchButtonPremiumness = () => {
    const thumbPosition = animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [28, 2], // Les positions de départ et d'arrivée du bouton
    });

    return (
      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: '#fff', marginBottom: appTheme.pixelPerfect(10) }} >
        <TouchableOpacity onPress={toggleSwitch}>
          <View style={{
            // Style pour le track
            width: appTheme.pixelPerfect(50),
            height: appTheme.pixelPerfect(25),
            borderRadius: appTheme.pixelPerfect(25),
            backgroundColor: isPremium === true ? '#117864' : '#d0d3d4',
            padding: 2,
            justifyContent: 'center',
          }}>
            <Animated.View
              style={{
                // Style pour le bouton rond (thumb)
                width: appTheme.pixelPerfect(20),
                height: appTheme.pixelPerfect(20),
                borderRadius: appTheme.pixelPerfect(10),
                backgroundColor: "#f4f3f4",
                transform: [{ translateX: thumbPosition }]
              }}
            />
          </View>
        </TouchableOpacity>
        <Text style={[labelStyle, { paddingHorizontal: appTheme.pixelPerfect(20) }]}>{isPremium === true ? "Activité payante" : "Activité gratuite"}</Text>
      </View>
    )
  };

  //********** PARTIE POUR LA GESTION DES TITRES AVEC SES DIFFÉRENTES LANGUES **********
  type LanguageOption = {
    value: string;
    label: string
  };

  const languageOptions: LanguageOption[] = [
    { "value": "fr", "label": "Français" },
    { "value": "en", "label": "Anglais" },
    { "value": "de", "label": "Allemand" },
    { "value": "es", "label": "Espagnol" },
    { "value": "it", "label": "Italien" },
    { "value": "pt", "label": "Portugais" },
    { "value": "ar", "label": "Arabe" },
    { "value": "zh", "label": "Chinois" },
    { "value": "hi", "label": "Hindi" },
    { "value": "bn", "label": "Bengali" },
    { "value": "ru", "label": "Russe" },
    { "value": "ja", "label": "Japonais" },
    { "value": "ko", "label": "Coréen" },
    { "value": "vi", "label": "Vietnamien" },
    { "value": "ur", "label": "Ourdou" },
    { "value": "mr", "label": "Marathi" },
    { "value": "ta", "label": "Tamoul" },
    { "value": "tr", "label": "Turc" },
    { "value": "te", "label": "Télougou" },
    { "value": "fa", "label": "Persan" },
    { "value": "pa", "label": "Pendjabi" },
    { "value": "id", "label": "Indonésien" },
    { "value": "pl", "label": "Polonais" },
    { "value": "uk", "label": "Ukrainien" },
    { "value": "ro", "label": "Roumain" },
    { "value": "nl", "label": "Néerlandais" },
    { "value": "th", "label": "Thaï" },
    { "value": "ms", "label": "Malais" },
    { "value": "my", "label": "Birman" },
    { "value": "hu", "label": "Hongrois" },
    { "value": "el", "label": "Grec" },
    { "value": "cs", "label": "Tchèque" },
    { "value": "sv", "label": "Suédois" },
    { "value": "bg", "label": "Bulgare" },
    { "value": "ab", "label": "Abkhaze" },
    { "value": "aa", "label": "Afar" },
    { "value": "af", "label": "Afrikaans" },
    { "value": "ak", "label": "Akan" },
    { "value": "sq", "label": "Albanais" },
    { "value": "am", "label": "Amharique" },
    { "value": "an", "label": "Aragonais" },
    { "value": "hy", "label": "Arménien" },
    { "value": "as", "label": "Assamais" },
    { "value": "av", "label": "Avar" },
    { "value": "ae", "label": "Avestique" },
    { "value": "ay", "label": "Aymara" },
    { "value": "az", "label": "Azéri" },
    { "value": "bm", "label": "Bambara" },
    { "value": "ba", "label": "Bachkir" },
    { "value": "eu", "label": "Basque" },
    { "value": "be", "label": "Biélorusse" },
    { "value": "bh", "label": "Bihari" },
    { "value": "bi", "label": "Bichelamar" },
    { "value": "bs", "label": "Bosnien" },
    { "value": "br", "label": "Breton" },
    { "value": "ca", "label": "Catalan" },
    { "value": "ch", "label": "Chamorro" },
    { "value": "ce", "label": "Tchétchène" },
    { "value": "ny", "label": "Chichewa" },
    { "value": "cu", "label": "Slavon d'église" },
    { "value": "cv", "label": "Tchouvache" },
    { "value": "kw", "label": "Cornique" },
    { "value": "co", "label": "Corse" },
    { "value": "cr", "label": "Cree" },
    { "value": "hr", "label": "Croate" },
    { "value": "da", "label": "Danois" },
    { "value": "dv", "label": "Divehi" },
    { "value": "dz", "label": "Dzongkha" },
    { "value": "eo", "label": "Espéranto" },
    { "value": "et", "label": "Estonien" },
    { "value": "ee", "label": "Ewe" },
    { "value": "fo", "label": "Féroïen" },
    { "value": "fj", "label": "Fidjien" },
    { "value": "fi", "label": "Finnois" },
    { "value": "ff", "label": "Peul" },
    { "value": "gl", "label": "Galicien" },
    { "value": "ka", "label": "Géorgien" },
    { "value": "gn", "label": "Guarani" },
    { "value": "gu", "label": "Gujarati" },
    { "value": "ht", "label": "Haïtien" },
    { "value": "ha", "label": "Haoussa" },
    { "value": "he", "label": "Hébreu" },
    { "value": "hz", "label": "Herero" },
    { "value": "ho", "label": "Hiri Motu" },
    { "value": "ia", "label": "Interlingua" },
    { "value": "ie", "label": "Interlingue" },
    { "value": "ga", "label": "Irlandais" },
    { "value": "ig", "label": "Igbo" },
    { "value": "ik", "label": "Inupiaq" },
    { "value": "io", "label": "Ido" },
    { "value": "is", "label": "Islandais" },
    { "value": "iu", "label": "Inuktitut" },
    { "value": "jv", "label": "Javanais" },
    { "value": "kl", "label": "Groenlandais" },
    { "value": "kn", "label": "Kannada" },
    { "value": "kr", "label": "Kanouri" },
    { "value": "ks", "label": "Kashmiri" },
    { "value": "kk", "label": "Kazakh" },
    { "value": "km", "label": "Khmer" },
    { "value": "ki", "label": "Kikuyu" },
    { "value": "rw", "label": "Kinyarwanda" },
    { "value": "ky", "label": "Kirghiz" },
    { "value": "kv", "label": "Komi" },
    { "value": "kg", "label": "Kongo" },
    { "value": "kj", "label": "Kwanyama" },
    { "value": "ku", "label": "Kurde" },
    { "value": "lo", "label": "Laotien" },
    { "value": "la", "label": "Latin" },
    { "value": "lv", "label": "Letton" },
    { "value": "li", "label": "Limbourgeois" },
    { "value": "ln", "label": "Lingala" },
    { "value": "lt", "label": "Lituanien" },
    { "value": "lu", "label": "Luba-Katanga" },
    { "value": "lg", "label": "Ganda" },
    { "value": "lb", "label": "Luxembourgeois" },
    { "value": "mk", "label": "Macédonien" },
    { "value": "mg", "label": "Malgache" },
    { "value": "ml", "label": "Malayâlam" },
    { "value": "mt", "label": "Maltais" },
    { "value": "gv", "label": "Mannois" },
    { "value": "mi", "label": "Maori" },
    { "value": "mh", "label": "Marshallais" },
    { "value": "mn", "label": "Mongol" },
    { "value": "na", "label": "Nauruan" },
    { "value": "nv", "label": "Navajo" },
    { "value": "nd", "label": "Ndébéle du Nord" },
    { "value": "nr", "label": "Ndébéle du Sud" },
    { "value": "ng", "label": "Ndonga" },
    { "value": "ne", "label": "Népalais" },
    { "value": "no", "label": "Norvégien" },
    { "value": "ii", "label": "Yi du Sichuan" },
    { "value": "oc", "label": "Occitan" },
    { "value": "oj", "label": "Ojibwé" },
    { "value": "or", "label": "Oriya" },
    { "value": "om", "label": "Oromo" },
    { "value": "os", "label": "Ossète" },
    { "value": "pi", "label": "Pâli" },
    { "value": "ps", "label": "Pachto" },
    { "value": "qu", "label": "Quechua" },
    { "value": "rm", "label": "Romanche" },
    { "value": "rn", "label": "Kirundi" },
    { "value": "sa", "label": "Sanskrit" },
    { "value": "sc", "label": "Sarde" },
    { "value": "sd", "label": "Sindhi" },
    { "value": "se", "label": "Sami du Nord" },
    { "value": "sm", "label": "Samoan" },
    { "value": "sg", "label": "Sango" },
    { "value": "sr", "label": "Serbe" },
    { "value": "gd", "label": "Gaélique écossais" },
    { "value": "sn", "label": "Shona" },
    { "value": "si", "label": "Singhalais" },
    { "value": "sk", "label": "Slovaque" },
    { "value": "sl", "label": "Slovène" },
    { "value": "so", "label": "Somali" },
    { "value": "st", "label": "Sotho du Sud" },
    { "value": "su", "label": "Soundanais" },
    { "value": "sw", "label": "Swahili" },
    { "value": "ss", "label": "Swati" },
    { "value": "tl", "label": "Tagalog" },
    { "value": "tn", "label": "Tswana" },
    { "value": "to", "label": "Tongien" },
    { "value": "tk", "label": "Turkmène" },
    { "value": "ts", "label": "Tsonga" },
    { "value": "tt", "label": "Tatar" },
    { "value": "tw", "label": "Twi" },
    { "value": "ty", "label": "Tahitien" },
    { "value": "ug", "label": "Ouïghour" },
    { "value": "vo", "label": "Volapük" },
    { "value": "wa", "label": "Wallon" },
    { "value": "cy", "label": "Gallois" },
    { "value": "wo", "label": "Wolof" },
    { "value": "xh", "label": "Xhosa" },
    { "value": "yi", "label": "Yiddish" },
    { "value": "yo", "label": "Yoruba" },
    { "value": "za", "label": "Zhuang" },
    { "value": "zu", "label": "Zoulou" }
  ];

  const onAddNewTitle = () => {
    if (selectedTitleLanguage) {
      if (activity.title.length === 0 || activity.title[selectedTitleLanguage.value] === undefined) {
        onChangeTitle({ lang: selectedTitleLanguage.value, value: "" });
      }
      setSelectedTitleLanguage(null); // Réinitialise la sélection
    }
  };

  const onChangeTitle = (data: { lang: string, value: string }) => {
    const { lang, value } = data;
    let updatedActivity: Activity = { ...activity };
    let updatedTitle = updatedActivity.title;
    updatedTitle[lang] = value;
    updatedActivity.title = updatedTitle;
    setActivity(updatedActivity);
  }

  const getNewTitleLanguageSelector = () => {
    return (
      <View style={{ width: "100%", zIndex: 3 }}>
        <View style={{ display: "flex", flexDirection: "row", width: "100%", zIndex: 3, marginBottom: appTheme.pixelPerfect(10) }}>
          <View style={{ width: "20%", zIndex: 4 }} >
            <Select
              value={selectedTitleLanguage}
              onChange={(selectedOption) => setSelectedTitleLanguage(selectedOption)}
              options={languageOptions}
              placeholder={"Sélectionner la langue"}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: appTheme.pixelPerfect(30),
                  minHeight: appTheme.pixelPerfect(30),
                  borderRadius: appTheme.pixelPerfect(10),
                  fontFamily: appTheme.primaryFont,
                  borderColor: appTheme.grow,
                  fontSize: appTheme.pixelPerfectForFont(6),
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 0,
                  paddingBottom: 0,
                  flexWrap: "unset",
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                  ...styles,
                  fontFamily: appTheme.primaryFont,
                  fontSize: appTheme.pixelPerfectForFont(6),
                })
              }}
            />
          </View>
          <View style={{ width: "20%", marginLeft: appTheme.pixelPerfect(10) }}>
            <Button
              onPress={() => onAddNewTitle()}
              title={"Ajouter une langue"}
              style={{ height: appTheme.pixelPerfect(30) }}
              textStyle={{ fontSize: appTheme.pixelPerfectForFont(6) }}
            />
          </View>
        </View>
      </View>
    );
  };

  const getTitles = () => {
    let titleViews = [];
    for (const aTitleKey in activity.title) {
      if (Object.prototype.hasOwnProperty.call(activity.title, aTitleKey)) {
        const aTitleTrad: string = activity.title[aTitleKey];
        titleViews.push(
          <View key={aTitleKey} style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginBottom: appTheme.pixelPerfect(10) }}>
            <View style={{ width: "20%", marginRight: appTheme.pixelPerfect(10), alignItems: "flex-start" }}>
              <Text style={[labelStyle, { verticalAlign: "top" }]}>{languagesByKey[aTitleKey] + " : "}</Text>
            </View>
            <View style={{ width: "80%" }}>
              <TextInput onChangeText={(e) => onChangeTitle({ lang: aTitleKey, value: e })} value={aTitleTrad} style={[inputStyle, { height: appTheme.pixelPerfect(30), width: "100%" }]} />
            </View>
          </View>
        )
      }
    }
    return titleViews;
  };

  //********** PARTIE POUR LA GESTION DES INTRODUCTIONS (BODY) AVEC SES DIFFÉRENTES LANGUES **********
  const onAddNewIntroduction = () => {
    if (selectedIntroductionLanguage) {
      if (activity.body.length === 0 || activity.body[selectedIntroductionLanguage.value] === undefined) {
        onChangeBody({ lang: selectedIntroductionLanguage.value, value: "" })
      }
      setSelectedIntroductionLanguage(null); // Réinitialise la sélection
    }
  };

  const onChangeBody = (data: { lang: string, value: string }) => {
    const { lang, value } = data;
    let updatedActivity: Activity = { ...activity };
    let updatedBody = updatedActivity.body;
    updatedBody[lang] = value;
    updatedActivity.body = updatedBody;
    setActivity(updatedActivity);
  };

  const getNewIntroductionLanguageSelector = () => {
    return (
      <View style={{ width: "100%", zIndex: 2 }}>
        <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: appTheme.pixelPerfect(10), zIndex: 2 }}>
          <View style={{ width: "20%" }} >
            <Select
              value={selectedIntroductionLanguage}
              onChange={(selectedOption) => setSelectedIntroductionLanguage(selectedOption)}
              options={languageOptions}
              placeholder={"Sélectionner la langue"}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: appTheme.pixelPerfect(30),
                  minHeight: appTheme.pixelPerfect(30),
                  borderRadius: appTheme.pixelPerfect(10),
                  fontFamily: appTheme.primaryFont,
                  borderColor: appTheme.grow,
                  fontSize: appTheme.pixelPerfectForFont(6),
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 0,
                  paddingBottom: 0,
                  flexWrap: "unset",
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                  ...styles,
                  fontFamily: appTheme.primaryFont,
                  fontSize: appTheme.pixelPerfectForFont(6),
                })
              }}
            />
          </View>
          <View style={{ width: "20%", marginLeft: appTheme.pixelPerfect(10) }}>
            {/* Bouton Ajouter */}
            <Button
              onPress={() => onAddNewIntroduction()}
              title={"Ajouter une langue"}
              style={{ height: appTheme.pixelPerfect(30) }}
              textStyle={{ fontSize: appTheme.pixelPerfectForFont(6) }}
            />
          </View>
        </View>
      </View>
    )
  };

  const getBodies = () => {
    let bodyViews = [];
    for (const aBodyKey in activity.body) {
      if (Object.prototype.hasOwnProperty.call(activity.body, aBodyKey)) {
        const aBodyTrad: string = activity.body[aBodyKey];
        bodyViews.push(
          <View key={aBodyKey} style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: appTheme.pixelPerfect(10), height: appTheme.pixelPerfect(100) }}>
            <View style={{ width: "20%", marginRight: appTheme.pixelPerfect(10) }}>
              <Text style={labelStyle}>{languagesByKey[aBodyKey] + " : "}</Text>
            </View>
            <View style={{ width: "80%" }}>
              <TextInput
                value={aBodyTrad}
                onChangeText={(e) => onChangeBody({ lang: aBodyKey, value: e })}
                multiline={true}
                style={[inputStyle, { height: appTheme.pixelPerfect(100), width: "100%" }]} />
            </View>
          </View>
        )
      }
    }
    return bodyViews;
  };


  //********** PARTIE POUR LA SAISIE / MODIFICATION DE LA DURÉE **********
  const onChangeDuration = (data: { value: string }) => {
    const { value } = data;
    let updatedActivity: Activity = { ...activity };
    updatedActivity.duration = value;
    setActivity(updatedActivity);
  };

  const getDuration = () => {
    let duration: string = "60";
    if (activity.duration !== undefined) {
      duration = activity.duration;
    }
    return <View key={"duration"} style={{ flexDirection: "row", alignItems: "center", height: appTheme.pixelPerfect(30) }}>
      <Text style={[labelStyle, { marginEnd: appTheme.pixelPerfect(10) }]}>{"En secondes :"}</Text>
      <TextInput onChangeText={(e) => onChangeDuration({ value: e })} value={duration} style={[inputStyle, { width: appTheme.pixelPerfect(100), height: appTheme.pixelPerfect(30) }]} />
    </View>;
  };


  //********** PARTIE POUR LA SELECTION DES IMAGES **********
  const handleImageChange = (e) => {
    setNewImage(e.target.files[0]);
  };

  const getImage = () => {
    let imageView = <Text style={labelStyle}>Aucune image</Text>;
    if ((activity.img_url !== undefined) && (activity.img_url.length > 0)) {
      imageView = <Image source={{ uri: activity.img_url }} style={{ width: appTheme.pixelPerfect(180), height: appTheme.pixelPerfect(135) }} resizeMode="contain" />;
    }

    let newImageView = <View />;

    let separatorView = <View />;

    if (newImage !== undefined) {
      const imageSource: string = URL.createObjectURL(newImage);
      newImageView = <Image source={{ uri: imageSource }} style={{ width: appTheme.pixelPerfect(180), height: appTheme.pixelPerfect(135) }} resizeMode="contain" />;
      separatorView = <View style={{ width: appTheme.pixelPerfect(40), alignItems: "center", justifyContent: "center", paddingHorizontal: appTheme.pixelPerfect(20) }}>
        <Text style={{ fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue }}>{" >"}</Text>
      </View>
    }

    return <View>
      <View style={{ flexDirection: "row" }}>
        {imageView}
        {separatorView}
        {newImageView}
      </View>
      <input type="file" onChange={handleImageChange} />
    </View>
  }

  //********** PARTIE POUR LA SÉLECTION DES TAGS **********
  const updateTag = (aTag: string) => {
    let updatedActivity: Activity = { ...activity };
    let tagArray = updatedActivity.tags;
    if (tagArray.indexOf(aTag) !== -1) {
      // On retire le tag
      tagArray.splice(tagArray.indexOf(aTag), 1);
    } else {
      // On ajout le tag
      tagArray.push(aTag);
    }
    updatedActivity.tags = tagArray;
    setActivity(updatedActivity);
  }

  const getTags = () => {
    let tagsSelection = [];
    const tagsArray: any[] = [
      {
        value: "emotionsTaming",
        name: "Apprivoiser mes émotions"
      },
      {
        value: "focus",
        name: "Muscler ma concentration"
      },
      {
        value: "errorTaming",
        name: "Dompter l'erreur"
      },
      {
        value: "speak",
        name: "Oser à l'oral"
      },
      {
        value: "collective",
        name: "La jouer collectif"
      },
      {
        value: "criticalThinking",
        name: "Réflechir sur la vie"
      },
      {
        value: "selfEsteem",
        name: "Croire en moi"
      }
    ];
    for (const aTagIndex in tagsArray) {
      if (Object.prototype.hasOwnProperty.call(tagsArray, aTagIndex)) {
        const aTag = tagsArray[aTagIndex];
        let isSelected: boolean = activity.tags.indexOf(aTag.value) !== -1;
        tagsSelection.push(
          <View key={aTagIndex} style={{ width: appTheme.pixelPerfect(250), marginBottom: appTheme.pixelPerfect(5) }} >
            <RadioButton value={isSelected} style={{ width: appTheme.pixelPerfect(200) }} centerButton={true} onPress={() => updateTag(aTag.value)}>
              <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(9) }}>{aTag.name}</Text>
            </RadioButton>
          </View>
        )
      }
    }
    return tagsSelection;
  };

  const dbUpdateActivity = async () => {
    let updatedActivity: Activity = { ...activity };
    if (newImage !== undefined) {
      const image_url: string = await updloadActivityImage({ imageToUpload: newImage, categoryKey: category.value, activityKey: activity.key });
      updatedActivity.img_url = image_url;
    };
    updatedActivity.last_update = new Date().getTime();

    //PERMET D'UPDATER INDIVIDUELLEMENT LES DONNÉES DE L'ACTIVITÉ SÉLECTIONNER
    await setDBActivity({ key: updatedActivity.key, value: updatedActivity });
    setActivityKey(undefined);

    //PERMET DE RÉINITIALISER TOUTE LA SELECTION
    setViewDidAppear(false)

    setNewImage(undefined);
  };


  const addNewMedia = () => {
    let updatedActivity: Activity = { ...activity };
    const indexOfTheNewMedia: number = updatedActivity.media.length + 1;
    let suffixForTheMedia: string = indexOfTheNewMedia.toString();
    if (indexOfTheNewMedia < 10) {
      suffixForTheMedia = "0" + suffixForTheMedia;
    }
    let keyForTheNewMedia: string = updatedActivity.key + "_" + suffixForTheMedia;
    if (category.value === "quotes") {
      const newMedia = {
        author: "",
        body: {
          fr: ""
        },
        id: keyForTheNewMedia,
        title: {
          fr: ""
        },
        type: "quote",
        url: {
          fr: ""
        }
      }
      let existingMedias = [...updatedActivity.media];
      existingMedias.push(newMedia);
      updatedActivity.media = existingMedias;
    } else {
      const newMedia = {
        id: keyForTheNewMedia,
        title: {
          fr: ""
        },
        type: "pdf",
        url: {
          fr: ""
        }
      }
      let existingMedias = [...updatedActivity.media];
      existingMedias.push(newMedia);
      updatedActivity.media = existingMedias;
    }
    setActivity(updatedActivity);
  }

  const onDeleteConfirmation = (media: any) => {
    let updatedActivity: Activity = { ...activity };
    let existingMedias = [...updatedActivity.media];
    for (const aMediaIndex in existingMedias) {
      if (Object.prototype.hasOwnProperty.call(existingMedias, aMediaIndex)) {
        const aMedia = existingMedias[aMediaIndex];
        if (aMedia.id === media.id) {
          existingMedias.splice(parseInt(aMediaIndex), 1);
          break;
        }
      }
    }
    updatedActivity.media = existingMedias;
    setActivity(updatedActivity);
  }

  const onDeleteRequest = (media: any) => {
    let onDeleteRequestTitle: string = "Demande de suppression d'un média";
    let onDeleteRequestBody: string = "Es-tu sûr de vouloir supprimer le média ";
    if (category.value === "quotes") {
      onDeleteRequestTitle = "Demande de suppression d'une citations";
      onDeleteRequestBody = "Es-tu sûr de vouloir supprimer la citation "
    }
    alertRef.current?.showAlert({
      title: onDeleteRequestTitle,
      body: onDeleteRequestBody + media.title.fr,
      primaryButtonTitle: "Confirmer",
      onPressPrimaryButton: () => onDeleteConfirmation(media),
      secondaryButtonTitle: "Annuler"
    });
  }

  const onChangeMediaData = (data: { key: "body" | "title" | "author" | "url" | "date", language?: string, index: number, text: string }) => {
    const { key, language, index, text } = data;
    let updatedActivity: Activity = { ...activity };
    let existingMedias = [...updatedActivity.media];
    let mediaToChange = existingMedias[index];
    let dataToChange = mediaToChange[key];
    if (language !== undefined) {
      let languageToChange = dataToChange[language];
      languageToChange = text;
      dataToChange[language] = languageToChange;
    } else {
      dataToChange = text;
    }
    mediaToChange[key] = dataToChange;
    existingMedias[index] = mediaToChange;
    updatedActivity.media = existingMedias;
    setActivity(updatedActivity);
  }

  const handleAudioFileForMedia = (data: { file: any, language: string, media_id: string }) => {
    const { file, language, media_id } = data;
    let updatedNewAudioFiles = [...newAudioFilesForMedia];
    updatedNewAudioFiles.push({ media_id, language, file });
    setNewAudioFilesForMedia(updatedNewAudioFiles);
  }

  const onDeleteAudioForMediaRequest = (mediaFile: { file: any, language: string, media_id: string }) => {
    const { file, language, media_id } = mediaFile;
    let updatedNewAudioFiles = [...newAudioFilesForMedia];
    for (const aNewAudioFileIndex in updatedNewAudioFiles) {
      if (Object.prototype.hasOwnProperty.call(updatedNewAudioFiles, aNewAudioFileIndex)) {
        const aNewAudioFile = updatedNewAudioFiles[aNewAudioFileIndex];
        if (aNewAudioFile.media_id === media_id) {
          updatedNewAudioFiles.splice(parseInt(aNewAudioFileIndex), 1);
          break;
        }
      }
    }
    setNewAudioFilesForMedia(updatedNewAudioFiles);
    file.target.value = null;
  };

  const onUploadAudioForMediaRequest = async (mediaFile: { file: any, language: string, media_id: string }) => {
    try {
      const { file, language, media_id } = mediaFile;
      const result = await updloadMediaAudio({ audioToUpload: file.target.files[0], categoryKey: category.value, activityKey: activityKey.value, mediaKey: media_id, language: "fr", index: 0 });
      for (const aMediaIndex in activity.media) {
        if (Object.prototype.hasOwnProperty.call(activity.media, aMediaIndex)) {
          const aMedia = activity.media[aMediaIndex];
          if (aMedia.id === media_id) {
            onChangeMediaData({ key: "url", language, index: parseInt(aMediaIndex), text: result });
            break;
          }
        }
      }
      file.target.value = null;
    } catch (error) {
    }
  };

  const getMediaCells = () => {
    let mediaCells = [];
    let mediaCellWidth: number = Dimensions.get("screen").width - appTheme.pixelPerfect(80);
    if (mediaCellWidth > appTheme.pixelPerfect(300)) {
      mediaCellWidth === appTheme.pixelPerfect(300);
    }
    for (const aMediaIndex in activity.media) {
      if (Object.prototype.hasOwnProperty.call(activity.media, aMediaIndex)) {
        const aMedia = activity.media[aMediaIndex];
        if (category.value === "quotes") {
          const audioFileView = aMedia.url.fr.length > 0 ? <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), backgroundColor: appTheme.grow, justifyContent: "center", alignItems: "center" }} onPress={() => setSelectedAudio([aMedia.url.fr])}>
            <SVGView Component={IcPlay} size={30} color={"#FFF"} />
          </TouchableOpacity> : <Text style={labelStyle}>{"Aucun fichier audio"}</Text>;
          // On va regarder s'il y a un nouveau fichier audio
          let newAudioFile = undefined;
          for (const aNewAudioFileIndex in newAudioFilesForMedia) {
            if (Object.prototype.hasOwnProperty.call(newAudioFilesForMedia, aNewAudioFileIndex)) {
              const aNewAudioFile = newAudioFilesForMedia[aNewAudioFileIndex];
              if (aNewAudioFile.media_id === aMedia.id) {
                newAudioFile = aNewAudioFile;
                break;
              }
            }
          }
          const newAudioFileButton = newAudioFile === undefined ? <View /> : <View style={{ width: appTheme.pixelPerfect(100), justifyContent: "space-evenly", flexDirection: "row" }}>
            <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onDeleteAudioForMediaRequest(newAudioFile)}>
              <SVGView Component={IcTrash} size={30} color={appTheme.grow} />
            </TouchableOpacity>
            <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onUploadAudioForMediaRequest(newAudioFile)}>
              <SVGView Component={IcUpload} size={30} color={appTheme.grow} />
            </TouchableOpacity>
          </View>;
          mediaCells.push(<View style={{
            // width: mediaCellWidth,
            flexDirection: "row", borderColor: appTheme.grow, borderRadius: 10, borderWidth: appTheme.pixelPerfect(1), padding: appTheme.pixelPerfect(10), marginBottom: appTheme.pixelPerfect(20)
          }}>
            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={{ flex: 1 }}>
                  <Text style={mediaTitleStyle}>{"Titre"}</Text>
                  <Divider />
                  <TextInput onChangeText={(e) => onChangeMediaData({ key: "title", language: "fr", index: parseInt(aMediaIndex), text: e })} value={aMedia.title.fr} style={[inputStyle, { marginEnd: appTheme.pixelPerfect(40) }]} />
                  <Divider />
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={mediaTitleStyle}>{"Auteur"}</Text>
                  <Divider />
                  <TextInput onChangeText={(e) => onChangeMediaData({ key: "author", index: parseInt(aMediaIndex), text: e })} value={aMedia.author} style={[inputStyle, { marginEnd: appTheme.pixelPerfect(40) }]} />
                  <Divider />
                </View>
              </View>
              <Text style={mediaTitleStyle}>{"Citation"}</Text>
              <Divider />
              <TextInput onChangeText={(e) => onChangeMediaData({ key: "body", language: "fr", index: parseInt(aMediaIndex), text: e })} value={aMedia.body.fr} style={[inputStyle, { marginEnd: appTheme.pixelPerfect(40) }]} />
              <Divider />
              <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={{ flex: 1 }}>
                  <Text style={mediaTitleStyle}>{"Date (facultative)"}</Text>
                  <Divider />
                  <TextInput onChangeText={(e) => onChangeMediaData({ key: "date", index: parseInt(aMediaIndex), text: e })} value={aMedia.date} style={[inputStyle, { marginEnd: appTheme.pixelPerfect(40) }]} />
                  <Divider />
                </View>
                <View style={{ flex: 1, flexDirection: "row" }}>
                  <View style={{ width: appTheme.pixelPerfect(200) }}>
                    <Text style={mediaTitleStyle}>{"Fichier audio"}</Text>
                    <Divider />
                    {audioFileView}
                    <Divider />
                    <input id={aMedia.id} type="file" onChange={(file) => handleAudioFileForMedia({ file, language: "fr", media_id: aMedia.id })} />
                    <Divider />
                  </View>
                  {newAudioFileButton}
                </View>
              </View>
            </View>
            <View style={{ width: appTheme.pixelPerfect(80), minHeight: appTheme.pixelPerfect(100), justifyContent: "space-evenly" }}>
              <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onDeleteRequest(aMedia)}>
                <SVGView Component={IcTrash} size={30} color={appTheme.grow} />
              </TouchableOpacity>
            </View>
          </View>)
        } else {
          let pdfLink: string = "Aucun fichier";
          if (aMedia.url !== undefined) {
            if (aMedia.url[getLanguage()] !== undefined) {
              pdfLink = aMedia.url[getLanguage()];
            } else {
              pdfLink = aMedia.url;
            }
          }
          mediaCells.push(<View style={{
            //width: mediaCellWidth,
            flexDirection: "row", borderColor: appTheme.grow, borderRadius: 10, borderWidth: appTheme.pixelPerfect(1), padding: appTheme.pixelPerfect(10), marginBottom: appTheme.pixelPerfect(20)
          }}>
            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={{ flex: 1 }}>
                  <Text style={mediaTitleStyle}>{"ID"}</Text>
                  <Divider />
                  <TextInput editable={false} value={aMedia.id} style={[inputStyle, { marginEnd: appTheme.pixelPerfect(40) }]} />
                  <Divider />
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={mediaTitleStyle}>{"Titre"}</Text>
                  <Divider />
                  <TextInput onChangeText={(e) => onChangeMediaData({ key: "title", language: "fr", index: parseInt(aMediaIndex), text: e })} value={aMedia.title.fr} style={[inputStyle, { marginEnd: appTheme.pixelPerfect(40) }]} />
                  <Divider />
                </View>
              </View>
              <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={{ flex: 1 }}>
                  <Text style={mediaTitleStyle}>{"Fichier"}</Text>
                  <Divider />
                  <Text style={labelStyle}>{pdfLink}</Text>
                  <Divider />
                </View>
              </View>
            </View>
            <View style={{ width: appTheme.pixelPerfect(80), minHeight: appTheme.pixelPerfect(100), justifyContent: "space-evenly" }}>
              <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onDeleteRequest(aMedia)}>
                <SVGView Component={IcTrash} size={30} color={appTheme.grow} />
              </TouchableOpacity>
            </View>
          </View>)
        }
      }
    }
    return mediaCells;
  };

  //********** PARTIE POUR LES AUDIOS ***********
  const handleAudioFileForActivity = (data: { file: any, language: string }) => {
    const { file, language } = data;
    let updatedNewAudioUrls = [...newAudioUrls];
    updatedNewAudioUrls.push({ file, language });
    setNewAudioUrls(updatedNewAudioUrls);
  };

  const onDeleteAudioRequest = (url: string, language: string) => {
    let updatedActivity = { ...activity };
    let activityAction = updatedActivity.action;
    let activityActionAudio = activityAction.audio;
    let activityAudioForLanguage = activityActionAudio[language];
    const indexofUrlToDelete = activityAudioForLanguage.indexOf(url);
    activityAudioForLanguage.splice(indexofUrlToDelete, 1);
    activityActionAudio[language] = activityAudioForLanguage;
    activityAction.audio = activityActionAudio;
    updatedActivity.action = activityAction;
    setActivity(updatedActivity);
  };

  const onUploadAudioForActivity = async (data: { file: any, language: string }) => {
    try {
      const { file, language } = data;
      const result = await updloadActivityAudio({ audioToUpload: file.target.files[0], categoryKey: category.value, activityKey: activityKey.value, language, index: activity.action.audio[language].length });
      let updatedActivity = { ...activity };
      let activityAction = updatedActivity.action;
      let activityActionAudio = activityAction.audio;
      let activityAudioForLanguage = activityActionAudio[language];

      activityAudioForLanguage.push(result);
      activityActionAudio[language] = activityAudioForLanguage;
      activityAction.audio = activityActionAudio;
      updatedActivity.action = activityAction;
      setActivity(updatedActivity);
      setNewAudioUrls([]);
      file.target.value = null;
    } catch (error) {
    }
  };

  const onDeleteNewAudio = (index: string, file: any) => {
    let updatedNewAudioUrls = [...newAudioUrls];
    updatedNewAudioUrls.splice(parseInt(index), 1);
    setNewAudioUrls(updatedNewAudioUrls);
    file.target.value = null;
  };

  const getActivityAudio = () => {
    if (activity !== undefined) {
      let audioFileView = [];
      if (activity.action.audio.fr.length > 0) {
        for (const aNewAudioFileIndex in activity.action.audio.fr) {
          if (Object.prototype.hasOwnProperty.call(activity.action.audio.fr, aNewAudioFileIndex)) {
            const element: string = activity.action.audio.fr[aNewAudioFileIndex];
            audioFileView.push(<View key={element} style={{ flexDirection: "row", width: appTheme.pixelPerfect(400), padding: appTheme.pixelPerfect(10), borderWidth: appTheme.pixelPerfect(1), borderRadius: appTheme.pixelPerfect(10), borderColor: "#BCBCBC" }}>
              <View style={{ flex: 1 }}>
                <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), backgroundColor: appTheme.grow, justifyContent: "center", alignItems: "center" }} onPress={() => setSelectedAudio([element])}>
                  <SVGView Component={IcPlay} size={30} color={"#FFF"} />
                </TouchableOpacity>
                <Divider />
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfect(8) }}>{element}</Text>
              </View>
              <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onDeleteAudioRequest(element, "fr")}>
                <SVGView Component={IcTrash} size={30} color={appTheme.grow} />
              </TouchableOpacity>
            </View>)
          }
        }
      }
      if (newAudioUrls.length > 0) {
        for (const aNewAudioUrlIndex in newAudioUrls) {
          if (Object.prototype.hasOwnProperty.call(newAudioUrls, aNewAudioUrlIndex)) {
            const element: { file: any, language: string } = newAudioUrls[aNewAudioUrlIndex];
            audioFileView.push(<View key={"new_audio_" + aNewAudioUrlIndex} style={{ flexDirection: "row", width: appTheme.pixelPerfect(400), padding: appTheme.pixelPerfect(10), borderWidth: appTheme.pixelPerfect(1), borderRadius: appTheme.pixelPerfect(10), borderColor: "#BCBCBC" }}>
              <View style={{ flex: 1 }}>
                <Divider />
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfect(12) }}>{element.language}</Text>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfect(12) }}>{element.file.target.files[0].name}</Text>
              </View>
              <View>
                <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onDeleteNewAudio(aNewAudioUrlIndex, element.file)}>
                  <SVGView Component={IcTrash} size={30} color={appTheme.grow} />
                </TouchableOpacity>
                <Divider />
                <TouchableOpacity style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), borderColor: appTheme.grow, borderWidth: 1, justifyContent: "center", alignItems: "center" }} onPress={() => onUploadAudioForActivity(element)}>
                  <SVGView Component={IcUpload} size={30} color={appTheme.grow} />
                </TouchableOpacity>
              </View>
            </View>)
          }
        }
      }
      if (audioFileView.length === 0) {
        audioFileView.push(<Text style={labelStyle}>{"Aucun fichier audio"}</Text>);
      }
      const inputView = newAudioUrls.length > 0 ? <View /> : <input id={activity.key} type="file" onChange={(file) => handleAudioFileForActivity({ file, language: "fr" })} />;
      return <View>
        {audioFileView}
        <Divider />
        {inputView}
        <Divider />
      </View>
    }
    return <View />
  }

  const getMedias = () => {
    // On traite les médias à part, car suivant les catégories, ils n'ont pas les mêmes noms / attributs
    if (inspiringCategories.indexOf(category.value) === -1) {
      return <View />
    }
    let mediaTitle: string = "Médias";
    let addAMediaTitle: string = "Ajouter un média";
    if (category.value === "quotes") {
      mediaTitle = "Citations";
      addAMediaTitle = "Ajouter une citation";
    }
    return <View>
      <Divider />
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 1 }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={attributeTitleStyle}>{mediaTitle}</Text>
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <View style={{ flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
            <Button style={{ width: appTheme.pixelPerfect(300) }} title={addAMediaTitle} onPress={() => addNewMedia()} />
          </View>
        </View>
      </View>
      <Divider />
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 1 }}>
          {getMediaCells()}
        </View>
      </View>
      <Divider />
    </View>
  };

  const getActivityForm = () => {
    if ((activity === undefined) || (category === undefined)) {
      return <View />
    }
    if ((inspiringCategories.indexOf(category.value) === -1) && (gamingCategories.indexOf(category.value) === -1)) {
      return <View>
        <Text style={attributeTitleStyle}>Les activités de cette catégorie ne sont pas encore gérées.</Text>
      </View>
    }

    return <View>
      <View style={{ width: "100%", height: 1, backgroundColor: "#909090" }} />
      <Divider />
      <View style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <View style={{ width: "40%" }}>
          <View>
            <Text style={attributeTitleStyle}>ID</Text>
            <Divider />
            <TextInput editable={newActivity} value={activity.key} style={[inputStyle, { width: appTheme.pixelPerfect(200), height: appTheme.pixelPerfect(30) }]} />
          </View>
          <Divider />
        </View>
        <View style={{ width: "40%" }}>
          <View>
            <Text style={attributeTitleStyle}>Premium</Text>
            <Divider />
            {getSwitchButtonPremiumness()}
          </View>
          <Divider />
        </View>
      </View>
      <Divider />

      <Divider />
      <View style={{ zIndex: 2 }}>
        <Text style={attributeTitleStyle}>Titre</Text>
        <Divider />
        {getNewTitleLanguageSelector()}
      </View>
      <Divider />
      {getTitles()}
      <Divider />

      <Divider />
      <View style={{ zIndex: 1 }}>
        <Text style={attributeTitleStyle}>Introduction (body)</Text>
        <Divider />
        {getNewIntroductionLanguageSelector()}
      </View>
      <Divider />
      <View>
        {getBodies()}
      </View>
      <Divider />

      <Divider />
      <View style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <View style={{ width: "40%" }}>
          <View>
            <Text style={attributeTitleStyle}>Durée</Text>
            <Divider />
            {getDuration()}
          </View>
          <Divider />
          <Divider />
          <View>
            <Text style={attributeTitleStyle}>Image</Text>
            <Divider />
            {getImage()}
          </View>
          <Divider />
          <Divider />
          <View>
            <Text style={attributeTitleStyle}>Tags</Text>
            <Divider />
            {getTags()}
          </View>
          <Divider />
        </View>

        <View style={{ width: "60%" }}>
          <View>
            <Text style={attributeTitleStyle}>Audio</Text>
            <Divider />
            {getActivityAudio()}
          </View>
          <Divider />
        </View>
      </View>
      <Divider />

      <Divider />
      <View style={{}}>
        <View>
          {getMedias()}
        </View>
      </View>
      <Divider />

      <Divider />
      <View style={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Button
          style={{ width: appTheme.pixelPerfect(300) }}
          title={newActivity === true ? "Enregistrer" : "Mettre à jour"}
          onPress={dbUpdateActivity}
        />
      </View>
    </View>
  }

  // BOUTON QUI AFFICHE LE FORMULAIRE DE CRÉATION D'UNE NOUVELLE ACTIVITÉ
  const getNewActivityButton = () => {
    return <Button disabled={category === undefined} style={{ width: appTheme.pixelPerfect(300) }} title={"Créer une nouvelle activité"} onPress={() => setNewActivity(true)} />
  };

  const getContent = () => {
    if (viewDidAppear === false) {
      return <View onLayout={onViewWillAppear} style={{ width: "100%", flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator color={appTheme.grow} loading={true} />
      </View>
    }
    return <View style={{ width: "100%" }}>
      <View style={{ flex: 1, alignItems: "flex-end" }}>
        {getNewActivityButton()}
      </View>
      <Text style={labelStyle}>
        Catégories
      </Text>
      <Divider size={5} />
      {getCategoriesSelectInput()}
      <Divider />
      <Text style={labelStyle}>
        Activités
      </Text>
      <Divider size={5} />
      {getActivitiesSelectInput()}
      <Divider />
      <View style={{ width: "100%", flexDirection: "row", alignItems: "center" }}>

      </View>
      <Divider />

      {getActivityForm()}


    </View>
  }

  const getAudioPlayerModal = () => {
    if ((activity === undefined) || (selectedAudio.length === 0)) {
      return <View />
    }
    return <ActivityPlayer
    activityKey={activity.key}
      title={activity.title[getLanguage()]}
      notificationImage={Platform.OS === "ios" ? activity.img_url : activity.app_image}
      urls={selectedAudio}
      color={appTheme.grow}
      localImage={images[activity.app_image]}
      remoteImage={activity.img_url}
      togglePlayer={() => setSelectedAudio([])} />
  }

  return (<View style={{ padding: appTheme.pixelPerfect(20) }}>
    <View style={{ flexDirection: "row", marginBottom: appTheme.pixelPerfect(10) }}>
      <TouchableOpacity onPress={() => goBack()}>
        <Text style={[aStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
          Dashboard
        </Text>
      </TouchableOpacity>
      <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
        {">"}
      </Text>
      <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
        Activités
      </Text>
    </View>
    {getContent()}
    <Modal animationType="slide" visible={launchPlayer} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
      <View style={{ flex: 1, alignItems: "center", backgroundColor: appTheme.grow + "80", width: appTheme.getFullScreenWidth() }}>
        <View style={{ flex: 1, alignItems: "center", justifyContent: "flex-end", backgroundColor: appTheme.grow + "80", width: appTheme.getFullAppWidth(), marginBottom: appTheme.pixelPerfect(20) }}>
          {getAudioPlayerModal()}
        </View>
      </View>
    </Modal>
    <Alert ref={alertRef} />
  </View>
  )
}

export default LHQActivitiesManagement;