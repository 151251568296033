import * as Encode from "../../specific/utils/Encode";
import { CONTEXT } from "../../shared/utils/Constants";
import { getAppTarget } from "../../specific/utils/Navigation/Host";
import { Platform } from "react-native";

const apiKey = "9f877bafd3c35f23a9a30e423efbe9d9-us4";
const server = "us4";
const mailchimpHost = "https://" + server + ".api.mailchimp.com/3.0";
const listsEndpoint = "/lists";
const membersEndpoint = "/members";
const tagsEndpoint = "/tags";

let authenticationString = Encode.btoa('randomstring:' + apiKey);
authenticationString = "Basic " + authenticationString;

export const getAuthString = () => {
}

export const getMailingToolAudiences = async () => {
    try {
        const mailchimpUrl = mailchimpHost + listsEndpoint;
        const listResult = await fetch(mailchimpUrl, {
            method: "GET",
            headers: {
                "authorization": authenticationString,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        const listData = await listResult.json();
    } catch (error) {
    }
}

export const searchUserEmailInLists = async (data: { user_email: string }) => {
    let userSubscription = {
        optInLili: {
            isRegistered: false,
            status: "unregistered"
        },
        optInSuperpowers: {
            isRegistered: false,
            status: "unregistered"
        }
    };
    try {
        const { user_email } = data;
        const mailchimpUrl = mailchimpHost + "/search-members?query=" + user_email;
        const listResult = await fetch(mailchimpUrl, {
            method: "GET",
            headers: {
                "authorization": authenticationString,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        const listData = await listResult.json();
        if ((listData.exact_matches !== undefined) && (listData.exact_matches.members !== undefined) && (listData.exact_matches.members.length > 0)) {
            for (const aMembersIndex in listData.exact_matches.members) {
                if (Object.prototype.hasOwnProperty.call(listData.exact_matches.members, aMembersIndex)) {
                    const aMember = listData.exact_matches.members[aMembersIndex];
                    if (aMember.list_id === "0e2549ec99") {
                        //Superpowers
                        userSubscription.optInSuperpowers = aMember.status === "subscribed" ? {
                            isRegistered: true,
                            status: aMember.status
                        } : {
                            isRegistered: false,
                            status: aMember.status
                        };
                    } else if (aMember.list_id === "0abdba74d7") {
                        //Lili
                        userSubscription.optInLili = aMember.status === "subscribed" ? {
                            isRegistered: true,
                            status: aMember.status
                        } : {
                            isRegistered: false,
                            status: aMember.status
                        };
                    }
                }
            }
        } else if ((listData.full_search !== undefined) && (listData.full_search.members !== undefined) && (listData.full_search.members.length > 0)) {
            for (const aMembersIndex in listData.full_search.members) {
                if (Object.prototype.hasOwnProperty.call(listData.full_search.members, aMembersIndex)) {
                    const aMember = listData.full_search.members[aMembersIndex];
                    if (aMember.email_address === user_email) {
                        if (aMember.list_id === "0e2549ec99") {
                            //Superpowers
                            userSubscription.optInSuperpowers = aMember.status === "subscribed" ? {
                                isRegistered: true,
                                status: aMember.status
                            } : {
                                isRegistered: false,
                                status: aMember.status
                            };
                        } else if (aMember.list_id === "0abdba74d7") {
                            //Lili
                            userSubscription.optInLili = aMember.status === "subscribed" ? {
                                isRegistered: true,
                                status: aMember.status
                            } : {
                                isRegistered: false,
                                status: aMember.status
                            };
                        }
                    }
                }
            }
        }
        return userSubscription;
    } catch (error) {
        return userSubscription;
    }
}

export const addContactToSuperPowerList = async (data: { email: string }) => {
    try {
        const { email } = data;
        // Power List ID : 
        const list_id = "0e2549ec99";
        const result = await addContactToList({
            email,
            list_id,
            tags: []
        });
        return result;
    } catch (error) {
        throw error
    }

}

export const addContactToGeneralAudience = async (data: { email: string, userType: string, optIn: boolean, optInCoach: boolean, uid?:string, firstname?:string, lastname?:string, isDirector?:boolean, tneEnable?: boolean }) => {
    const { email, userType, optIn, optInCoach, uid, firstname, lastname, isDirector = false, tneEnable = false } = data;
    const appTarget = getAppTarget();
    if (appTarget === "public") {
        if (Platform.OS !== "web") {
            const list_id = "0abdba74d7";
            let tags = [];
            if (userType === CONTEXT.teacher) {
                if (tneEnable === false) {
                    tags.push("Teacher");
                } else {
                    tags.push("TNE Teacher");
                }
            } else if (userType === CONTEXT.family) {
                tags.push("Parent");
            } else if (userType === CONTEXT.animator) {
                tags.push("Animator");
            }
            if (optIn === true) {
                tags.push("OptIn")
            }
            if (isDirector === true) {
                tags.push("Direction");
            }
            await addContactToList({
                email,
                list_id,
                tags
            });
            if (optInCoach === true) {
                addContactToList({ email, list_id: "0e2549ec99", tags: [] });
            }
        }
    } else {
        /*
        const listId = userType === "tne" ? 2 : 5;
        const options = {
            method: 'POST',
            headers: { 
                accept: 'application/json', 
                'content-type': 'application/json',
                'api-key': ''
            },
            body: JSON.stringify({
                email,
                ext_id: uid,
                attributes: { FNAME: firstname, LNAME: lastname },
                emailBlacklisted: false,
                smsBlacklisted: false,
                listIds: [listId],
                updateEnabled: false
            })
        };

        fetch('https://api.sendinblue.com/v3/contacts', options).then(response => response.json())
            .then((response) => {
            }).catch((err) => {
            });
            */
    }
}

const addContactToList = async (data: { email: string, list_id: string, tags: string[] }) => {
    const { email, list_id, tags } = data;
    const body = JSON.stringify({
        email_address: email,
        status: "subscribed",
        tags
    });
    try {
        const mailchimpUrl = mailchimpHost + listsEndpoint + "/" + list_id + membersEndpoint;
        const listResult = await fetch(mailchimpUrl, {
            method: "POST",
            headers: {
                "authorization": authenticationString,
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body
        });
        const listData = await listResult.json();
        return listData;
    } catch (error) {
        throw error;
    }
}

export const addOupOutSuperHerosTagToUser = async (data: { email: string }) => {
    const { email } = data;
    await addTagToUser({
        email,
        tag: "OptOut Super Heros",
        list_id: "0abdba74d7"
    });
}

export const addOptInTagToUser = async (data: { email: string }) => {
    const { email } = data;
    await addTagToUser({
        email,
        tag: "OptIn",
        list_id: "0abdba74d7"
    });
}

export const addCoachManualTagToUser = async (data: { email: string }) => {
    const { email } = data;
    await addTagToUser({
        email,
        tag: "CoachManual",
        list_id: "0abdba74d7"
    });
}

export const addParentTagToUser = async (data: { email: string }) => {
    const { email } = data;
    await addTagToUser({
        email,
        tag: "Parent",
        list_id: "0abdba74d7"
    });
}

export const addTeacherTagToUser = async (data: { email: string }) => {
    const { email } = data;
    await addTagToUser({
        email,
        tag: "Teacher",
        list_id: "0abdba74d7"
    });
}

export const addPremiumTagToUser = async (data: { email: string }) => {
    const { email } = data;
    await addTagToUser({
        email,
        tag: "Premium",
        list_id: "0abdba74d7"
    });
}

const addTagToUser = async (data: { email: string, tag: string, list_id: string }) => {
    const { email, tag, list_id } = data;
    const subscriber_hash = Encode.stringToMd5(email.toString().toLowerCase());
    try {
        const mailchimpUrl = mailchimpHost + listsEndpoint + "/" + list_id + membersEndpoint + "/" + subscriber_hash + tagsEndpoint;
        const listResult = await fetch(mailchimpUrl, {
            method: "POST",
            headers: {
                "authorization": authenticationString,
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                tags: [
                    {
                        name: tag,
                        status: "active"
                    }
                ]
            })
        });
        const listData = await listResult.json();
    } catch (error) {
    }
}