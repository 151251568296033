export const getHost = () => {
    const host = window.location.hostname;
    return host;
}


const garHost: string[] = ["app-tne.lili.cool"];
//const garHost: string[] = ["app-tne.lili.cool"];
const garPreProdHost: string[] = ["app-tne-pp.lili.cool"];
const canopeHost: string[] = ["app-tne-demo.lili.cool"];

//CI DESSOUS POUR LANCER L'ADMIN LHQ
const lhqHost: string[] = ["centralperk.web.app"];

//CI DESSOUS POUR LANCER L'APP
//const lhqHost: string[] = ["centralperk.web.app"];

export type AppTarget = "gar-preprod" | "gar-prod" | "public" | "canope" | "lhq";

export const getAppTarget = () => {
    const host = getHost();
    let target: AppTarget = "public";
    if (garHost.indexOf(host) !== -1) {
        target = "gar-prod";
    } else if (canopeHost.indexOf(host) !== -1) {
        target = "canope";
    } else if (lhqHost.indexOf(host) !== -1) {
        target = "lhq";
    } else if (garPreProdHost.indexOf(host) !== -1) {
        target = "gar-preprod";
    }
    return target;
} 