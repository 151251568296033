import React, { useState, useEffect } from "react";
import { View, Text, TextStyle, TouchableOpacity, Animated } from "react-native";
import AppTheme from "../../utils/Theme";

import Select from "react-select"

import Button from "../../designSystem/Button";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import Divider from "../../designSystem/Divider/Divider";

import { setDBCategory, addDBCategory } from "../../../specific/services/Specific_Database";
import { getSessionsActivities, getSessionsCategories } from "../../../specific/utils/LocalStorage";

import Category from "../../models/Category";
import TextInput from "../../designSystem/TextInput/RoundCornerTextInput";
import GameBackground from "../Game/GameBackground";


interface Props {
  goBack?: () => void
}

const languagesByKey = {
  "fr": "Français",
  "en": "Anglais",
  "es": "Espagnol",
  "it": "Italien",
  "pt": "Portugais",
  "ar": "Arabe",
  "de": "Allemand",
  "zh": "Chinois",
  "hi": "Hindi",
  "bn": "Bengali",
  "ru": "Russe",
  "ja": "Japonais",
  "ko": "Coréen",
  "vi": "Vietnamien",
  "ur": "Ourdou",
  "mr": "Marathi",
  "ta": "Tamoul",
  "tr": "Turc",
  "te": "Télougou",
  "fa": "Persan",
  "pa": "Pendjabi",
  "id": "Indonésien",
  "pl": "Polonais",
  "uk": "Ukrainien",
  "ro": "Roumain",
  "nl": "Néerlandais",
  "th": "Thaï",
  "ms": "Malais",
  "my": "Birman",
  "hu": "Hongrois",
  "el": "Grec",
  "cs": "Tchèque",
  "sv": "Suédois",
  "bg": "Bulgare",
  "ab": "Abkhaze",
  "aa": "Afar",
  "af": "Afrikaans",
  "ak": "Akan",
  "sq": "Albanais",
  "am": "Amharique",
  "an": "Aragonais",
  "hy": "Arménien",
  "as": "Assamais",
  "av": "Avar",
  "ae": "Avestique",
  "ay": "Aymara",
  "az": "Azéri",
  "bm": "Bambara",
  "ba": "Bachkir",
  "eu": "Basque",
  "be": "Biélorusse",
  "bh": "Bihari",
  "bi": "Bichelamar",
  "bs": "Bosnien",
  "br": "Breton",
  "ca": "Catalan",
  "ch": "Chamorro",
  "ce": "Tchétchène",
  "ny": "Chichewa",
  "cu": "Slavon d'église",
  "cv": "Tchouvache",
  "kw": "Cornique",
  "co": "Corse",
  "cr": "Cree",
  "hr": "Croate",
  "da": "Danois",
  "dv": "Divehi",
  "dz": "Dzongkha",
  "eo": "Espéranto",
  "et": "Estonien",
  "ee": "Ewe",
  "fo": "Féroïen",
  "fj": "Fidjien",
  "fi": "Finnois",
  "ff": "Peul",
  "gl": "Galicien",
  "ka": "Géorgien",
  "gn": "Guarani",
  "gu": "Gujarati",
  "ht": "Haïtien",
  "ha": "Haoussa",
  "he": "Hébreu",
  "hz": "Herero",
  "ho": "Hiri Motu",
  "ia": "Interlingua",
  "ie": "Interlingue",
  "ga": "Irlandais",
  "ig": "Igbo",
  "ik": "Inupiaq",
  "io": "Ido",
  "is": "Islandais",
  "iu": "Inuktitut",
  "jv": "Javanais",
  "kl": "Groenlandais",
  "kn": "Kannada",
  "kr": "Kanouri",
  "ks": "Kashmiri",
  "kk": "Kazakh",
  "km": "Khmer",
  "ki": "Kikuyu",
  "rw": "Kinyarwanda",
  "ky": "Kirghiz",
  "kv": "Komi",
  "kg": "Kongo",
  "kj": "Kwanyama",
  "ku": "Kurde",
  "lo": "Laotien",
  "la": "Latin",
  "lv": "Letton",
  "li": "Limbourgeois",
  "ln": "Lingala",
  "lt": "Lituanien",
  "lu": "Luba-Katanga",
  "lg": "Ganda",
  "lb": "Luxembourgeois",
  "mk": "Macédonien",
  "mg": "Malgache",
  "ml": "Malayâlam",
  "mt": "Maltais",
  "gv": "Mannois",
  "mi": "Maori",
  "mh": "Marshallais",
  "mn": "Mongol",
  "na": "Nauruan",
  "nv": "Navajo",
  "nd": "Ndébéle du Nord",
  "nr": "Ndébéle du Sud",
  "ng": "Ndonga",
  "ne": "Népalais",
  "no": "Norvégien",
  "ii": "Yi du Sichuan",
  "oc": "Occitan",
  "oj": "Ojibwé",
  "or": "Oriya",
  "om": "Oromo",
  "os": "Ossète",
  "pi": "Pâli",
  "ps": "Pachto",
  "qu": "Quechua",
  "rm": "Romanche",
  "rn": "Kirundi",
  "sa": "Sanskrit",
  "sc": "Sarde",
  "sd": "Sindhi",
  "se": "Sami du Nord",
  "sm": "Samoan",
  "sg": "Sango",
  "sr": "Serbe",
  "gd": "Gaélique écossais",
  "sn": "Shona",
  "si": "Singhalais",
  "sk": "Slovaque",
  "sl": "Slovène",
  "so": "Somali",
  "st": "Sotho du Sud",
  "su": "Soundanais",
  "sw": "Swahili",
  "ss": "Swati",
  "tl": "Tagalog",
  "tn": "Tswana",
  "to": "Tongien",
  "tk": "Turkmène",
  "ts": "Tsonga",
  "tt": "Tatar",
  "tw": "Twi",
  "ty": "Tahitien",
  "ug": "Ouïghour",
  "vo": "Volapük",
  "wa": "Wallon",
  "cy": "Gallois",
  "wo": "Wolof",
  "xh": "Xhosa",
  "yi": "Yiddish",
  "yo": "Yoruba",
  "za": "Zhuang",
  "zu": "Zoulou"
}

const LHQCategoriesManagement = ({ goBack }: Props) => {


  // VALEURS PAR DÉFAUT POUR LES COULEURS DES CAT
  // btn : #1BA48A
  // header_bg : #FCECDF
  // nav : #1BA48A
  // txt : #FFFFFF

  const [categories, setCategories] = useState<any>(undefined);
  const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

  // SÉLECTION DE LA SECTION
  const [section, setSection] = useState<{ value: string, label: string } | undefined>(undefined);
  const [sectionId, setSectionId] = useState<string>("");

  // CATEGORIES CORRESPONDANT À LA SECTION SÉLECTIONNÉE
  const [categoriesMatchedSectionId, setCategoriesMatchedSectionId] = useState<any>(undefined);

  //FORMULAIRE DE CREATION D'UNE NOUVELLE CATEGORIE
  const [newCategory, setNewCategory] = useState<boolean>(false);
  const [category, setCategory] = useState<Category | undefined>(undefined);

  //SÉLECTION D'UNE CATEGORIE EXISTANTTE POUR ACTUALISATION
  const [selectedCategory, setSelectedCategory] = useState<{ value: string, label: string } | undefined>(undefined)

  //FORM
  const [showForm, setShowForm] = useState<boolean>(false);

  // STATUS
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [categoryStatusChanged, setCategoryStatusChanged] = useState<boolean>(false);

  // TITLE
  const [selectedTitleLanguage, setSelectedTitleLanguage] = useState<LanguageOption | null>(null);

  // BODY
  const [selectedBodyLanguage, setSelectedBodyLanguage] = useState<LanguageOption | null>(null)

  // CLÉ ANALYTICS
  const [categoryName, setCategoryName] = useState<string>("");
  const [tempCategoryKey, setTempCategoryKey] = useState<string>("");
  const [categoryKeyError, setCategoryKeyError] = useState<string>("");


  // ORDRE DE LA CATEGORIE
  const [tempCategoryOrder, setTempCategoryOrder] = useState<{ value: number, label: string } | undefined>(undefined);
  const [categoryOrder, setCategoryOrder] = useState<number | null>(null);

  // NOMBBRE MINIMUM DE BUILD
  const [minBuildNumber, setMinBuildNumber] = useState<string>("");
  const [minBuildNumberError, setMinBuildNumberError] = useState<string>("");

  //MESSAGE DE SUCCES OU D'ERREUR
  const [message, setMessage] = useState<string>("")

  const appTheme: AppTheme = new AppTheme();

  const h1Style: TextStyle = {
    fontFamily: appTheme.primaryBoldFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(18)
  };

  const h2Style: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(16)
  };

  const h3Style: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(14)
  };

  const labelStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(10)
  };

  const mediaTitleStyle: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(10)
  };

  const attributeTitleStyle: TextStyle = {
    fontFamily: appTheme.primaryBoldFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(12),
    textDecorationLine: "underline"
  };

  const inputStyle: TextStyle = {
    height: appTheme.pixelPerfect(40),
    borderRadius: appTheme.pixelPerfect(10),
    borderWidth: 1,
    borderColor: appTheme.grow,
    padding: appTheme.pixelPerfect(4),
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(6)
  };

  const pStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.textColor,
    fontSize: appTheme.pixelPerfectForFont(14)
  };

  const aStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.textColor,
    fontSize: appTheme.pixelPerfectForFont(14),
    textDecorationLine: "underline"
  };

  const onViewWillAppear = () => {
    getCategories();
  };

  const getCategories = async () => {
    const sessionCategories = await getSessionsCategories();
    setCategories(sessionCategories);
  };

  useEffect(() => {
    if (categories !== undefined) {
      setViewDidAppear(true);
    }
  }, [categories]);

  //OBJET CATEGORY POUR UNE NOUVELLE CRÉATION DE CATEGORIE
  useEffect(() => {
    if (sectionId !== "" && newCategory === true) {
      //Valeurs des couleurs des éléments si sectionId = "inspire", sinon valeurs par défaut
      let btnClr = sectionId === "inspire" ? "#FFC94E" : "#1BA48A"
      let header_bgClr = sectionId === "inspire" ? "#FFC94E" : "#FCECDF"
      let navClr = sectionId === "inspire" ? "#FFF085" : "#1BA48A"
      let txtClr = sectionId === "inspire" ? "#25364F" : "#FFFFFF"

      let categoryToAdd: Category = {
        analytics_key: "",
        body: {
          fr: [""]
        },
        clr: {
          btn: btnClr,
          header_bg: header_bgClr,
          nav: navClr,
          txt: txtClr
        },
        key: "",
        last_update: new Date().getTime(),
        minBuildNumber: null,
        ord: null,
        sectionId: sectionId,
        status: "draft",
        title: {
          fr: ""
        }
      }
      setCategory(categoryToAdd)
      setShowForm(true)
    }
  }, [newCategory]);

  // ********** FILTRE DES CATÉGORIES EN FONCTION DE LA SECTION SÉLECTIONNÉE ********** 
  useEffect(() => {
    if (sectionId !== undefined) {
      if (categories !== undefined) {
        let categoriesMatchedWithSectionId = [];
        for (const aKey in categories.data) {
          if (categories.data[aKey].sectionId === sectionId) {
            categoriesMatchedWithSectionId.push(categories.data[aKey])
            setCategoriesMatchedSectionId(categoriesMatchedWithSectionId)
          }
        }
      }
    }
  }, [sectionId]);

  //RÉCUPÈRER LA VALEUR DU STATUS DE LA CATEGORIE SÉLECTIONNÉE
  useEffect(() => {
    if (category !== undefined) {
      if (category.status === "published") {
        setIsPublished(true)
      } else {
        setIsPublished(false)
      }
    }
  }, [category]);

  // ********** SETTER AUTOMATIQUE DU STATUS  **********
  useEffect(() => {
    let updatedCategory: Category = { ...category }
    if (isPublished === true) {
      updatedCategory.status = "published"
    } else {
      updatedCategory.status = "draft"
    }
    setCategory(updatedCategory)
  }, [categoryStatusChanged]);

  // ********** RÉCUPÈRER LES DONNÉES DE LA CATEGORIE SÉLECTIONNÉE POUR DEFINIR "CATEGORY" ********** 
  useEffect(() => {
    setNewCategory(false)
    setCategory(undefined)
    if ((selectedCategory !== undefined)) {
      if (categories.data[selectedCategory.value] !== undefined) {
        setCategory(categories.data[selectedCategory.value])
      };

      //Pour les categories anciennes qui n'ont pas de CLR, on les ajoute. 
      if (!categories.data[selectedCategory.value].clr) {
        categories.data[selectedCategory.value].clr = {
          btn: "",
          header_bg: "",
          nav: "",
          txt: ""
        }
      };

      //Pour les categories anciennes qui n'ont pas de BODY, on l'ajoute. 
      if (!categories.data[selectedCategory.value].body) {
        categories.data[selectedCategory.value].body = {
          fr: [""]
        }
      }

    } else {
      setCategory(undefined)
    }
  }, [selectedCategory]);


  // ********** DÉFINITION AUTOMATIQUE DE L'ANALYTICS KEY ********** 
  useEffect(() => {
    if (tempCategoryKey !== "") {
      let tempAnalyticsKey = tempCategoryKey.replace(/([A-Z])/g, "_$1")
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")

      let updatedCategory: Category = { ...category };
      updatedCategory.analytics_key = tempAnalyticsKey;
      setCategory(updatedCategory)
    }
  }, [tempCategoryKey]);


  // ********** FONCTION DE VALIDATION DU FORMULAIRE (CRÉER OU MODIFIER) ********** 
  const dbCreateAndUpdateCategory = async () => {
    let updatedCategory: Category = { ...category }
    updatedCategory.last_update = new Date().getTime();
    if (newCategory === true && selectedCategory === undefined) {
      try {
        const responseMessage = await addDBCategory({ key: updatedCategory.key, value: updatedCategory });
        setMessage(responseMessage)
      } catch (error) {
        console.error(error.message);
      }
    };
    if (newCategory === false && selectedCategory !== undefined) {
      try {
        const responseMessage = await setDBCategory({ key: updatedCategory.key, value: updatedCategory });
        setMessage(responseMessage)
      } catch (error) {
        console.error(error.message);
      }
    };
  };

  // ********** PARTIE POUR LA SELECTION DE LA SECTION **********
  const onSectionSelection = (e: { value: string, label: string }) => {
    setCategory(undefined)
    setSection(e)
    setSectionId(e.value)
  };

  interface SectionInterface {
    value: string,
    label: string
  };

  const getSectionSelector = () => {
    const sectionArray: SectionInterface[] = [
      {
        value: "still",
        label: "Je me pose"
      },
      {
        value: "talk",
        label: "Je m'exprime"
      },
      {
        value: "grow",
        label: "Je grandis"
      },
      {
        value: "inspire",
        label: "Je m'inspire"
      },
      {
        value: "liliAtHome",
        label: "Lili à la maison"
      },
      {
        value: "liliAtSchool",
        label: "Lili à l'école"
      },
      {
        value: "onBoardingSteps",
        label: "Conseils d'utilisation de l'app"
      },
      {
        value: "superPowers",
        label: "Super pouvoir"
      },
      {
        value: "superPrograms",
        label: "Super programmes"
      }
    ];

    let sectionSelectionOptions: { value: string, label: string }[] = [];
    for (const aSection in sectionArray) {
      if (Object.prototype.hasOwnProperty.call(sectionArray, aSection)) {
        const element = sectionArray[aSection];
        sectionSelectionOptions.push({
          value: element.value,
          label: element.label + " - " + element.value
        })
      }
    };
    return (
      <Select
        value={section}
        onChange={(e) => onSectionSelection(e)}
        options={sectionSelectionOptions}
        placeholder={"Sélectionnez une section."}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: appTheme.pixelPerfect(30),
            minHeight: appTheme.pixelPerfect(30),
            borderRadius: appTheme.pixelPerfect(10),
            fontFamily: appTheme.primaryFont,
            borderColor: appTheme.grow,
            fontSize: appTheme.pixelPerfectForFont(6),
            display: "flex",
            alignItems: "center",
            paddingTop: 0,
            paddingBottom: 0,
            flexWrap: "unset",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            fontFamily: appTheme.primaryFont,
            fontSize: appTheme.pixelPerfectForFont(6),
          })
        }}
      />
    )
  };


  // ********** PARTIE POUR LA SELECTION DE LA CATEGORIES EXISTANTE QUE L'ON SOUHAITE MODIFIER ********** 
  const selectACategory = (e) => {
    setSelectedCategory(e)
    setNewCategory(false)
  };

  const getExistingCategorySelector = () => {
    let categorySelectOptions: { value: string, label: string }[] = [];
    for (const aCategoryKey in categoriesMatchedSectionId) {
      if (Object.prototype.hasOwnProperty.call(categoriesMatchedSectionId, aCategoryKey)) {
        const element = categoriesMatchedSectionId[aCategoryKey];
        categorySelectOptions.push({
          value: element.key,
          label: element.key + " - " + element.title.fr
        })
      }
    };

    return (
      <Select
        value={selectedCategory}
        onChange={(e) => selectACategory(e)}
        placeholder={"Sélectionnez une categorie."}
        options={categorySelectOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: appTheme.pixelPerfect(30),
            minHeight: appTheme.pixelPerfect(30),
            borderRadius: appTheme.pixelPerfect(10),
            fontFamily: appTheme.primaryFont,
            borderColor: appTheme.grow,
            fontSize: appTheme.pixelPerfectForFont(6),
            display: "flex",
            alignItems: "center",
            paddingTop: 0,
            paddingBottom: 0,
            flexWrap: "unset",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            fontFamily: appTheme.primaryFont,
            fontSize: appTheme.pixelPerfectForFont(6),
          })
        }}
      />
    )
  };

  // ********** PARTIE POUR LE BOUTON SWITCH DU STATUS **********
  const [animatedValue] = useState(new Animated.Value(0));

  const toggleSwitch = () => {
    setIsPublished(!isPublished);
    setCategoryStatusChanged(!categoryStatusChanged)

    // Animation du bouton
    Animated.timing(animatedValue, {
      toValue: isPublished ? 0 : 1,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  const getSwitchButtonForStatus = () => {
    const thumbPosition = animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [2, 28],
    });

    return (
      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: '#fff', marginBottom: appTheme.pixelPerfect(10) }}>
        <TouchableOpacity onPress={toggleSwitch}>
          <View style={{
            // Style pour le track
            width: appTheme.pixelPerfect(50),
            height: appTheme.pixelPerfect(25),
            borderRadius: appTheme.pixelPerfect(25),
            backgroundColor: isPublished === true ? '#117864' : '#d0d3d4',
            padding: 2,
            justifyContent: 'center',
          }}>
            <Animated.View
              style={{
                // Style pour le bouton rond (thumb)
                width: appTheme.pixelPerfect(20),
                height: appTheme.pixelPerfect(20),
                borderRadius: appTheme.pixelPerfect(10),
                backgroundColor: "#f4f3f4",
                transform: [{ translateX: thumbPosition }]
              }}
            />
          </View>
        </TouchableOpacity>
        <Text style={{ paddingHorizontal: appTheme.pixelPerfect(20) }}>{isPublished === true ? "Published" : "Draft"}</Text>
      </View>
    )
  };


  // ********** PARTIE POUR LA SAISIE DU TITRE DE LA CATEGORY  **********
  type LanguageOption = {
    value: string;
    label: string
  };

  const languageOptions: LanguageOption[] = [
    { "value": "fr", "label": "Français" },
    { "value": "en", "label": "Anglais" },
    { "value": "de", "label": "Allemand" },
    { "value": "es", "label": "Espagnol" },
    { "value": "it", "label": "Italien" },
    { "value": "pt", "label": "Portugais" },
    { "value": "ar", "label": "Arabe" },
    { "value": "zh", "label": "Chinois" },
    { "value": "hi", "label": "Hindi" },
    { "value": "bn", "label": "Bengali" },
    { "value": "ru", "label": "Russe" },
    { "value": "ja", "label": "Japonais" },
    { "value": "ko", "label": "Coréen" },
    { "value": "vi", "label": "Vietnamien" },
    { "value": "ur", "label": "Ourdou" },
    { "value": "mr", "label": "Marathi" },
    { "value": "ta", "label": "Tamoul" },
    { "value": "tr", "label": "Turc" },
    { "value": "te", "label": "Télougou" },
    { "value": "fa", "label": "Persan" },
    { "value": "pa", "label": "Pendjabi" },
    { "value": "id", "label": "Indonésien" },
    { "value": "pl", "label": "Polonais" },
    { "value": "uk", "label": "Ukrainien" },
    { "value": "ro", "label": "Roumain" },
    { "value": "nl", "label": "Néerlandais" },
    { "value": "th", "label": "Thaï" },
    { "value": "ms", "label": "Malais" },
    { "value": "my", "label": "Birman" },
    { "value": "hu", "label": "Hongrois" },
    { "value": "el", "label": "Grec" },
    { "value": "cs", "label": "Tchèque" },
    { "value": "sv", "label": "Suédois" },
    { "value": "bg", "label": "Bulgare" },
    { "value": "ab", "label": "Abkhaze" },
    { "value": "aa", "label": "Afar" },
    { "value": "af", "label": "Afrikaans" },
    { "value": "ak", "label": "Akan" },
    { "value": "sq", "label": "Albanais" },
    { "value": "am", "label": "Amharique" },
    { "value": "an", "label": "Aragonais" },
    { "value": "hy", "label": "Arménien" },
    { "value": "as", "label": "Assamais" },
    { "value": "av", "label": "Avar" },
    { "value": "ae", "label": "Avestique" },
    { "value": "ay", "label": "Aymara" },
    { "value": "az", "label": "Azéri" },
    { "value": "bm", "label": "Bambara" },
    { "value": "ba", "label": "Bachkir" },
    { "value": "eu", "label": "Basque" },
    { "value": "be", "label": "Biélorusse" },
    { "value": "bh", "label": "Bihari" },
    { "value": "bi", "label": "Bichelamar" },
    { "value": "bs", "label": "Bosnien" },
    { "value": "br", "label": "Breton" },
    { "value": "ca", "label": "Catalan" },
    { "value": "ch", "label": "Chamorro" },
    { "value": "ce", "label": "Tchétchène" },
    { "value": "ny", "label": "Chichewa" },
    { "value": "cu", "label": "Slavon d'église" },
    { "value": "cv", "label": "Tchouvache" },
    { "value": "kw", "label": "Cornique" },
    { "value": "co", "label": "Corse" },
    { "value": "cr", "label": "Cree" },
    { "value": "hr", "label": "Croate" },
    { "value": "da", "label": "Danois" },
    { "value": "dv", "label": "Divehi" },
    { "value": "dz", "label": "Dzongkha" },
    { "value": "eo", "label": "Espéranto" },
    { "value": "et", "label": "Estonien" },
    { "value": "ee", "label": "Ewe" },
    { "value": "fo", "label": "Féroïen" },
    { "value": "fj", "label": "Fidjien" },
    { "value": "fi", "label": "Finnois" },
    { "value": "ff", "label": "Peul" },
    { "value": "gl", "label": "Galicien" },
    { "value": "ka", "label": "Géorgien" },
    { "value": "gn", "label": "Guarani" },
    { "value": "gu", "label": "Gujarati" },
    { "value": "ht", "label": "Haïtien" },
    { "value": "ha", "label": "Haoussa" },
    { "value": "he", "label": "Hébreu" },
    { "value": "hz", "label": "Herero" },
    { "value": "ho", "label": "Hiri Motu" },
    { "value": "ia", "label": "Interlingua" },
    { "value": "ie", "label": "Interlingue" },
    { "value": "ga", "label": "Irlandais" },
    { "value": "ig", "label": "Igbo" },
    { "value": "ik", "label": "Inupiaq" },
    { "value": "io", "label": "Ido" },
    { "value": "is", "label": "Islandais" },
    { "value": "iu", "label": "Inuktitut" },
    { "value": "jv", "label": "Javanais" },
    { "value": "kl", "label": "Groenlandais" },
    { "value": "kn", "label": "Kannada" },
    { "value": "kr", "label": "Kanouri" },
    { "value": "ks", "label": "Kashmiri" },
    { "value": "kk", "label": "Kazakh" },
    { "value": "km", "label": "Khmer" },
    { "value": "ki", "label": "Kikuyu" },
    { "value": "rw", "label": "Kinyarwanda" },
    { "value": "ky", "label": "Kirghiz" },
    { "value": "kv", "label": "Komi" },
    { "value": "kg", "label": "Kongo" },
    { "value": "kj", "label": "Kwanyama" },
    { "value": "ku", "label": "Kurde" },
    { "value": "lo", "label": "Laotien" },
    { "value": "la", "label": "Latin" },
    { "value": "lv", "label": "Letton" },
    { "value": "li", "label": "Limbourgeois" },
    { "value": "ln", "label": "Lingala" },
    { "value": "lt", "label": "Lituanien" },
    { "value": "lu", "label": "Luba-Katanga" },
    { "value": "lg", "label": "Ganda" },
    { "value": "lb", "label": "Luxembourgeois" },
    { "value": "mk", "label": "Macédonien" },
    { "value": "mg", "label": "Malgache" },
    { "value": "ml", "label": "Malayâlam" },
    { "value": "mt", "label": "Maltais" },
    { "value": "gv", "label": "Mannois" },
    { "value": "mi", "label": "Maori" },
    { "value": "mh", "label": "Marshallais" },
    { "value": "mn", "label": "Mongol" },
    { "value": "na", "label": "Nauruan" },
    { "value": "nv", "label": "Navajo" },
    { "value": "nd", "label": "Ndébéle du Nord" },
    { "value": "nr", "label": "Ndébéle du Sud" },
    { "value": "ng", "label": "Ndonga" },
    { "value": "ne", "label": "Népalais" },
    { "value": "no", "label": "Norvégien" },
    { "value": "ii", "label": "Yi du Sichuan" },
    { "value": "oc", "label": "Occitan" },
    { "value": "oj", "label": "Ojibwé" },
    { "value": "or", "label": "Oriya" },
    { "value": "om", "label": "Oromo" },
    { "value": "os", "label": "Ossète" },
    { "value": "pi", "label": "Pâli" },
    { "value": "ps", "label": "Pachto" },
    { "value": "qu", "label": "Quechua" },
    { "value": "rm", "label": "Romanche" },
    { "value": "rn", "label": "Kirundi" },
    { "value": "sa", "label": "Sanskrit" },
    { "value": "sc", "label": "Sarde" },
    { "value": "sd", "label": "Sindhi" },
    { "value": "se", "label": "Sami du Nord" },
    { "value": "sm", "label": "Samoan" },
    { "value": "sg", "label": "Sango" },
    { "value": "sr", "label": "Serbe" },
    { "value": "gd", "label": "Gaélique écossais" },
    { "value": "sn", "label": "Shona" },
    { "value": "si", "label": "Singhalais" },
    { "value": "sk", "label": "Slovaque" },
    { "value": "sl", "label": "Slovène" },
    { "value": "so", "label": "Somali" },
    { "value": "st", "label": "Sotho du Sud" },
    { "value": "su", "label": "Soundanais" },
    { "value": "sw", "label": "Swahili" },
    { "value": "ss", "label": "Swati" },
    { "value": "tl", "label": "Tagalog" },
    { "value": "tn", "label": "Tswana" },
    { "value": "to", "label": "Tongien" },
    { "value": "tk", "label": "Turkmène" },
    { "value": "ts", "label": "Tsonga" },
    { "value": "tt", "label": "Tatar" },
    { "value": "tw", "label": "Twi" },
    { "value": "ty", "label": "Tahitien" },
    { "value": "ug", "label": "Ouïghour" },
    { "value": "vo", "label": "Volapük" },
    { "value": "wa", "label": "Wallon" },
    { "value": "cy", "label": "Gallois" },
    { "value": "wo", "label": "Wolof" },
    { "value": "xh", "label": "Xhosa" },
    { "value": "yi", "label": "Yiddish" },
    { "value": "yo", "label": "Yoruba" },
    { "value": "za", "label": "Zhuang" },
    { "value": "zu", "label": "Zoulou" }
  ];


  const onAddCategoryNewTitle = () => {
    if (selectedTitleLanguage) {
      if (category.title.length === 0 || category.title[selectedTitleLanguage.value] === undefined) {
        onTitleChange({ lang: selectedTitleLanguage.value, value: "" })
      }
      setSelectedTitleLanguage(null)
    }
  };

  const onTitleChange = (data: { lang: string, value: string }) => {
    const { lang, value } = data;
    let updatedCategory: Category = { ...category };
    let updatedTitle = updatedCategory.title;
    updatedTitle[lang] = value;
    setCategory(updatedCategory)
  };

  const getNewTitleLanguageSelector = () => {
    return (
      <View style={{ width: "100%", zIndex: 3 }}>
        {/* Sélecteur pour choisir la langue */}
        <View style={{ display: "flex", flexDirection: "row", width: "100%", zIndex: 3, marginBottom: appTheme.pixelPerfect(10) }}>
          <View style={{ width: "20%", zIndex: 4 }} >
            <Select
              value={selectedTitleLanguage}
              onChange={(selectedOption) => setSelectedTitleLanguage(selectedOption)}
              options={languageOptions}
              placeholder="Sélectionner la langue"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: appTheme.pixelPerfect(30),
                  minHeight: appTheme.pixelPerfect(30),
                  borderRadius: appTheme.pixelPerfect(10),
                  borderColor: appTheme.grow,
                  fontFamily: appTheme.primaryFont,
                  fontSize: appTheme.pixelPerfectForFont(6),
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 0,
                  paddingBottom: 0,
                  flexWrap: "unset",
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                  ...styles,
                  fontFamily: appTheme.primaryFont,
                  fontSize: appTheme.pixelPerfectForFont(6),
                })
              }}
            />
          </View>
          <View style={{ width: "20%", marginLeft: appTheme.pixelPerfect(10) }}>
            {/* Bouton Ajouter */}
            <Button
              onPress={() => onAddCategoryNewTitle()}
              title={"Ajouter une langue"}
              style={{ height: appTheme.pixelPerfect(30) }}
              textStyle={{ fontSize: appTheme.pixelPerfectForFont(6) }}
            />
          </View>
        </View>
      </View>
    );
  };

  const getTitles = () => {
    if (category !== undefined) {
      let titlesView = [];
      for (const aTitleKey in category.title) {
        if (Object.prototype.hasOwnProperty.call(category.title, aTitleKey)) {
          const aTitle: string = category.title[aTitleKey];
          titlesView.push(
            <View key={aTitleKey} style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginBottom: appTheme.pixelPerfect(10) }}>
              <View style={{ width: "20%", alignItems: "flex-start" }}>
                <Text style={[labelStyle, { verticalAlign: "top" }]}>{languagesByKey[aTitleKey] + " : "}</Text>
              </View>
              <View style={{ width: "80%", height: appTheme.pixelPerfect(30) }}>
                <TextInput onChangeText={(e) => onTitleChange({ lang: aTitleKey, value: e })} value={aTitle} style={[inputStyle, { height: appTheme.pixelPerfect(30), width: "100%" }]} />
              </View>
            </View>
          )
        }
      }
      return titlesView
    }
  };

  // ********** PARTIE POUR LA BODY DE LA CATGORIE ***********
  const onAddNewBody = () => {
    if (selectedBodyLanguage) {
      // Initialiser avec un tableau vide si la langue n'existe pas encore
      if (!Array.isArray(category.body[selectedBodyLanguage.value])) {
        onBodyChange({ lang: selectedBodyLanguage.value, value: [""] }); // Ajoute un tableau contenant un commentaire vide
      }
      setSelectedBodyLanguage(null); // Réinitialise la sélection
    }
  };

  const onBodyChange = (data: { lang: string, value: [""] }) => {
    const { lang, value } = data;
    let updatedCategory: Category = { ...category };
    let updatedBody = updatedCategory.body;
    updatedBody[lang] = value;
    updatedCategory.body = updatedBody;
    setCategory(updatedCategory)
  };

  const onChangeDescription = (data: { lang: string, value: string, index: number }) => {
    const { lang, value, index } = data;
    let updatedCategory: Category = { ...category };
    let updatedBody = updatedCategory.body;
    // Vérifier et s'assurer que `updatedBody[lang]` est un tableau
    if (!Array.isArray(updatedBody[lang])) {
      updatedBody[lang] = []; // Initialiser en tant que tableau vide si ce n'est pas déjà le cas
    };

    // Mettre à jour l'index spécifique dans le tableau
    updatedBody[lang][index] = value;
    updatedCategory.body = updatedBody;
    setCategory(updatedCategory);
  }

  // Fonction pour ajouter un nouveau commentaire à une langue spécifique
  const addANewDescToLanguage = (lang: string) => {
    const descriptions = Array.isArray(category.body[lang]) ? category.body[lang] : []; // Assure que c'est un tableau
    let updatedDescriptions = [...descriptions, ""]; // Ajoute un nouveau commentaire vide
    onBodyChange({ lang, value: updatedDescriptions });
  };

  const getNewBodySelector = () => {
    return (
      <View style={{ width: "100%", zIndex: 2 }}>
        {/* Sélecteur pour choisir la langue */}
        <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: appTheme.pixelPerfect(10), zIndex: 2 }}>
          <View style={{ width: "20%" }} >
            <Select
              value={selectedBodyLanguage}
              onChange={(selectedOption) => setSelectedBodyLanguage(selectedOption)}
              options={languageOptions}
              placeholder="Sélectionner la langue"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: appTheme.pixelPerfect(30),
                  minHeight: appTheme.pixelPerfect(30),
                  borderRadius: appTheme.pixelPerfect(10),
                  borderColor: appTheme.grow,
                  fontFamily: appTheme.primaryFont,
                  fontSize: appTheme.pixelPerfectForFont(6),
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 0,
                  paddingBottom: 0,
                  flexWrap: "unset",
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                  ...styles,
                  fontFamily: appTheme.primaryFont,
                  fontSize: appTheme.pixelPerfectForFont(6),
                })
              }}
            />
          </View>
          <View style={{ width: "20%", marginLeft: appTheme.pixelPerfect(10) }}>
            {/* Bouton Ajouter */}
            <Button
              onPress={() => onAddNewBody()}
              title={"Ajouter une langue"}
              style={{ height: appTheme.pixelPerfect(30) }}
              textStyle={{ fontSize: appTheme.pixelPerfectForFont(6) }}
            />
          </View>
        </View>
      </View>
    )
  };

  const getBodies = () => {
    if (category !== undefined) {

      let bodiesViews = [];

      for (const aBodyKey in category.body) {
        if (Object.prototype.hasOwnProperty.call(category.body, aBodyKey)) {
          const aBody: string = category.body[aBodyKey];
          bodiesViews.push(
            <View key={aBodyKey} style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: appTheme.pixelPerfect(10) }}>
              <View style={{ width: "20%" }}>
                <Text style={[labelStyle]}>{languagesByKey[aBodyKey] + " : "}</Text>
              </View>
              <View style={{ width: "65%", display: "flex" }}>
                {category.body[aBodyKey].map((description, index) => (
                  <View key={index} >
                    <TextInput
                      value={description}
                      onChangeText={(e) => onChangeDescription({ lang: aBodyKey, index, value: e })}
                      multiline={true}
                      style={[inputStyle,
                        {
                          height: appTheme.pixelPerfect(100),
                          width: "100%",
                        }]}
                    />
                  </View>
                ))}
              </View>
              <View style={{ width: "15%" }}>
                <Button
                  onPress={() => addANewDescToLanguage(aBodyKey)}
                  title="+1 Desc."
                  style={{ height: appTheme.pixelPerfect(30), marginLeft: appTheme.pixelPerfect(10) }}
                  textStyle={{ fontSize: appTheme.pixelPerfectForFont(6) }}
                />
              </View>
            </View>
          )
        }
      }
      return bodiesViews
    }
  };

  // ********** PARTIE POUR LA SAISIE DU NOM DE LA CATEGORIE QUI DÉTERMINE LA CLÉ ET L'ANALYTICS KEY **********
  const onChangeCategoryKey = (text: string) => {
    setCategoryName(text);
    let transformedCategoryKey: string = text.toLowerCase()
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z]/g, " ")
      .split(' ')
      .map((word, index) => {
        if (index === 0) {
          return word
        } else {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          )
        }
      })
      .join('')

    if (categories.data[transformedCategoryKey] === undefined) {
      setCategoryKeyError("")
      let updatedCategory: Category = { ...category }
      updatedCategory.key = transformedCategoryKey
      setCategory(updatedCategory)
      setTempCategoryKey(transformedCategoryKey)
    } else {
      setCategoryKeyError("Cette clé de catégorie existe déjà dans la base des categories. Veuillez saisir un autre nom pour cette nouvelle catégorie.")
    }
  };

  const createMyOwnCategoryKey = (text: string) => {
    if (categories.data[text] === undefined) {
      setCategoryKeyError("")
      let updatedCategory: Category = { ...category }
      updatedCategory.key = text
      setCategory(updatedCategory)
      setTempCategoryKey(text)
    } else {
      setCategoryKeyError("Cette clé de catégorie existe déjà dans la base des categories. Veuillez saisir un autre nom pour cette nouvelle catégorie.")
    }
  };

  const getCategoryKey = () => {
    const categoryKeyErrorLabel = categoryKeyError.length > 0 ? <Text style={{ color: "#800000" }}>{categoryKeyError}</Text> : <View />
    return (
      <View>
        <View style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
          <View style={{ width: "45%" }}>
            <Text>Nom que vous souhaitez donner à cette nouvelle catégorie</Text>
            <TextInput
              value={categoryName}
              onChangeText={(text) => onChangeCategoryKey(text)}
              style={[inputStyle, { height: appTheme.pixelPerfect(30) }]}
              multiline={false}
              maxLength={40} //  limiter le nombre de caractères à 40
              placeholder="Saisissez le nom de la nouvelle catégorie"
            />
          </View>
          <View style={{ width: "45%" }}>
            <Text>Clé qui sera enregistrée pour cette nouvelle catégorie</Text>
            <TextInput
              value={category.key}
              style={[inputStyle, { height: appTheme.pixelPerfect(30) }]}
              multiline={false}
              placeholder={"Généré automatiquement mais éditable"}
              onChangeText={(text) => createMyOwnCategoryKey(text)}
            />
          </View>
        </View>
        {categoryKeyErrorLabel}
      </View>
    )
  };

  // ********** PARTIE POUR LA SUGGESTION DE L'ANALYTICS KEY **********

  const onChangeCategoryAnalyticsKey = (text: string) => {
    let tempAnalyticsKey = text.toUpperCase()
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .replace(/[^A-Z]/g, "_")

    let updatedCategory: Category = { ...category };
    updatedCategory.analytics_key = tempAnalyticsKey;
    setCategory(updatedCategory)
  }

  const getNewAnalyticsKey = () => {
    return (
      <View>
        <TextInput
          value={category.analytics_key}
          style={[inputStyle, { width: "100%", height: appTheme.pixelPerfect(30), fontFamily: appTheme.primaryFont }]}
          onChangeText={(text) => onChangeCategoryAnalyticsKey(text)}
          placeholder="Généré automatiquement"
        />
      </View>
    )
  };

  // ********** PARTIE POUR LA SAISIE DU ORDER "ORD" **********
  // Générer les options de 0 à 100 une seule fois
  const categoryOrderOptions = Array.from({ length: 101 }, (_, i) => ({
    value: i,
    label: i.toString(),
  }));

  const handleSelectChange = (selectedOption) => {
    const newOrd = selectedOption ? selectedOption.value : null;
    let updatedCategory: Category = { ...category }
    updatedCategory.ord = newOrd
    setCategory(updatedCategory)
  };
  const getOrder = () => {

    return (
      <View style={{ zIndex: 3, height: appTheme.pixelPerfect(30) }}>
        <Select
          value={categoryOrderOptions.find((option) => option.value === category.ord) || null}
          onChange={handleSelectChange}
          options={categoryOrderOptions}
          placeholder={"Sélectionnez un nombre"}

          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: appTheme.pixelPerfect(30),
              minHeight: appTheme.pixelPerfect(30),
              borderRadius: appTheme.pixelPerfect(10),
              borderColor: appTheme.grow,
              fontFamily: appTheme.primaryFont,
              fontSize: appTheme.pixelPerfectForFont(6),
              display: "flex",
              alignItems: "center",
              paddingTop: 0,
              paddingBottom: 0,
              flexWrap: "unset",
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
              ...styles,
              fontFamily: appTheme.primaryFont,
              fontSize: appTheme.pixelPerfectForFont(6)
            })
          }}
        />
      </View>
    );
  };
  // ********** PARTIE POUR LA SAISIE DU MINIMUM BUILD NUMBER **********
  const onChangeCategoryMinBuildNumber = (text: string) => {
    //TO DO : Vérifier que text est composé de chiffres uniquement
    let isNumber: boolean = /^\d*$/.test(text);
    if ((isNumber === true) || (text.length === 0)) {
      let updatedCategory: Category = { ...category };
      updatedCategory.minBuildNumber = text === "" ? null : parseInt(text, 10);
      setCategory(updatedCategory)
      setMinBuildNumberError("");
    } else {
      setMinBuildNumberError("Le numéro de build doit être un nombre !")
    }
  };

  const getMinBuildNumber = () => {
    const errorLabelView: React.JSX.Element = minBuildNumberError.length > 0 ? <Text style={{ color: "#800000" }}>{minBuildNumberError}</Text> : <View />;
    const inputBorderColor: string = minBuildNumberError.length > 0 ? "#800000" : appTheme.grow;
    return (
      <View>
        <TextInput
          value={category.minBuildNumber}
          onChangeText={(text) => onChangeCategoryMinBuildNumber(text)}
          style={[inputStyle, { width: "100%", borderColor: inputBorderColor, height: appTheme.pixelPerfect(30) }]}
          placeholder="Saisissez un nombre"
        />
        {errorLabelView}
      </View>
    )
  };

  // ********** ORDER, KEY, MIN BUILD NUMBER ON THE SAIME LINE **********
  const getOrderKeyMinBuildNumberSameLine = () => {
    return (
      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", zIndex: 1 }}>
        <View style={{ width: appTheme.pixelPerfect(200) }}>
          <Text style={labelStyle}>Analytics Key</Text>
          <Divider size={5} />
          {getNewAnalyticsKey()}
        </View>
        <View style={{ width: appTheme.pixelPerfect(200), zIndex: 2 }}>
          <Text style={labelStyle}>Ordre de la catégorie</Text>
          <Divider size={5} />
          {getOrder()}
        </View>
        <View style={{ width: appTheme.pixelPerfect(200) }}>
          <Text style={labelStyle}>Min. Build Number</Text>
          <Divider size={5} />
          {getMinBuildNumber()}
        </View>
      </View>
    )
  }

  // ********** PARTIE POUR LA DÉFINITION DE LA COULEUR **********
  const onChangeBtnColor = (text: string) => {
    let updatedCategory: Category = { ...category }
    updatedCategory.clr.btn = text
    setCategory(updatedCategory)
  }
  const onChangeHeaderBgClr = (text: string) => {
    let updatedCategory: Category = { ...category }
    updatedCategory.clr.header_bg = text
    setCategory(updatedCategory)
  }

  const onChangeNavClr = (text: string) => {
    let updatedCategory: Category = { ...category }
    updatedCategory.clr.nav = text
    setCategory(updatedCategory)
  };

  const onChangeTxtClr = (text: string) => {
    let updatedCategory: Category = { ...category }
    updatedCategory.clr.txt = text
    setCategory(updatedCategory)
  };

  const getClrForCategoryElement = () => {
    return (
      <View style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <View>
          <Text>Couleur du btn"</Text>
          <TextInput
            value={category?.clr?.btn}
            onChangeText={(text) => onChangeBtnColor(text)}
            style={[inputStyle, { width: appTheme.pixelPerfect(150), height: appTheme.pixelPerfect(30), fontFamily: appTheme.primaryFont }]}
            editable={sectionId === "inspire" ? false : true}
            placeholder="Couleur du bouton"
          />
        </View>
        <View>
          <Text>Couleur du header-bg</Text>
          <TextInput
            value={category?.clr?.header_bg}
            onChangeText={(text) => onChangeHeaderBgClr(text)}
            style={[inputStyle, { width: appTheme.pixelPerfect(150), height: appTheme.pixelPerfect(30), fontFamily: appTheme.primaryFont }]}
            editable={sectionId === "inspire" ? false : true}
            placeholder="Couleur de fond du header"
          />
        </View>
        <View>
          <Text>Couleur de la nav</Text>
          <TextInput
            value={category?.clr?.nav}
            onChangeText={(text) => onChangeNavClr(text)}
            style={[inputStyle, { width: appTheme.pixelPerfect(150), height: appTheme.pixelPerfect(30), fontFamily: appTheme.primaryFont }]}
            editable={sectionId === "inspire" ? false : true}
            placeholder="Couleur de la navigation"
          />
        </View>
        <View>
          <Text>Couleur du txt</Text>
          <TextInput
            value={category?.clr?.txt}
            onChangeText={(text) => onChangeTxtClr(text)}
            style={[inputStyle, { width: appTheme.pixelPerfect(150), height: appTheme.pixelPerfect(30), fontFamily: appTheme.primaryFont }]}
            editable={sectionId === "inspire" ? false : true}
            placeholder="Couleur du texte"
          />
        </View>
      </View>
    )
  };

  //********** MODAL MESSAGE SI SUCCÈS ENREGISTREMENT OU UPDATE **********
  const closeModal = () => {
    setMessage("")
    goBack()
  }

  const getSaveSuccessMessage = () => {
    if (message !== "") {
      return (
        <View style={{ position: "fixed" as const, width: "80%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 1000 }}>
          <View style={{ backgroundColor: "#fff", padding: appTheme.pixelPerfect(20), borderRadius: appTheme.pixelPerfect(8), boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", maxWidth: appTheme.pixelPerfect(500), textAlign: "center" as const, zIndex: 1001 }}>
            <Text style={[h3Style, { marginVertical: appTheme.pixelPerfect(20) }]}>{message}</Text>
            <Button
              onPress={() => closeModal()}
              title="Fermer"
              style={{ height: appTheme.pixelPerfect(30), marginLeft: appTheme.pixelPerfect(10) }}
              textStyle={{ fontSize: appTheme.pixelPerfectForFont(6) }} />
          </View>
        </View>
      )
    } else {
      return <View />
    }
  };


  // ********** BOUTON POUR FAIRE APPARAITRE LE FORMULAIRE DE CRÉATION D'UNE NOUVELLE CATEGORY **********
  const getANewCategoryForm = () => {
    if (selectedCategory !== undefined) {
      setSelectedCategory(undefined)
    }
    setNewCategory(true)
  };

  const createNewCategoryButton = () => {
    return (
      <Button disabled={(section === undefined || selectedCategory !== undefined)}
        style={{ width: appTheme.pixelPerfect(300) }}
        title={"Créer une nouvelle categorie"}
        onPress={() => getANewCategoryForm()} />
    )
  };

  const getContent = () => {
    if (viewDidAppear === false) {
      return <View onLayout={onViewWillAppear} style={{ width: "100%", flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator color={appTheme.grow} loading={true} />
      </View>
    };

    return (
      <View style={{ width: "100%" }}>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          {createNewCategoryButton()}
        </View>
        <Text style={labelStyle}>Sélection de la section</Text>
        <Divider size={5} />
        {getSectionSelector()}
        <Divider size={10} />

        <Text style={labelStyle}>Sélection d'une categorie existante</Text>
        <Divider size={5} />
        {getExistingCategorySelector()}
        <Divider size={10} />

        {category !== undefined && (showForm === true || selectedCategory !== undefined) ? <View style={{ width: "100%", height: 1, backgroundColor: "#909090" }}>

          <Divider size={20} />
          <View >
            <Text style={labelStyle}>Status de la catégorie</Text>
            <Divider size={5} />
            {getSwitchButtonForStatus()}
          </View>

          <Divider size={10} />
          <View style={{ zIndex: 2 }}>
            <Text style={labelStyle}>Titre de la catégorie</Text>
            <Divider size={5} />
            {getNewTitleLanguageSelector()}
          </View>
          {getTitles()}

          <Divider size={10} />
          <View style={{ zIndex: 1 }}>
            <Text style={labelStyle}>Description de la catégorie (body)</Text>
            <Divider size={5} />
            {getNewBodySelector()}
          </View>
          <View>
            {getBodies()}
          </View>


          <Divider size={10} />
          <Text style={labelStyle}>Nom de la catégorie (clé)</Text>
          <Divider size={5} />
          {getCategoryKey()}

          <Divider size={10} />
          {getOrderKeyMinBuildNumberSameLine()}

          <Divider size={10} />
          <Text style={labelStyle}>Couleurs des éléments</Text>
          <Divider size={5} />
          {getClrForCategoryElement()}

          <Divider size={10} />
          <View style={{ width: "100%", display: "flex", alignItems: "center", paddingTop: appTheme.pixelPerfect(50), paddingBottom: appTheme.pixelPerfect(50) }}>
            <Button
              style={{ width: appTheme.pixelPerfect(300) }}
              title={newCategory === true ? "Enregistrer" : "Mettre à jour"}
              onPress={() => dbCreateAndUpdateCategory()} />
          </View>
        </View> : <View />}
        <Divider />
      </View>
    )
  };

  return (
    <View style={{ padding: appTheme.pixelPerfect(20) }}>
      {getSaveSuccessMessage()}
      <View style={{ flexDirection: "row", marginBottom: appTheme.pixelPerfect(10) }}>
        <TouchableOpacity onPress={() => goBack()}>
          <Text style={[aStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
            Dashboard
          </Text>
        </TouchableOpacity>
        <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
          {">"}
        </Text>
        <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
          Catégories
        </Text>
      </View>
      {getContent()}


    </View>
  )
};

export default LHQCategoriesManagement;
