import CasClient, { constant } from "../../utils/CasClient";
import { Account, Client, Databases, ID, Query } from 'appwrite';
import { build, version } from "../../../../package.json";
import { AppTarget, getAppTarget } from "../../utils/Navigation/Host";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Configuration LOGS
const tneLogAppDatabaseId = "673b0aba7afc638025ee";
const tneLogAppCollectionId = "673b0ac44f9a2ba3dbe3";


const host = window.location.hostname;
let client = undefined;
let isTneApp = false;
let account = undefined;
let databases = undefined;
const tneAppApiUrl = "https://api.lili.cool/v1";
const appTarget = getAppTarget();
const tneAppProjectId = "6392ed8493c6811ab73f";
const tneAppDatabaseId = "63b2a188b1fd08721a8d";
const tneAppUsersCollectionId = "63b2a1aea2a53b245cbf";
if (appTarget !== "public") {
    isTneApp = true;
    client = new Client();
    client.setEndpoint(tneAppApiUrl).setProject(tneAppProjectId);
    account = new Account(client);
    databases = new Databases(client);
}

// Configuration CAS
const isProd = true;
const isGar = ((appTarget === "gar-prod") || (appTarget === "gar-preprod")) ? true : false;
const garCasEndpoint = appTarget === "gar-prod" ? "idp-auth.gar.education.fr" : "idp-auth.partenaire.test-gar.education.fr";
const garCasPath = "";
const casEndpoint = appTarget === "gar-prod" ? "www.reseau-canope.fr/" : "val.reseau-canope.fr/";
const casPath = "cas-usagers";
let casOptions = {
    version: constant.CAS_VERSION_3_0,
    path: isGar === true ? garCasPath : casPath,
    validation_proxy: true,
    validation_proxy_path: "/cas_proxy"
};

let casClient = isGar === true ? new CasClient(garCasEndpoint, casOptions) : new CasClient(casEndpoint, casOptions);

// TNE
export const checkCASConnexion = async () => {
    try {
        const authResult = await casClient.auth();
        return authResult;
    } catch (error) {
        throw error;
    };
}

export const redirectToCASLogin = () => {
    casClient.redirectToLoginUrl();
}

export function logoutFromCAS() {
    casClient.logout();
}

export const createAnonymousSession = async () => {
    try {
        const promise = await account.createAnonymousSession();
        return promise;
    } catch (error) {
        throw error;
    }
}

export const deleteCurrentSession = async () => {
    try {
        const promise = await account.deleteSession("current");
        return true;
    } catch (error) {
        return false;
    }
}

export const deleteSessionWithId = async (session_id: string) => {
    try {
        const promise = await account.deleteSession(session_id);
        return true;
    } catch (error) {
        return false;
    }
}

export const getCurrentSession = async () => {
    try {
        const promise = await account.getSession("current");
        return promise;
    } catch (error) {
        return null;
    }
}

export const deleteCurrentUser = async () => {
    try {
        const promise = await account.get();
        const deleteUserRequest = await fetch("https://api.lili.cool/v1/users/" + promise.$id, {
            method: "DELETE",
            headers: {
                "X-Appwrite-Project": "6392ed8493c6811ab73f",
                "Content-Type": "application/json"
            }
        });
        return true;
    } catch (error) {
        return false;
    }
}

export const getCurrentUser = async () => {
    try {
        const promise = await account.get();
        return promise;
    } catch (error) {
        return null;
    }
}

export const getUser = async (id: string) => {
    try {
        let promise = await databases.getDocument(tneAppDatabaseId, tneAppUsersCollectionId, id);
        return promise;
    } catch (error) {
        return null;
    }
}

export const getUserWithGARIDO = async (gar_ido: string) => {
    try {
        let promise = await databases.listDocuments(tneAppDatabaseId, tneAppUsersCollectionId, [Query.equal('IDO', gar_ido)]);
        if (promise.documents.length > 0) {
            return promise.documents[0];
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

export const getUserWithSessionId = async (current_session_id: string) => {
    try {
        let promise = await databases.listDocuments(tneAppDatabaseId, tneAppUsersCollectionId, [Query.equal('current_session_id', current_session_id)]);
        if (promise.documents.length > 0) {
            return promise.documents[0];
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

export const updateUserPrefs = async (user_data: { ido: string, ticket: string, app: string }) => {
    try {
        const { ido, ticket, app } = user_data;
        const promise = await account.updatePrefs({ ido, ticket, app });
        return true
    } catch (error) {
        throw error
    }
}

export const updateUserTicketPref = async (user_data: { ticket: string }) => {
    try {
        const { ticket } = user_data;
        const promise = await account.updatePrefs({ ticket });
        return true
    } catch (error) {
        throw error
    }
}

export const createUserWithGARData = async (user_data: { ido: string, pro: string, p_ms2: string[], uai: string, sessionId: string, ticket: string, authId: string, email: string, firstname: string, lastname: string, demo: boolean }) => {
    try {
        const { ido, pro, p_ms2, uai, sessionId, ticket, authId, email, firstname, lastname, demo } = user_data;
        await databases.createDocument(tneAppDatabaseId, tneAppUsersCollectionId, ID.unique(), {
            "build": build,
            "creation_build": build,
            "creation_version": version,
            "last_update": new Date().getTime(),
            "version"version,
            "email": email,
            "lastname": lastname,
            "firstname": firstname,
            "IDO": ido,
            "PRO": pro,
            "UAI": uai,
            "P_MS2": p_ms2,
            "current_session_id": sessionId,
            "gar_ticket": ticket,
            "current_auth_id": authId,
            "demo": demo
        });
        return true;
    } catch (error) {
        return null;
    }
}

export const updateSessionAndTicketForGarUser = async (user_data: { sessionId: string, ticket: string, userId: string, authId: string }) => {
    try {
        const { sessionId, ticket, userId, authId } = user_data;
        console.log({ sessionId, ticket, userId, authId });
        let promise = await databases.updateDocument(tneAppDatabaseId, tneAppUsersCollectionId, userId, {
            "last_update": new Date().getTime(),
            "current_session_id": sessionId,
            "gar_ticket": ticket,
            "current_auth_id": authId
        });
        console.log("YEAH", promise);
        return true;
    } catch (error) {
        console.log("BOOH", error);
        return null;
    }
}

// Gestion de l'audio
const bucketIdForAudio = "641960bb6ecb47bdeb59";
export const getAudioUrlForMediaId = (data: { media_id: string }) => {
    const { media_id } = data;
    const urlToReturn = tneAppApiUrl + "/storage/buckets/" + bucketIdForAudio + "/files/" + media_id.toLowerCase() + "/view?project=" + tneAppProjectId;
    return urlToReturn;
}

// Gestion des vidéos
const bucketIdForVideo = "641dbaacd6473dc2185a";
export const getVideoUrlForMediaId = (data: { media_id: string }) => {
    const { media_id } = data;
    const urlToReturn = tneAppApiUrl + "/storage/buckets/" + bucketIdForVideo + "/files/" + media_id.toLowerCase() + "/view?project=" + tneAppProjectId;
    return urlToReturn;
}

// Gestion des documents
const bucketIdForDocument = "6438f2c4d03f27cb9ddf";
export const getDocumentUrlForMediaId = (data: { media_id: string }) => {
    const { media_id } = data;
    const urlToReturn = tneAppApiUrl + "/storage/buckets/" + bucketIdForDocument + "/files/" + media_id.toLowerCase() + "/view?project=" + tneAppProjectId;
    return urlToReturn;
}

export const getDocumentUrlForActivityId = (data: { activity_id: string }) => {
    const { activity_id } = data;
    const urlToReturn = tneAppApiUrl + "/storage/buckets/" + bucketIdForDocument + "/files/" + activity_id.toLowerCase() + "/view?project=" + tneAppProjectId;
    return urlToReturn;
}

export const getChapterAudioIdForActivityId = (data: { activity_id: string, index: string }) => {
    const { activity_id, index } = data;
    const urlToReturn = tneAppApiUrl + "/storage/buckets/" + bucketIdForAudio + "/files/" + activity_id.toLowerCase() + "_" + index + "/view?project=" + tneAppProjectId;
    return urlToReturn;
}

export const getContextDocumentUrlForActivityId = (data: { activity_id: string }) => {
    const { activity_id } = data;
    const urlToReturn = tneAppApiUrl + "/storage/buckets/" + bucketIdForDocument + "/files/context_" + activity_id.toLowerCase() + "/view?project=" + tneAppProjectId;
    return urlToReturn;
}

export const getReferrer = async () => {
    let referrerList: string = await sessionStorage.getItem("referrers");
    let referrerArray: string[] = [];
    if ((referrerList !== null) && (referrerList !== undefined)) {
        referrerArray = JSON.parse(referrerList);
    }
    return referrerArray;
}

export const addLog = async (data: { error: string, ticket: string, validate_url: string }) => {
    try {
        const { error, ticket, validate_url } = data;
        const referrer = await getReferrer();
        const casSession: string = sessionStorage.getItem("cas");
        const ticketInformationSession: string = sessionStorage.getItem("ticketInfomation");
        const result = await databases.createDocument(tneLogAppDatabaseId, tneLogAppCollectionId, ID.unique(), {
            "created_at": new Date(),
            "data": JSON.stringify({
                build,
                version,
                error,
                ticket,
                referrer,
                validate_url,
                casSession,
                ticketInformationSession
            })
        });
        console.log({ "addLogResult": result });
        return true;
    } catch (error) {
        console.log({ "addLogResult error": error });
        return null;
    }
}