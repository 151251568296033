import React, { useEffect, useRef, useState, useContext, useMemo } from 'react';
import {
    View,
    ScrollView,
    Text,
    Platform,
    ActivityIndicator,
    Modal,
    NativeSyntheticEvent,
    NativeScrollEvent
} from 'react-native';
import Main from '../../../specific/components/Main/Main';
import MainOptions from '../../models/MainOptions';

// Utils
import * as Constants from "../../utils/Constants";
import i18n from '../../utils/Localization/Localization';
import AppTheme from '../../utils/Theme';
import Image from "../../designSystem/OTBImage";


/* SUPER POWER ACTIVITY LIST */
import SuperPowerItem from './SuperPowerItem';
import Images, { bundleImage } from '../../../specific/utils/Images';

// Data
import { useParams } from "react-router-dom";
import { getLocalActivities, getLocalActivitiesBySections, getLocalCategories } from '../../../specific/utils/LocalStorage';
import User from '../../data/user/User';
import { EventRegister } from '../../utils/EventListeners';
import SuperPowerSheet from './SuperPowerSheet';

import superPowersData from "../../data/jsons/superPrograms.json";

const { superPowersKeys } = Constants;

export const SUPPORT_TYPE = {
    ACTIVITY: "activity",
    PHILO_NOTEBOOK: "philoNotebook",
    BOOK: "book",
    ROW: "row",
    ACTIVITIES: "activities",
    SINGLE: "single",
    SUBSECTIONS: "subsections"
}

export const superPowerDataKeys = [
    superPowersKeys.EMOTIONS_TAMING,
    superPowersKeys.FOCUS,
    superPowersKeys.ERROR_TAMING,
    superPowersKeys.SPEAK,
    superPowersKeys.COLLECTIVE,
    superPowersKeys.CRITICAL_THINKING,
    superPowersKeys.SELF_ESTEEM
]

const SuperPowersPlaylist = (props: any) => {
    let propsData = props;
    if (Platform.OS === "web") {
        propsData = useParams();
    }
    const { superPowersKey } = propsData;
    const { componentId = "" } = propsData;

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const [viewDidAppear, setViewDidAppear] = useState(false);
    const [pageOnScreen, setPageOnScreen] = useState(-1);
    const [loaderDisplayed, setLoaderDisplayed] = useState(false);

    const [playlistPositionned, setPlaylistPositionned] = useState(false);
    const [playlistProgress, setPlaylistProgress] = useState(0);

    const [localActivities, setLocalActivities] = useState({});
    const [localCategories, setLocalCategories] = useState({});
    const [localActivitiesBySections, setLocalActivitiesBySections] = useState({});

    const menuScrollViewRef = useRef<ScrollView>(null);

    const pageWidth = Platform.OS === "web" ? appTheme.getFullAppWidth() : appTheme.getFullAppWidth();

    // 1
    const onViewDidAppear = () => {
        getLocalActivitiesFromStorage();
    }

    // 2
    const getLocalActivitiesFromStorage = async () => {
        const localActivitiesFromStorage = await getLocalActivities();
        setLocalActivities(localActivitiesFromStorage.data);
    }

    // 3
    useEffect(() => {
        if (Object.keys(localActivities).length > 0) {
            getLocalCategoriesFromStorage();
        }
    }, [localActivities]);

    // 4
    const getLocalCategoriesFromStorage = async () => {
        const localCategoriesFromStorage = await getLocalCategories();
        setLocalCategories(localCategoriesFromStorage.data);
    }

    // 5
    useEffect(() => {
        if (Object.keys(localCategories).length > 0) {
            getLocalActivitiesBySectionsFromStorage();
        }
    }, [localCategories]);

    // 6
    const getLocalActivitiesBySectionsFromStorage = async () => {
        const localActivitesBySectionsFromStorage = await getLocalActivitiesBySections();
        setLocalActivitiesBySections(localActivitesBySectionsFromStorage.data);
    }

    // 7
    useEffect(() => {
        if (Object.keys(localActivitiesBySections).length > 0) {
            setViewDidAppear(true);
        }
    }, [localActivitiesBySections]);


    const mainOptions: MainOptions = new MainOptions({
        key: "super_power_playlist",
        navigationColor: "#354F84",
        title: i18n.t("tags." + superPowerDataKeys[pageOnScreen]),
        componentId,
        showNavBar: true,
        showMenu: false,
        safeArea: false,
        canGoBack: true
    });

    const onHorizontalViewLayout = () => {
        if (superPowerDataKeys !== undefined) {
            const indexToShow: number = superPowerDataKeys.indexOf(superPowersKey);
            setPageOnScreen(indexToShow);
            menuScrollViewRef.current?.scrollTo({ x: indexToShow * appTheme.getFullAppWidth(), animated: true });
        }
        setTimeout(() => {
            setPlaylistPositionned(true);
        }, 1000);
    }

    useEffect(() => {
        if (pageOnScreen !== -1) {
            //menuScrollViewRef.current?.scrollTo({ x: pageOnScreen * appTheme.getFullAppWidth(), animated: true });
        }
    }, [pageOnScreen]);

    const onLoaderLayout = () => {
        setTimeout(() => {
            setLoaderDisplayed(true);
        }, 1000);
    }

    // 10
    const getHorizontalMenuView = () => {
        if (viewDidAppear === false) {
            return <View />
        }
        let superPowerItemsView = [];
        const superpowerKeys = Object.keys(superPowersData);
        for (const aSuperPowerKeyIndex in superpowerKeys) {
            if (Object.prototype.hasOwnProperty.call(superpowerKeys, aSuperPowerKeyIndex)) {
                const aSuperPowerKey = superpowerKeys[aSuperPowerKeyIndex];
                const activitiesByCategory = localActivitiesBySections !== undefined ? localActivitiesBySections : {};
                superPowerItemsView.push(<SuperPowerSheet
                    key={aSuperPowerKey}
                    activities={activitiesByCategory}
                    localActivities={localActivities}
                    localCategories={localCategories}
                    componentId={componentId}
                    superPowerData={superPowersData[aSuperPowerKey]}
                    superpowerKey={aSuperPowerKey}
                    isPageOnScreen={parseInt(aSuperPowerKeyIndex) === pageOnScreen} />);
            }
        }
        const paddingTop: number = Platform.OS === "web" ? appTheme.pixelPerfect(54) : 0;
        return <View style={{ height: appTheme.getFullScreenHeight() }}>
            <ScrollView
                onScroll={horizontalViewScroll}
                ref={menuScrollViewRef}
                onLayout={onHorizontalViewLayout}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                pagingEnabled={true}
                style={{ width: pageWidth, height: appTheme.getFullAppHeight(), backgroundColor: appTheme.nightSkyColor, paddingBottom: appTheme.bottomInsets, paddingTop }}>
                {superPowerItemsView}
            </ScrollView>
        </View>
    }

    const getLoadingView = () => {
        if (playlistPositionned === false) {
            const loader = <ActivityIndicator color={appTheme.white} />
            return <View onLayout={onLoaderLayout} style={{ backgroundColor: appTheme.nightSkyColor, width: pageWidth, height: appTheme.getFullScreenHeight(), position: 'absolute', top: 0, left: 0, justifyContent: 'center', alignItems: 'center' }}>
                <View style={[{ marginBottom: appTheme.pixelPerfect(40) }]}>
                    <Image resizeMode="contain" source={images.illSupercat} style={{ width: appTheme.pixelPerfect(150), height: appTheme.pixelPerfect(150) }} />
                </View>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(12), marginBottom: appTheme.pixelPerfect(10), color: appTheme.white }}>{i18n.t("superPowersPlaylist.loading")}</Text>
                {loader}
            </View>;
        }
        return <View />;
    }

    const horizontalViewScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
        const pagePosition = event.nativeEvent.contentOffset.x / event.nativeEvent.layoutMeasurement.width;
        if (pagePosition % 1 === 0) {
            setPageOnScreen(pagePosition);
        }
    }

    const horizontalMenuView = useMemo(() => {
        return getHorizontalMenuView();
    }, [viewDidAppear, pageOnScreen]);

    return (
        <Main mainOptions={mainOptions}>
            <View onLayout={onViewDidAppear} style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight(), backgroundColor: appTheme.nightSkyColor }}>
                {horizontalMenuView}
                {getLoadingView()}
            </View>
        </Main>
    )
}


export default SuperPowersPlaylist;