import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import User from '../data/user/User';
import { keyGdprConsent, keyMood } from './Constants';

import * as SpecificLocalStorage from "../../specific/utils/LocalStorage";
import { doesFileExist, getOfflineUrl, getPathForOriginal } from '../../specific/components/Download/DownloadButton';

const user_type_key = "user_type_key";
const user_session_key = "user_session_key";

// Méthode pour vérifier les adresses locales
export const getUrlsToUse = async (data: { urls: string[], activityKey: string }) => {
    const { urls, activityKey } = data;
    if (Platform.OS !== "web") {
        let newUrls: string[] = [];
        for (const aUrlIndex in urls) {
            if (Object.prototype.hasOwnProperty.call(urls, aUrlIndex)) {
                const anUrl: string = urls[aUrlIndex];
                const urlToUse: string = await getUrlToUse({url:anUrl, activityKey});
                newUrls.push(urlToUse);
            }
        }
        return newUrls;
    }
    return urls;
}

export const getUrlToUse = async (data: { url: string, activityKey: string }) => {
    const { url, activityKey } = data;
    if (Platform.OS !== "web") {
        const anOfflineUrl: string | null = getOfflineUrl({activity_key: activityKey, url});
        if ( anOfflineUrl !== null ) {
            const doesOfflineFileExist: boolean = await doesFileExist(anOfflineUrl);
            if (doesOfflineFileExist === true) {
                return anOfflineUrl;
            }
        }
    }
    return url;
}

export const checkLocalUrls = (urls: string[]) => {
    if (Platform.OS !== "web") {
        let newUrls: string[] = [];
        for (const aUrlIndex in urls) {
            if (Object.prototype.hasOwnProperty.call(urls, aUrlIndex)) {
                const anUrl: string = urls[aUrlIndex];

                if (anUrl.indexOf("/Documents/") !== -1) {
                    const newLocalUrl = checkLocalUrl(anUrl);
                    newUrls.push(newLocalUrl);
                } else {
                    newUrls.push(anUrl);
                }
            }
        }
        return newUrls;
    }
    return urls;
}

export const checkLocalUrl = (url: string) => {
    if (Platform.OS !== "web") {
        if (url.indexOf("/Documents/") !== -1) {
            const newLocalUrl = SpecificLocalStorage.checkLocalUrl(url);
            return newLocalUrl;
        }
    }
    return url;
}

export const wipeData = async () => {
    await AsyncStorage.removeItem(user_type_key);
    await AsyncStorage.removeItem(keyGdprConsent);
    await AsyncStorage.removeItem(keyMood);
    const user: User = User.getInstance();
    await user.deleteUser();
}

const storeData = async (data: { key: string, value: string }) => {
    const { key, value } = data;
    try {
        await AsyncStorage.setItem(key, value);
        return true;
    } catch (e) {
        return undefined;
    }
}

const getData = async (data: { key: string }) => {
    const { key } = data;
    try {
        const value = await AsyncStorage.getItem(key)
        if (value !== null) {
            // value previously stored
            return value;
        } else {
            return undefined;
        }
    } catch (e) {
        // error reading value
        return undefined;
    }
}

/**
 * TEACHER : USER SESSION
 */
export const setUserSession = async (data: { userSession: any }) => {
    const { userSession } = data;
    const storeStatus = await storeData({
        key: user_type_key,
        value: userSession
    });
    return storeStatus;
}

export const getUserSession = async () => {
    const userSession = await getData({
        key: user_session_key
    });
    return userSession;
}

/**
 * USER TYPE : TEACHER OR PARENT
 */
export const getUserType = async () => {
    const userType = await getData({
        key: user_type_key
    });
    return userType;
}

export const isUserATeacher = async () => {
    const userType = await getUserType();
    if (userType !== undefined) {
        return (userType === "teacher");
    }
    return false;
}

export const setUserType = async (data: { defineAsTeacher: boolean }) => {
    const { defineAsTeacher } = data;
    const userTypeValue = defineAsTeacher === true ? "teacher" : "family";
    const storeUserTypeStatus = await storeUserType({
        userType: userTypeValue
    });
}

export const storeUserType = async (data: { userType: string }) => {
    const { userType } = data;
    const storeStatus = await storeData({
        key: user_type_key,
        value: userType
    });
}