import React, { useEffect, useRef, useState } from "react";

import {
    View,
    Text,
    Image,
    Platform,
    TouchableOpacity,
    ScrollView,
    LayoutAnimation
} from "react-native";
import AppTheme from "../../utils/Theme";
import Images, { bundleImage } from "../../../specific/utils/Images";
import GameStyle from "../../styles/game/GameStyle";
import Activity from "../../models/Activity";
import { default as IcChevronLeft } from "../../assets/svg/icons/solid/chevron-left.svg";
import { default as IcChevronRight } from "../../assets/svg/icons/solid/chevron-right.svg";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import MainStyle from "../../styles/game/MainStyle";
import { getLanguage } from "../../utils/Localization/Localization";

import BundleGameLabels from "../../data/jsons/bundleGameLabels.json";
import { getAppTarget } from "../../../specific/utils/Navigation/Host";
import SVGView from "../../utils/SvgView";
import { getPathForOriginal, getShortNameForUrl } from "../../../specific/components/Download/DownloadButton";
import { checkLocalUrl } from "../../utils/LocalStorage";
import { getAudioUrlForMediaId } from "../../../specific/services/AppWrite/AppWriteDatabase";
import { getLocalGameLabels } from "../../../specific/utils/LocalStorage";

type blockElement = {
    l: string,
    a: string
}

interface GameCardsProps {
    activity: Activity,
    color: string,
    playASound: (sound: string, source?: string) => void,
    isMute: boolean,
    setTimer: (on: boolean) => void,
    pauseTimer: boolean
}

let isGamePaused = true;

const GameCards = (props: GameCardsProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const gameStyle: GameStyle = new GameStyle();
    const mainStyle: MainStyle = new MainStyle();

    let dbGameLabelsData = BundleGameLabels.data;

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [blockLabels, setBlockLabels] = useState<any | undefined>(undefined);
    const [blockImages, setBlockImages] = useState<any | undefined>(undefined);
    const [onScreenCard, setOnScreenCard] = useState<number>(-1);
    const [refreshCards, setRefreshCards] = useState<number>(-1);
    const [histo, setHisto] = useState({}); // On va enregitrer l'historique des jeux pour éviter de reproposer trop souvent des labels identiques

    const { color, playASound, activity, isMute, setTimer } = props;

    const widthOfTheGame = Platform.OS === "web" ? appTheme.getFullAppWidth() : appTheme.getFullAppHeight();

    const scrollViewRef = useRef<ScrollView>(null);

    const onViewDidAppear = async () => {
        if (viewDidAppear === false) {
            if (Platform.OS !== "web") {
                const updatedGameLabel = await getLocalGameLabels();
                dbGameLabelsData = updatedGameLabel.data;
            }
            let showTheGame: boolean = true;
            if (activity.action.type === "game") {
                if (blockLabels === undefined) {
                    if (activity.action.auto !== undefined) {
                        launchAutomaticChangeForKeyAndTime(8000);
                    }
                    chooseBlockLabels();
                    showTheGame = false;
                }
            } else if (activity.action.type === "game_img") {
                if (blockImages === undefined) {
                    chooseBlockImages();
                    showTheGame = false;
                }
            }
            if (showTheGame === true) {
                setViewDidAppear(true);
            }
        }
    }

    useEffect(() => {
        if ((blockLabels !== undefined) && (onScreenCard === -1)) {
            setViewDidAppear(true);
            setOnScreenCard(0);
        } else {
            playCurrentLabel();
        }
    }, [blockLabels]);

    useEffect(() => {
        if (onScreenCard > -1) {
            if (activity.action.type === "game") {
                const blockLabelKeys = Object.keys(blockLabels);
                if (onScreenCard === blockLabelKeys.length) {
                    // On va lancer le jeu
                    if (blockLabelKeys.length < 4) {
                        setTimer(true);
                    }
                } else {
                    setTimer(false);
                    playLabel();
                }
            }
        }
    }, [onScreenCard]);

    useEffect(() => {
        if (refreshCards > -1) {
            if (props.pauseTimer === false) {
                chooseBlockLabels();
            }
            launchAutomaticChangeForKeyAndTime();
        }
    }, [refreshCards]);

    const launchAutomaticChangeForKeyAndTime = (delay?: number) => {
        if (activity.action.auto !== undefined) {
            let changeFrequency = activity.action.auto * 1000;
            if (delay !== undefined) {
                changeFrequency = changeFrequency + delay;
            }
            setTimeout(() => {
                // On lance une rafraichissement des cartes, pour prendre en compte le fait de savoir si le jeu est en pause ou non
                setRefreshCards(new Date().getTime());
            }, changeFrequency);
        }
    }

    const getBlockElement = (gameLabels: any[][], blockList: any) => {
        const aRandomNumber = Math.floor(Math.random() * gameLabels[blockList].data.length);
        let blockElement = gameLabels[blockList].data[aRandomNumber]
        const target = getAppTarget();
        if (target !== "public") {
            blockElement = { l: blockElement.l, a: getAudioUrlForMediaId({ media_id: blockList + "_" + aRandomNumber }) };
        }
        return blockElement;
    }

    // Jeu avec images
    const chooseBlockImages = () => {
        setViewDidAppear(true);
        setTimer(true);
    }

    // Jeu classique
    const chooseBlockLabels = () => {
        const { action } = activity;
        const { blocks } = action;
        let newBlockLabels = {};
        let histoForGame = { ...histo };
        if (blocks !== undefined) {
            blocks.forEach(block => {
                if ((blockLabels === undefined) || (block.auto !== undefined)) {
                    const blockList = block.list + "_" + getLanguage();
                    if ((dbGameLabelsData[blockList] !== undefined) && (dbGameLabelsData[blockList].data !== undefined) && (dbGameLabelsData[blockList].data.length > 0)) {
                        let newElement = getBlockElement(dbGameLabelsData, blockList);
                        let histoForlist = histoForGame[blockList] !== undefined ? histoForGame[blockList] : [];
                        if (histoForlist.length === dbGameLabelsData[blockList].length) {
                            histoForlist.splice(0, 1);
                        }
                        while (histoForlist.includes(newElement.l) === true) {
                            newElement = getBlockElement(dbGameLabelsData, blockList);
                        }
                        newBlockLabels[block.key] = newElement;
                        histoForlist.push(newElement.l);
                        if ((histoForlist.length === 11) || (histoForlist.length === dbGameLabelsData[blockList].length - 1)) {
                            histoForlist.splice(0, 1);
                        }
                        histoForGame[blockList] = histoForlist;
                        setHisto(histoForGame);
                    } else {
                        canDisplayGames = false;
                    }
                } else if ((blockLabels !== undefined) && (blockLabels[block.key] !== undefined)) {
                    newBlockLabels[block.key] = blockLabels[block.key];
                }
            });
        }
        setBlockLabels(newBlockLabels);
    }

    const playLabel = async () => {
        playCurrentLabel();
        const blockLabelKeys = Object.keys(blockLabels);
        setTimeout(() => {
            if (blockLabelKeys.length < 4) {
                setOnScreenCard(onScreenCard + 1);
            } else {
                setTimer(true);
            }
        }, 4000);
    }

    const playCurrentLabel = async () => {
        let labelIndex = onScreenCard;
        const blockLabelKeys = Object.keys(blockLabels);
        if (labelIndex === blockLabelKeys.length) {
            labelIndex = labelIndex - 1;
        }
        if (blockLabelKeys[labelIndex] !== undefined) {
            if (isMute === false) {
                const labelToDisplay: blockElement = blockLabels[blockLabelKeys[labelIndex]];
                if (Platform.OS === "web") {
                    await playASound(labelToDisplay.a, "url");
                } else {
                    const stepKey = blockLabelKeys[labelIndex];
                    const stepKeysElements = stepKey.split("_");
                    const dirForOfflineFile = stepKeysElements[0] + "_" + getLanguage();
                    const shortName = getShortNameForUrl(labelToDisplay.a);
                    let localUrl = getPathForOriginal(shortName, "mp3", dirForOfflineFile);
                    localUrl = checkLocalUrl(localUrl);
                    await playASound("file://" + localUrl, "url");
                }
            }
        }
    }


    const launchEasterEgg = () => {

    }

    const onBlockScroll = (e) => {
        const { action } = activity;
        let numberOfBlocks: number = 2;
        let imageHeight: number = appTheme.getFullAppWidth() - appTheme.pixelPerfect(120); // On prend en compte la hauteur du Timer et du
        let imageWidth: number = imageHeight * 360 / 270;
        let widthForBlocks: number = imageWidth;
        if (action.type === "game") {
            const { blocks } = action;
            //L'espace total à utiliser est la hauteur du tel, moins 16à pixel de marge sur les côté
            //Jusqu'à 3 blocs on affiche tout sur un écran. Au dessus, on aura une scrollview horizontal donc on prend la valeur pour 2
            numberOfBlocks = blocks.length > 3 ? 2 : blocks.length;
            const separationMargin = appTheme.pixelPerfect(10);
            widthForBlocks = (widthOfTheGame - appTheme.pixelPerfect(100) - (numberOfBlocks - 1) * separationMargin) / numberOfBlocks;
            widthForBlocks = widthForBlocks > appTheme.pixelPerfect(200) ? appTheme.pixelPerfect(200) : widthForBlocks;
        }
        const widthForBlockContainer = widthForBlocks + appTheme.pixelPerfect(20);
        const presentX: number = onScreenCard * widthForBlockContainer;
        if (e.nativeEvent.contentOffset.x % e.nativeEvent.layoutMeasurement.width === 0) {
            if (presentX > e.nativeEvent.contentOffset.x) {
                moveCard(false);
            } else if (presentX < e.nativeEvent.contentOffset.x) {
                moveCard(true);
            }
        }
    }

    const moveCard = (forward: boolean) => {
        const { action } = activity;
        let newCard = onScreenCard;
        if (forward === true) {
            newCard = onScreenCard + 1;
        } else {
            newCard = onScreenCard - 1;
        }
        if (action.type === "game") {
            const { blocks } = action;
            //L'espace total à utiliser est la hauteur du tel, moins 16à pixel de marge sur les côté
            //Jusqu'à 3 blocs on affiche tout sur un écran. Au dessus, on aura une scrollview horizontal donc on prend la valeur pour 2
            const numberOfBlocks = blocks.length > 3 ? 2 : blocks.length;
            const separationMargin = appTheme.pixelPerfect(10);
            let widthForBlocks = (widthOfTheGame - appTheme.pixelPerfect(100) - (numberOfBlocks - 1) * separationMargin) / numberOfBlocks;
            widthForBlocks = widthForBlocks > appTheme.pixelPerfect(200) ? appTheme.pixelPerfect(200) : widthForBlocks;
            const widthForBlockContainer = widthForBlocks + appTheme.pixelPerfect(20);
            scrollViewRef.current?.scrollTo({ x: newCard * widthForBlockContainer, y: 0, animated: true });
        } else {
            LayoutAnimation.configureNext({
                duration: 1000,
                create: { type: 'spring', springDamping: 0.8 },
                update: { type: 'spring', springDamping: 0.8 },
                delete: { type: 'spring', springDamping: 0.8 }
            });
        }

        setOnScreenCard(newCard);
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ width: 1, height: 1 }} />
        }

        const { action } = activity;

        if (action.type === "game_img") {
            const imagesBlocks: (number | bundleImage)[] = [images.adv01JeMePose, images.aut02Cavaonrigole, images.aut09Faismoiconfiance, images.biosi01AdaLovelace, images.bio05KeithHaring, images.ded01JeMeSouviens, images.pro01Nefaispasdebetises, images.vqp02GererUnEnfant, images.twk01LesEmotions, images.vir04SixSaucissons]
            const separationMargin = appTheme.pixelPerfect(10);
            //L'espace total à utiliser est la hauteur du tel, moins 16à pixel de marge sur les côté
            //Jusqu'à 3 blocs on affiche tout sur un écran. Au dessus, on aura une scrollview horizontal donc on prend la valeur pour 2
            const numberOfBlocks = imagesBlocks.length > 3 ? 2 : imagesBlocks.length;
            // Image dimensions => 360/270
            let imageHeight: number = appTheme.getFullAppWidth() - appTheme.pixelPerfect(100); // On prend en compte la hauteur du Timer et du
            if (Platform.OS === "web") {
                imageHeight = appTheme.getFullAppHeight() - appTheme.pixelPerfect(100);
                if (imageHeight > appTheme.pixelPerfect(360)) {
                    imageHeight = appTheme.pixelPerfect(360);
                }
            }
            let imageWidth: number = imageHeight * 360 / 270;
            const widthForBlockContainer = imageWidth + appTheme.pixelPerfect(20);
            let blocksView: React.JSX.Element[] = [];
            for (const aBlockIndex in imagesBlocks) {
                if (Object.prototype.hasOwnProperty.call(imagesBlocks, aBlockIndex)) {
                    let cardRotation: string = "0deg";
                    let cardLeftPosition: number = 0;
                    const cardRotations: string[] = ["0deg", "-7deg", "9deg", "-2deg", "6deg", "-8deg", "4deg", "-4deg", "9deg", "-9deg", "0deg"];
                    cardRotation = cardRotations[parseInt(aBlockIndex)];
                    if (parseInt(aBlockIndex) - 1 !== onScreenCard) {
                        if (parseInt(aBlockIndex) - 1 > onScreenCard) {
                            cardLeftPosition = Platform.OS === "web" ? appTheme.getFullScreenWidth() : appTheme.getFullScreenHeight();
                        }
                    }
                    const anImageSource: number | bundleImage = imagesBlocks[aBlockIndex];
                    const aNewBlockView = <View style={{ transform: [{ rotate: cardRotation }], left: cardLeftPosition, position: "absolute", paddingBottom: appTheme.pixelPerfect(5), width: widthForBlockContainer, justifyContent: 'center', alignItems: 'center' }}>
                        <TouchableOpacity activeOpacity={1} onPress={launchEasterEgg} style={[{ height: imageHeight, width: imageWidth }]}>
                            <Image source={anImageSource} style={{ width: imageWidth, height: imageHeight, borderRadius: appTheme.pixelPerfect(20), borderWidth: appTheme.pixelPerfect(4), borderColor: appTheme.white }} resizeMode="cover" />
                        </TouchableOpacity>
                    </View>;
                    blocksView.push(aNewBlockView);
                }
            }
            const arrowButtonSize = appTheme.pixelPerfect(40);
            const leftNavigationButtons = onScreenCard > -1 ? <TouchableOpacity onPress={() => moveCard(false)} activeOpacity={1} style={{ justifyContent: 'center', alignItems: 'center', width: arrowButtonSize, height: arrowButtonSize, borderRadius: arrowButtonSize / 2, backgroundColor: appTheme.white }}>
                <SVGView Component={IcChevronLeft} size={appTheme.pixelPerfect(20)} color={color} />
            </TouchableOpacity> : <View style={{ width: arrowButtonSize }} />;
            const rightNavigationButtons = onScreenCard < (imagesBlocks.length - 2) ? <TouchableOpacity onPress={() => moveCard(true)} activeOpacity={1} style={{ justifyContent: 'center', alignItems: 'center', width: arrowButtonSize, height: arrowButtonSize, borderRadius: arrowButtonSize / 2, backgroundColor: appTheme.white }}>
                <SVGView Component={IcChevronRight} size={appTheme.pixelPerfect(20)} color={color} />
            </TouchableOpacity> : <View style={{ width: arrowButtonSize }} />;
            return <View style={{ width: widthOfTheGame, flex: 1, justifyContent: "space-evenly", alignItems: 'center', flexDirection: "row" }}>
                {leftNavigationButtons}
                <View style={{ width: widthForBlockContainer }}>
                    <View style={[mainStyle.shadowed, { width: imageWidth, height: imageHeight }]}>
                        {blocksView}
                    </View>
                </View>

                {rightNavigationButtons}
            </View>;
        } else {
            const { blocks } = action;

            let blocksContainer = [];
            if (blocks !== undefined) {
                let blocksView = [];
                //On va calculer la largeur des blocs 
                //On va prendre un espace entre les blocs de 30
                const separationMargin = appTheme.pixelPerfect(10);
                //L'espace total à utiliser est la hauteur du tel, moins 16à pixel de marge sur les côté
                //Jusqu'à 3 blocs on affiche tout sur un écran. Au dessus, on aura une scrollview horizontal donc on prend la valeur pour 2
                const numberOfBlocks = blocks.length > 3 ? 2 : blocks.length;
                let widthForBlocks = (widthOfTheGame - appTheme.pixelPerfect(100) - (numberOfBlocks - 1) * separationMargin) / numberOfBlocks;
                widthForBlocks = widthForBlocks > appTheme.pixelPerfect(200) ? appTheme.pixelPerfect(200) : widthForBlocks;
                const widthForBlockContainer = widthForBlocks + appTheme.pixelPerfect(20);
                const horizontalMargin = blocks.length > 3 ? 0 : separationMargin / 2;
                for (const aBlockIndex in blocks) {
                    if (Object.prototype.hasOwnProperty.call(blocks, aBlockIndex)) {
                        const block = blocks[aBlockIndex];
                        let aBlockContent = blockLabels[block.key] !== undefined ? <Text style={{ textAlign: 'center', fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.darkBlue, paddingHorizontal: appTheme.pixelPerfect(5) }}>{blockLabels[block.key].l.toLocaleUpperCase()}</Text> : <ActivityIndicator color={appTheme.talk} />;
                        if (onScreenCard < parseInt(aBlockIndex)) {
                            aBlockContent = <ActivityIndicator color={appTheme.talk} />;
                        }
                        const aNewBlockView = <View style={{ width: widthForBlockContainer, justifyContent: 'center', alignItems: 'center', marginVertical: appTheme.pixelPerfect(20), marginHorizontal: horizontalMargin }}>
                            <TouchableOpacity activeOpacity={1} onPress={launchEasterEgg} style={[mainStyle.shadowed, { backgroundColor: appTheme.white, width: widthForBlocks }]}>
                                <View style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: appTheme.darkBlue, width: widthForBlocks, height: appTheme.pixelPerfect(44) }}>
                                    <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(12), color }}>{block.title[getLanguage()].toLocaleUpperCase()}</Text>
                                </View>
                                <View style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: appTheme.white, width: widthForBlocks, height: appTheme.pixelPerfect(76) }}>
                                    {aBlockContent}
                                </View>
                            </TouchableOpacity>
                        </View>;
                        blocksView.push(aNewBlockView);
                    }
                }
                if (blocks.length > 3) {
                    const arrowButtonSize = appTheme.pixelPerfect(40);
                    const leftNavigationButtons = onScreenCard > 0 ? <TouchableOpacity onPress={() => moveCard(false)} activeOpacity={1} style={{ justifyContent: 'center', alignItems: 'center', width: arrowButtonSize, height: arrowButtonSize, borderRadius: arrowButtonSize / 2, backgroundColor: appTheme.white }}>
                        <SVGView Component={IcChevronLeft} size={appTheme.pixelPerfect(20)} color={color} />
                    </TouchableOpacity> : <View style={{ width: arrowButtonSize }} />;
                    const rightNavigationButtons = onScreenCard < (blocks.length - 1) ? <TouchableOpacity onPress={() => moveCard(true)} activeOpacity={1} style={{ justifyContent: 'center', alignItems: 'center', width: arrowButtonSize, height: arrowButtonSize, borderRadius: arrowButtonSize / 2, backgroundColor: appTheme.white }}>
                        <SVGView Component={IcChevronRight} size={appTheme.pixelPerfect(20)} color={color} />
                    </TouchableOpacity> : <View style={{ width: arrowButtonSize }} />;
                    blocksContainer = <View style={{ width: widthOfTheGame, justifyContent: "space-evenly", alignItems: 'center', flexDirection: "row" }}>
                        {leftNavigationButtons}
                        <View style={{ width: widthForBlockContainer }}>
                            <ScrollView
                                style={{ width: widthForBlockContainer }}
                                ref={scrollViewRef}
                                onScroll={onBlockScroll}
                                pagingEnabled={true}
                                showsHorizontalScrollIndicator={false}
                                horizontal={true}>
                                <View style={{ flexDirection: 'row' }}>
                                    {blocksView}
                                </View>
                            </ScrollView>
                        </View>

                        {rightNavigationButtons}
                    </View>;
                } else {
                    blocksContainer = <View style={{ flexDirection: 'row' }}>{blocksView}</View>;
                }
            }

            return blocksContainer;
        }
    }

    return getContent();
}

export default GameCards;