import React, { useEffect, useState } from "react";

import {
    Image,
    Modal,
    TouchableOpacity,
    View,
    Text
} from "react-native";
import User from "../../../shared/data/user/User";

import AppTheme from "../../../shared/utils/Theme";
import { getCurrentSession } from "../../services/AppWrite/AppWriteDatabase";
import AppMenu from "../../../shared/components/Homepage/AppMenu";
import Style from "../../../shared/utils/Style";
import Images from "../../utils/Images";
import i18n from "../../../shared/utils/Localization/Localization";
import AvatarView from "../../../shared/components/Gamification/AvatarView";
import { useNavigate } from "react-router-dom";
import { env } from "../../services/Specific_Database";
//import { launchUserListener } from "../../services/Specific_Auth";

const KEYS = {
    info: "info",
    profile: "profile",
    path: "path",
    still: "still",
    grow: "grow",
    talk: "talk",
    liliAtSchool: "liliAtSchool",
    superPower: "superPower",
    liliAtHome: "liliAtHome",
    trainingPath: "trainingPath",
    sleepingMeditation: "sleepingMeditation",
    coachingManual: "coachingManual"
}

export let user_uid = "";

const InvisibleHeader = () => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const user: User = User.getInstance();

    const [viewDidAppear, setViewDidAppear] = useState(false);

    const onViewDidAppear = () => {
        setViewDidAppear(true);
    }

    const launchSessionListener = async () => {
        const currentSessiion = await getCurrentSession();
        setTimeout(() => {
            if (currentSessiion === null) {
                user.deleteUser();
            } else {
                launchSessionListener();
            }
        }, 30000);
    }

    useEffect(() => {
        if (viewDidAppear === true) {
            // Si on est sur l'app TNE, on va lancer un "listener" de session. Sinon des données utilisateurs
            const host = window.location.hostname;
            if ((host === "app-tne.lili.cool") || (host === "app-tne-pp.lili.cool")) {
                launchSessionListener();
            }
        }
    }, [viewDidAppear]);

    const getHeader = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{width:1, height:1}} />;
        }
        if (env === "DEV") {
            return <View style={{width:"100%", height:appTheme.pixelPerfect(20), backgroundColor:"#800000", justifyContent:"center", alignItems:"center"}}>
                <Text style={{color:"#FFFFFF", fontWeight:"bold"}}>ENVIRONNEMENT DE DÉVELOPPEMENT</Text>
            </View>
        }
        return <View />;
    }

    return (
        <View style={{ zIndex: 100 }}>
            {getHeader()}
        </View>
    )
};

export default InvisibleHeader;