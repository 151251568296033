import React, { useState, useEffect } from "react";
import { View, Text, TextStyle, TouchableOpacity, Animated, Image } from "react-native";
import AppTheme from "../../utils/Theme";

import Select from "react-select";
import Calendar from 'react-calendar';
//import 'react-calendar/dist/Calendar.css'; // Assurez-vous d'importer le CSS pour styliser le calendrier


import Button from "../../designSystem/Button";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import Divider from "../../designSystem/Divider/Divider";
import TextInput from "../../designSystem/TextInput/RoundCornerTextInput";
import RadioButton from "../../designSystem/RadioButton";

import { addDBNews, setDBNews } from "../../../specific/services/Specific_Database";

import { getSessionsNews } from "../../../specific/utils/LocalStorage";
import News from "../../models/News"
import { ID } from "appwrite";
import { formatDate } from "react-calendar/dist/cjs/shared/dateFormatter";


const languagesByKey = {
  "fr": "Français",
  "en": "Anglais",
  "es": "Espagnol",
  "it": "Italien",
  "pt": "Portugais",
  "ar": "Arabe",
  "de": "Allemand",
  "zh": "Chinois",
  "hi": "Hindi",
  "bn": "Bengali",
  "ru": "Russe",
  "ja": "Japonais",
  "ko": "Coréen",
  "vi": "Vietnamien",
  "ur": "Ourdou",
  "mr": "Marathi",
  "ta": "Tamoul",
  "tr": "Turc",
  "te": "Télougou",
  "fa": "Persan",
  "pa": "Pendjabi",
  "id": "Indonésien",
  "pl": "Polonais",
  "uk": "Ukrainien",
  "ro": "Roumain",
  "nl": "Néerlandais",
  "th": "Thaï",
  "ms": "Malais",
  "my": "Birman",
  "hu": "Hongrois",
  "el": "Grec",
  "cs": "Tchèque",
  "sv": "Suédois",
  "bg": "Bulgare",
  "ab": "Abkhaze",
  "aa": "Afar",
  "af": "Afrikaans",
  "ak": "Akan",
  "sq": "Albanais",
  "am": "Amharique",
  "an": "Aragonais",
  "hy": "Arménien",
  "as": "Assamais",
  "av": "Avar",
  "ae": "Avestique",
  "ay": "Aymara",
  "az": "Azéri",
  "bm": "Bambara",
  "ba": "Bachkir",
  "eu": "Basque",
  "be": "Biélorusse",
  "bh": "Bihari",
  "bi": "Bichelamar",
  "bs": "Bosnien",
  "br": "Breton",
  "ca": "Catalan",
  "ch": "Chamorro",
  "ce": "Tchétchène",
  "ny": "Chichewa",
  "cu": "Slavon d'église",
  "cv": "Tchouvache",
  "kw": "Cornique",
  "co": "Corse",
  "cr": "Cree",
  "hr": "Croate",
  "da": "Danois",
  "dv": "Divehi",
  "dz": "Dzongkha",
  "eo": "Espéranto",
  "et": "Estonien",
  "ee": "Ewe",
  "fo": "Féroïen",
  "fj": "Fidjien",
  "fi": "Finnois",
  "ff": "Peul",
  "gl": "Galicien",
  "ka": "Géorgien",
  "gn": "Guarani",
  "gu": "Gujarati",
  "ht": "Haïtien",
  "ha": "Haoussa",
  "he": "Hébreu",
  "hz": "Herero",
  "ho": "Hiri Motu",
  "ia": "Interlingua",
  "ie": "Interlingue",
  "ga": "Irlandais",
  "ig": "Igbo",
  "ik": "Inupiaq",
  "io": "Ido",
  "is": "Islandais",
  "iu": "Inuktitut",
  "jv": "Javanais",
  "kl": "Groenlandais",
  "kn": "Kannada",
  "kr": "Kanouri",
  "ks": "Kashmiri",
  "kk": "Kazakh",
  "km": "Khmer",
  "ki": "Kikuyu",
  "rw": "Kinyarwanda",
  "ky": "Kirghiz",
  "kv": "Komi",
  "kg": "Kongo",
  "kj": "Kwanyama",
  "ku": "Kurde",
  "lo": "Laotien",
  "la": "Latin",
  "lv": "Letton",
  "li": "Limbourgeois",
  "ln": "Lingala",
  "lt": "Lituanien",
  "lu": "Luba-Katanga",
  "lg": "Ganda",
  "lb": "Luxembourgeois",
  "mk": "Macédonien",
  "mg": "Malgache",
  "ml": "Malayâlam",
  "mt": "Maltais",
  "gv": "Mannois",
  "mi": "Maori",
  "mh": "Marshallais",
  "mn": "Mongol",
  "na": "Nauruan",
  "nv": "Navajo",
  "nd": "Ndébéle du Nord",
  "nr": "Ndébéle du Sud",
  "ng": "Ndonga",
  "ne": "Népalais",
  "no": "Norvégien",
  "ii": "Yi du Sichuan",
  "oc": "Occitan",
  "oj": "Ojibwé",
  "or": "Oriya",
  "om": "Oromo",
  "os": "Ossète",
  "pi": "Pâli",
  "ps": "Pachto",
  "qu": "Quechua",
  "rm": "Romanche",
  "rn": "Kirundi",
  "sa": "Sanskrit",
  "sc": "Sarde",
  "sd": "Sindhi",
  "se": "Sami du Nord",
  "sm": "Samoan",
  "sg": "Sango",
  "sr": "Serbe",
  "gd": "Gaélique écossais",
  "sn": "Shona",
  "si": "Singhalais",
  "sk": "Slovaque",
  "sl": "Slovène",
  "so": "Somali",
  "st": "Sotho du Sud",
  "su": "Soundanais",
  "sw": "Swahili",
  "ss": "Swati",
  "tl": "Tagalog",
  "tn": "Tswana",
  "to": "Tongien",
  "tk": "Turkmène",
  "ts": "Tsonga",
  "tt": "Tatar",
  "tw": "Twi",
  "ty": "Tahitien",
  "ug": "Ouïghour",
  "vo": "Volapük",
  "wa": "Wallon",
  "cy": "Gallois",
  "wo": "Wolof",
  "xh": "Xhosa",
  "yi": "Yiddish",
  "yo": "Yoruba",
  "za": "Zhuang",
  "zu": "Zoulou"
};

interface Props {
  goBack?: () => void
};


const LHQNewsManagement = ({ goBack }: Props) => {

  const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

  // NEWS
  const [newsFromDB, setNewsFromDB] = useState<any>(undefined);
  const [news, setNews] = useState<News | undefined>(undefined)
  const [selectedNews, setSelectedNews] = useState<{ value: string, label: string } | undefined>(undefined)
  const [newNews, setNewNews] = useState<boolean>(false)

  // TITLE
  const [selectedTitleLanguage, setSelectedTitleLanguage] = useState<LanguageOption | null>(null);

  // BODY
  const [selectedBodyLanguage, setSelectedBodyLanguage] = useState<LanguageOption | null>(null);

  // URL
  const [selectedUrlLanguage, setSelectedUrlLanguage] = useState<LanguageOption | null>(null);

  //STATUS
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [newsStatusChanged, setNewsStatusChanged] = useState<boolean>(false);

  //IMAGE
  const [newImage, setNewImage] = useState<any>(undefined);
  const [imageUrl, setImageUrl] = useState<string>("")

  //KEY
  const [newsKey, setNewsKey] = useState<string>("");

  //STARTDATE
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  // MESSAGE DE RETOUR D'ENREGISTREMENT OU DE RÉACTUSALISATION
  const [message, setMessage] = useState<string>("")

  const appTheme: AppTheme = new AppTheme();

  const h1Style: TextStyle = {
    fontFamily: appTheme.primaryBoldFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(18)
  };

  const h2Style: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(16)
  };

  const h3Style: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(14)
  };

  const labelStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(10)
  };

  const mediaTitleStyle: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(10)
  };

  const attributeTitleStyle: TextStyle = {
    fontFamily: appTheme.primaryBoldFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(12),
    textDecorationLine: "underline"
  };

  const inputStyle: TextStyle = {
    height: appTheme.pixelPerfect(40),
    borderRadius: appTheme.pixelPerfect(10),
    borderWidth: 1,
    borderColor: appTheme.grow,
    padding: appTheme.pixelPerfect(4),
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(6)
  };

  const pStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.textColor,
    fontSize: appTheme.pixelPerfectForFont(14)
  };

  const aStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.textColor,
    fontSize: appTheme.pixelPerfectForFont(14),
    textDecorationLine: "underline"
  };

  const onViewWillAppear = () => {
    getNewsFromDB();
  };

  const getNewsFromDB = async () => {
    const sessionNews = await getSessionsNews()
    setNewsFromDB(sessionNews)
  };

  useEffect(() => {
    if (newsFromDB !== undefined) {
      setViewDidAppear(true)
    }
  }, [newsFromDB]);

  //SELECTED NEWS ==> SETNEWS
  useEffect(() => {
    setNewNews(false)
    if (selectedNews !== undefined) {
      if (newsFromDB.data[selectedNews.value] !== undefined) {
        setNews(newsFromDB.data[selectedNews.value])
      }
    }
  }, [selectedNews]);

  useEffect(() => {
    if (newNews === true) {
      let newsToAdd: News = {
        title: {
          fr: ""
        },
        body: {
          fr: ""
        },
        img: "",
        url: {
          fr: ""
        },
        target: [],
        status: "draft",
        from: null,
        to: null,
        last_update: new Date().getTime()
      };
      setNews(newsToAdd);
      setSelectedNews(undefined)
    }
  }, [newNews]);

  //STATUS
  useEffect(() => {
    if (news !== undefined) {
      let updatedNews: News = { ...news };
      if (isPublished === true) {
        updatedNews.status = "published"
      } else {
        updatedNews.status = "draft"
      }
      setNews(updatedNews)
    }
  }, [newsStatusChanged])



  //IMAGE
  // useEffect(() => {
  //   let updatedNews: News = { ...news };
  //   if (newImage !== undefined) {
  //     const image_url: string = await updloadNewsImage({ imageToUpload: newImage, newsKey: news.key })
  //     console.log({ image_url })
  //     updatedNews.img = image_url:
  //   };
  //   setNewImage(undefined)
  // })


  type LanguageOption = {
    value: string;
    label: string
  };

  const languageOptions: LanguageOption[] = [
    { "value": "fr", "label": "Français" },
    { "value": "en", "label": "Anglais" },
    { "value": "de", "label": "Allemand" },
    { "value": "es", "label": "Espagnol" },
    { "value": "it", "label": "Italien" },
    { "value": "pt", "label": "Portugais" },
    { "value": "ar", "label": "Arabe" },
    { "value": "zh", "label": "Chinois" },
    { "value": "hi", "label": "Hindi" },
    { "value": "bn", "label": "Bengali" },
    { "value": "ru", "label": "Russe" },
    { "value": "ja", "label": "Japonais" },
    { "value": "ko", "label": "Coréen" },
    { "value": "vi", "label": "Vietnamien" },
    { "value": "ur", "label": "Ourdou" },
    { "value": "mr", "label": "Marathi" },
    { "value": "ta", "label": "Tamoul" },
    { "value": "tr", "label": "Turc" },
    { "value": "te", "label": "Télougou" },
    { "value": "fa", "label": "Persan" },
    { "value": "pa", "label": "Pendjabi" },
    { "value": "id", "label": "Indonésien" },
    { "value": "pl", "label": "Polonais" },
    { "value": "uk", "label": "Ukrainien" },
    { "value": "ro", "label": "Roumain" },
    { "value": "nl", "label": "Néerlandais" },
    { "value": "th", "label": "Thaï" },
    { "value": "ms", "label": "Malais" },
    { "value": "my", "label": "Birman" },
    { "value": "hu", "label": "Hongrois" },
    { "value": "el", "label": "Grec" },
    { "value": "cs", "label": "Tchèque" },
    { "value": "sv", "label": "Suédois" },
    { "value": "bg", "label": "Bulgare" },
    { "value": "ab", "label": "Abkhaze" },
    { "value": "aa", "label": "Afar" },
    { "value": "af", "label": "Afrikaans" },
    { "value": "ak", "label": "Akan" },
    { "value": "sq", "label": "Albanais" },
    { "value": "am", "label": "Amharique" },
    { "value": "an", "label": "Aragonais" },
    { "value": "hy", "label": "Arménien" },
    { "value": "as", "label": "Assamais" },
    { "value": "av", "label": "Avar" },
    { "value": "ae", "label": "Avestique" },
    { "value": "ay", "label": "Aymara" },
    { "value": "az", "label": "Azéri" },
    { "value": "bm", "label": "Bambara" },
    { "value": "ba", "label": "Bachkir" },
    { "value": "eu", "label": "Basque" },
    { "value": "be", "label": "Biélorusse" },
    { "value": "bh", "label": "Bihari" },
    { "value": "bi", "label": "Bichelamar" },
    { "value": "bs", "label": "Bosnien" },
    { "value": "br", "label": "Breton" },
    { "value": "ca", "label": "Catalan" },
    { "value": "ch", "label": "Chamorro" },
    { "value": "ce", "label": "Tchétchène" },
    { "value": "ny", "label": "Chichewa" },
    { "value": "cu", "label": "Slavon d'église" },
    { "value": "cv", "label": "Tchouvache" },
    { "value": "kw", "label": "Cornique" },
    { "value": "co", "label": "Corse" },
    { "value": "cr", "label": "Cree" },
    { "value": "hr", "label": "Croate" },
    { "value": "da", "label": "Danois" },
    { "value": "dv", "label": "Divehi" },
    { "value": "dz", "label": "Dzongkha" },
    { "value": "eo", "label": "Espéranto" },
    { "value": "et", "label": "Estonien" },
    { "value": "ee", "label": "Ewe" },
    { "value": "fo", "label": "Féroïen" },
    { "value": "fj", "label": "Fidjien" },
    { "value": "fi", "label": "Finnois" },
    { "value": "ff", "label": "Peul" },
    { "value": "gl", "label": "Galicien" },
    { "value": "ka", "label": "Géorgien" },
    { "value": "gn", "label": "Guarani" },
    { "value": "gu", "label": "Gujarati" },
    { "value": "ht", "label": "Haïtien" },
    { "value": "ha", "label": "Haoussa" },
    { "value": "he", "label": "Hébreu" },
    { "value": "hz", "label": "Herero" },
    { "value": "ho", "label": "Hiri Motu" },
    { "value": "ia", "label": "Interlingua" },
    { "value": "ie", "label": "Interlingue" },
    { "value": "ga", "label": "Irlandais" },
    { "value": "ig", "label": "Igbo" },
    { "value": "ik", "label": "Inupiaq" },
    { "value": "io", "label": "Ido" },
    { "value": "is", "label": "Islandais" },
    { "value": "iu", "label": "Inuktitut" },
    { "value": "jv", "label": "Javanais" },
    { "value": "kl", "label": "Groenlandais" },
    { "value": "kn", "label": "Kannada" },
    { "value": "kr", "label": "Kanouri" },
    { "value": "ks", "label": "Kashmiri" },
    { "value": "kk", "label": "Kazakh" },
    { "value": "km", "label": "Khmer" },
    { "value": "ki", "label": "Kikuyu" },
    { "value": "rw", "label": "Kinyarwanda" },
    { "value": "ky", "label": "Kirghiz" },
    { "value": "kv", "label": "Komi" },
    { "value": "kg", "label": "Kongo" },
    { "value": "kj", "label": "Kwanyama" },
    { "value": "ku", "label": "Kurde" },
    { "value": "lo", "label": "Laotien" },
    { "value": "la", "label": "Latin" },
    { "value": "lv", "label": "Letton" },
    { "value": "li", "label": "Limbourgeois" },
    { "value": "ln", "label": "Lingala" },
    { "value": "lt", "label": "Lituanien" },
    { "value": "lu", "label": "Luba-Katanga" },
    { "value": "lg", "label": "Ganda" },
    { "value": "lb", "label": "Luxembourgeois" },
    { "value": "mk", "label": "Macédonien" },
    { "value": "mg", "label": "Malgache" },
    { "value": "ml", "label": "Malayâlam" },
    { "value": "mt", "label": "Maltais" },
    { "value": "gv", "label": "Mannois" },
    { "value": "mi", "label": "Maori" },
    { "value": "mh", "label": "Marshallais" },
    { "value": "mn", "label": "Mongol" },
    { "value": "na", "label": "Nauruan" },
    { "value": "nv", "label": "Navajo" },
    { "value": "nd", "label": "Ndébéle du Nord" },
    { "value": "nr", "label": "Ndébéle du Sud" },
    { "value": "ng", "label": "Ndonga" },
    { "value": "ne", "label": "Népalais" },
    { "value": "no", "label": "Norvégien" },
    { "value": "ii", "label": "Yi du Sichuan" },
    { "value": "oc", "label": "Occitan" },
    { "value": "oj", "label": "Ojibwé" },
    { "value": "or", "label": "Oriya" },
    { "value": "om", "label": "Oromo" },
    { "value": "os", "label": "Ossète" },
    { "value": "pi", "label": "Pâli" },
    { "value": "ps", "label": "Pachto" },
    { "value": "qu", "label": "Quechua" },
    { "value": "rm", "label": "Romanche" },
    { "value": "rn", "label": "Kirundi" },
    { "value": "sa", "label": "Sanskrit" },
    { "value": "sc", "label": "Sarde" },
    { "value": "sd", "label": "Sindhi" },
    { "value": "se", "label": "Sami du Nord" },
    { "value": "sm", "label": "Samoan" },
    { "value": "sg", "label": "Sango" },
    { "value": "sr", "label": "Serbe" },
    { "value": "gd", "label": "Gaélique écossais" },
    { "value": "sn", "label": "Shona" },
    { "value": "si", "label": "Singhalais" },
    { "value": "sk", "label": "Slovaque" },
    { "value": "sl", "label": "Slovène" },
    { "value": "so", "label": "Somali" },
    { "value": "st", "label": "Sotho du Sud" },
    { "value": "su", "label": "Soundanais" },
    { "value": "sw", "label": "Swahili" },
    { "value": "ss", "label": "Swati" },
    { "value": "tl", "label": "Tagalog" },
    { "value": "tn", "label": "Tswana" },
    { "value": "to", "label": "Tongien" },
    { "value": "tk", "label": "Turkmène" },
    { "value": "ts", "label": "Tsonga" },
    { "value": "tt", "label": "Tatar" },
    { "value": "tw", "label": "Twi" },
    { "value": "ty", "label": "Tahitien" },
    { "value": "ug", "label": "Ouïghour" },
    { "value": "vo", "label": "Volapük" },
    { "value": "wa", "label": "Wallon" },
    { "value": "cy", "label": "Gallois" },
    { "value": "wo", "label": "Wolof" },
    { "value": "xh", "label": "Xhosa" },
    { "value": "yi", "label": "Yiddish" },
    { "value": "yo", "label": "Yoruba" },
    { "value": "za", "label": "Zhuang" },
    { "value": "zu", "label": "Zoulou" }
  ];

  // ********** NEWS SELECTOR **********
  const selectAExistingNews = (e) => {
    setSelectedNews(e)
  };

  const getNewsSelector = () => {
    let newsSelectOptions: { value: string, label: string }[] = [];
    for (const aNewsKey in newsFromDB.data) {
      if (Object.prototype.hasOwnProperty.call(newsFromDB.data, aNewsKey)) {
        const element = newsFromDB.data[aNewsKey];
        newsSelectOptions.push({
          value: aNewsKey,
          label: element.title.fr
        })
      }
    };

    return (
      <Select
        value={selectedNews}
        onChange={(e) => selectAExistingNews(e)}
        placeholder="Sélectionnez une categorie"
        options={newsSelectOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: appTheme.pixelPerfect(30),
            minHeight: appTheme.pixelPerfect(30),
            borderRadius: appTheme.pixelPerfect(10),
            fontFamily: appTheme.primaryFont,
            borderColor: appTheme.grow,
            fontSize: appTheme.pixelPerfectForFont(6),
            display: "flex",
            alignItems: "center",
            paddingTop: 0,
            paddingBottom: 0,
            flexWrap: "unset",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            fontFamily: appTheme.primaryFont,
            fontSize: appTheme.pixelPerfectForFont(6),
          })
        }}
      />
    )
  };

  // ********** PARTIE POUR LA SAISIE DES TITRES **********
  const onAddNewTitle = () => {
    if (selectedTitleLanguage) {
      if (news.title.length === 0 || news.title[selectedTitleLanguage.value] === undefined) {
        onChangeTitle({ lang: selectedTitleLanguage.value, value: "" });
      }
      setSelectedTitleLanguage(null); // Réinitialise la sélection
    }
  };

  const onChangeTitle = (data: { lang: string, value: string }) => {
    const { lang, value } = data;
    let updatedNews: News = { ...news };
    let updatedTitle = updatedNews.title;
    updatedTitle[lang] = value;
    updatedNews.title = updatedTitle;
    setNews(updatedNews)
  }

  const getNewTitleLanguageSelector = () => {
    return (
      <View style={{ width: "100%", zIndex: 3 }}>
        <View style={{ display: "flex", flexDirection: "row", width: "100%", zIndex: 3, marginBottom: appTheme.pixelPerfect(10) }}>
          <View style={{ width: "20%", zIndex: 4 }} >
            <Select
              value={selectedTitleLanguage}
              onChange={(selectedOption) => setSelectedTitleLanguage(selectedOption)}
              options={languageOptions}
              placeholder="Sélectionner la langue"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: appTheme.pixelPerfect(30),
                  minHeight: appTheme.pixelPerfect(30),
                  borderRadius: appTheme.pixelPerfect(10),
                  fontFamily: appTheme.primaryFont,
                  borderColor: appTheme.grow,
                  fontSize: appTheme.pixelPerfectForFont(6),
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 0,
                  paddingBottom: 0,
                  flexWrap: "unset",
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                  ...styles,
                  fontFamily: appTheme.primaryFont,
                  fontSize: appTheme.pixelPerfectForFont(6),
                })
              }}
            />
          </View>
          <View style={{ width: "20%", marginLeft: appTheme.pixelPerfect(10) }}>
            <Button
              onPress={() => onAddNewTitle()}
              title={"Ajouter une langue"}
              style={{ height: appTheme.pixelPerfect(30) }}
              textStyle={{ fontSize: appTheme.pixelPerfectForFont(6) }}
            />
          </View>
        </View>
      </View>
    )
  };

  const getTitles = () => {
    if (news !== undefined) {
      let titleViews = [];
      for (const aTitleKey in news.title) {
        if (Object.prototype.hasOwnProperty.call(news.title, aTitleKey)) {
          const aTitleTrad: string = news.title[aTitleKey];
          titleViews.push(
            <View key={aTitleKey} style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginBottom: appTheme.pixelPerfect(10) }}>
              <View style={{ width: "20%", alignItems: "flex-start" }}>
                <Text style={[labelStyle, { verticalAlign: "top" }]}>{languagesByKey[aTitleKey] + " : "}</Text>
              </View>
              <View style={{ width: "80%", height: appTheme.pixelPerfect(30) }}>
                <TextInput onChangeText={(e) => onChangeTitle({ lang: aTitleKey, value: e })} value={aTitleTrad} style={[inputStyle, { height: appTheme.pixelPerfect(30), width: "100%" }]} />
              </View>
            </View>
          )
        }
      }
      return titleViews;
    }
  };

  // ********** PARTIE POUR LA SAISIE DES BODIES **********
  const onAddNewBody = () => {
    if (selectedBodyLanguage) {
      if (news.body.length === 0 || news.body[selectedBodyLanguage.value] === undefined) {
        onChangeBody({ lang: selectedBodyLanguage.value, value: "" })
      }
      setSelectedBodyLanguage(null)
    }
  };

  const onChangeBody = (data: { lang: string, value: string }) => {
    const { lang, value } = data;
    let updatedNews: News = { ...news };
    let updatedBody = updatedNews.body;
    updatedBody[lang] = value;
    updatedNews.body = updatedBody
    setNews(updatedNews)
  };

  const getNewBodyLanguageSelector = () => {
    return (
      <View style={{ width: "100%", zIndex: 2 }}>
        <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: appTheme.pixelPerfect(10), zIndex: 2 }}>
          <View style={{ width: "20%" }} >
            <Select
              value={selectedBodyLanguage}
              onChange={(selectedOption) => setSelectedBodyLanguage(selectedOption)}
              options={languageOptions}
              placeholder="Sélectionner la langue"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: appTheme.pixelPerfect(30),
                  minHeight: appTheme.pixelPerfect(30),
                  borderRadius: appTheme.pixelPerfect(10),
                  fontFamily: appTheme.primaryFont,
                  borderColor: appTheme.grow,
                  fontSize: appTheme.pixelPerfectForFont(6),
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 0,
                  paddingBottom: 0,
                  flexWrap: "unset",
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                  ...styles,
                  fontFamily: appTheme.primaryFont,
                  fontSize: appTheme.pixelPerfectForFont(6),
                })
              }}
            />
          </View>
          <View style={{ width: "20%", marginLeft: appTheme.pixelPerfect(10) }}>
            {/* Bouton Ajouter */}
            <Button
              onPress={() => onAddNewBody()}
              title={"Ajouter une langue"}
              style={{ height: appTheme.pixelPerfect(30) }}
              textStyle={{ fontSize: appTheme.pixelPerfectForFont(6) }}
            />
          </View>
        </View>
      </View>
    )
  };

  const getBodies = () => {
    if (news !== undefined) {
      let bodyViews = [];
      for (const aBodyKey in news.body) {
        if (Object.prototype.hasOwnProperty.call(news.body, aBodyKey)) {
          const aBodyTrad: string = news.body[aBodyKey];
          bodyViews.push(
            <View key={aBodyKey} style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: appTheme.pixelPerfect(10), height: appTheme.pixelPerfect(100) }}>
              <View style={{ width: "20%" }}>
                <Text style={labelStyle}>{languagesByKey[aBodyKey] + " : "}</Text>
              </View>
              <View style={{ width: "80%" }}>
                <TextInput
                  value={aBodyTrad}
                  onChangeText={(e) => onChangeBody({ lang: aBodyKey, value: e })}
                  multiline={true}
                  style={[inputStyle, { height: appTheme.pixelPerfect(100), width: "100%" }]} />
              </View>
            </View>
          )
        }
      }
      return bodyViews;
    }
  };

  // ********** PARTIE POUR LA SAISIE DE L'URL**********
  const onAddNewUrl = () => {
    if (selectedUrlLanguage) {
      if (news.url.length === 0 || news.url[selectedUrlLanguage.value] === undefined) {
        onChangeUrl({ lang: selectedUrlLanguage.value, value: "" })
      }
      setSelectedUrlLanguage(null)
    }
  };

  const onChangeUrl = (data: { lang: string, value: string }) => {
    const { lang, value } = data;
    let updatedNews: News = { ...news };
    let updatedUrl = updatedNews.url;
    updatedUrl[lang] = value;
    updatedNews.url = updatedUrl;
    setNews(updatedNews)
  }

  const getNewUrlLanguageSelector = () => {
    return (
      <View style={{ width: "100%", zIndex: 3 }}>
        <View style={{ display: "flex", flexDirection: "row", width: "100%", zIndex: 3, marginBottom: appTheme.pixelPerfect(10) }}>
          <View style={{ width: "20%", zIndex: 4 }} >
            <Select
              value={selectedUrlLanguage}
              onChange={(selectedOption) => setSelectedUrlLanguage(selectedOption)}
              options={languageOptions}
              placeholder="Sélectionner la langue"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: appTheme.pixelPerfect(30),
                  minHeight: appTheme.pixelPerfect(30),
                  borderRadius: appTheme.pixelPerfect(10),
                  fontFamily: appTheme.primaryFont,
                  borderColor: appTheme.grow,
                  fontSize: appTheme.pixelPerfectForFont(6),
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 0,
                  paddingBottom: 0,
                  flexWrap: "unset",
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                  ...styles,
                  fontFamily: appTheme.primaryFont,
                  fontSize: appTheme.pixelPerfectForFont(6),
                })
              }}
            />
          </View>
          <View style={{ width: "20%", marginLeft: appTheme.pixelPerfect(10) }}>
            <Button
              onPress={() => onAddNewUrl()}
              title={"Ajouter une langue"}
              style={{ height: appTheme.pixelPerfect(30) }}
              textStyle={{ fontSize: appTheme.pixelPerfectForFont(6) }}
            />
          </View>
        </View>
      </View>
    )
  };

  const getUrls = () => {
    if (news !== undefined) {
      let urlViews = [];
      for (const aUrlKey in news.url) {
        if (Object.prototype.hasOwnProperty.call(news.url, aUrlKey)) {
          const aUrlTrad: string = news.url[aUrlKey];
          urlViews.push(
            <View key={aUrlKey} style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginBottom: appTheme.pixelPerfect(10) }}>
              <View style={{ width: "20%", alignItems: "flex-start" }}>
                <Text style={[labelStyle, { verticalAlign: "top" }]}>{languagesByKey[aUrlKey] + " : "}</Text>
              </View>
              <View style={{ width: "80%", height: appTheme.pixelPerfect(30) }}>
                <TextInput onChangeText={(e) => onChangeUrl({ lang: aUrlKey, value: e })} value={aUrlTrad} style={[inputStyle, { height: appTheme.pixelPerfect(30), width: "100%" }]} />
              </View>
            </View>
          )
        }
      }
      return urlViews;
    };
  };

  // ********** PARTIE POUR L'INPUT IMAGE **********
  // Option 1: un text input pour coller une url
  const onChangeImageUrl = (data: { value: string }) => {
    const { value } = data;
    let updatedNews: News = { ...news };
    updatedNews.img = value;
    setNews(updatedNews);
  };


  const getImageUrl = () => {
    return (
      <View style={{ width: "100%" }}>
        <TextInput
          value={news.img}
          // value={news.img !== "" ? news.img : imageUrl}
          onChangeText={(e) => onChangeImageUrl({ value: e })}
          style={[inputStyle, { width: "100%", height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(10), fontFamily: appTheme.primaryFont }]}
          editable={true}
          placeholder="Saisissez ou coller l'url de l'image"
        />
      </View>
    )
  };
  //Option 2: un upload d'image (input type file) avec un enregistrement dans Firebase puis enregistrement de l'url en provenance de Firebase
  const handleImageChange = (e) => {
    setNewImage(e.target.files[0]);
  };

  const getImage = () => {
    let imageView = <Text style={labelStyle}>Aucune image</Text>;
    if ((news.img !== undefined) && (news.img.length > 0)) {
      imageView = <Image source={{ uri: news.img }} style={{ width: appTheme.pixelPerfect(180), height: appTheme.pixelPerfect(135) }} resizeMode="contain" />;
    };
    let newImageView = <View />;
    let separatorView = <View />;
    if (newImage !== undefined) {
      const imageSource: string = URL.createObjectURL(newImage);
      newImageView = <Image source={{ uri: imageSource }} style={{ width: appTheme.pixelPerfect(180), height: appTheme.pixelPerfect(135) }} resizeMode="contain" />;
      separatorView = <View style={{ width: appTheme.pixelPerfect(40), alignItems: "center", justifyContent: "center", paddingHorizontal: appTheme.pixelPerfect(20) }}>
        <Text style={{ fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue }}>{" >"}</Text>
      </View>
    };
    return (
      <View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          {imageView}
          {separatorView}
          {newImageView}
        </View>
        <input type="file" onChange={handleImageChange} />
      </View>

    )
  };

  //********** PARTIE POUR LA SÉLECTION DES TARGETS **********
  const updateTargets = (aTarget: string) => {
    let updatedNews: News = { ...news };
    let targetArray = updatedNews.target;
    if (targetArray.indexOf(aTarget) !== -1) {
      targetArray.splice(targetArray.indexOf(aTarget), 1);
    } else {
      targetArray.push(aTarget)
    };
    updatedNews.target = targetArray;
    setNews(updatedNews)
  };

  const getTargets = () => {

    let targetsSelection = [];

    const targetsArray: any[] = [
      {
        value: "teacher",
        label: "Professeur"
      },
      {
        value: "family",
        label: "Famille"
      },
      {
        value: "animator",
        label: "Animateur"
      }
    ];
    for (const aTargetIndex in targetsArray) {
      if (news.target !== undefined) {
        if (Object.prototype.hasOwnProperty.call(targetsArray, aTargetIndex)) {
          const aTarget = targetsArray[aTargetIndex];
          let isSelected: boolean = news.target.indexOf(aTarget.value) !== -1;
          targetsSelection.push(
            <View key={aTargetIndex} style={{ width: appTheme.pixelPerfect(250), marginBottom: appTheme.pixelPerfect(5) }}>
              <RadioButton value={isSelected} style={{ width: appTheme.pixelPerfect(200) }} centerButton={true} onPress={() => updateTargets(aTarget.value)}>
                <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(9) }}>{aTarget.label}</Text>
              </RadioButton>
            </View>
          )
        }
      }
    }
    return targetsSelection;
  };


  // ********** BOUTON SWITCH POUR LE STATUS **********

  useEffect(() => {
    if (news !== undefined) {
      if (news.status === "published") {
        setIsPublished(true)
      } else {
        setIsPublished(false)
      }
    }
  })
  const [animatedValue] = useState(new Animated.Value(0));
  const toggleSwitch = () => {
    setIsPublished(!isPublished);
    setNewsStatusChanged(!newsStatusChanged)

    // Animation du bouton
    Animated.timing(animatedValue, {
      toValue: isPublished ? 0 : 1,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  const getSwitchButtonForStatus = () => {
    const thumbPosition = animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [2, 28], // Les positions de départ et d'arrivée du bouton
    });

    return (
      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: '#fff', marginBottom: appTheme.pixelPerfect(10) }}>
        <TouchableOpacity onPress={toggleSwitch}>
          <View style={{
            // Style pour le track
            width: appTheme.pixelPerfect(50),
            height: appTheme.pixelPerfect(25),
            borderRadius: appTheme.pixelPerfect(25),
            backgroundColor: isPublished === true ? '#117864' : '#d0d3d4',
            padding: 2,
            justifyContent: 'center',
          }}>
            <Animated.View
              style={{
                // Style pour le bouton rond (thumb)
                width: appTheme.pixelPerfect(20),
                height: appTheme.pixelPerfect(20),
                borderRadius: appTheme.pixelPerfect(10),
                backgroundColor: "#f4f3f4",
                transform: [{ translateX: thumbPosition }]
              }}
            />
          </View>
        </TouchableOpacity>
        <Text style={{ paddingHorizontal: appTheme.pixelPerfect(20) }}>{isPublished === true ? "Published" : "Draft"}</Text>
      </View>
    )
  };

  //********** PARTIE POUR LA SÉLECTION DES DATES DE DIFFUSION **********

  // Transformation des dates millisecondes en JJ / MM / AAAA
  const formatDate = (milliseconds) => {
    const date = new Date(milliseconds);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day} / ${month} / ${year}`;
  };

  useEffect(() => {
    if (news?.from) {
      setStartDate(formatDate(news.from));
    }
    if (news?.to) {
      setEndDate(formatDate(news.to));
    }
  }, [news]);

  //Créer ou updater les dates en finction des saisie: si la saisie est complète, on enregistre en millisecondes sinon en enregistre null
  useEffect(() => {
    if (news !== undefined) {
      if (startDate.length === 14) {
        const startDateMilliseconds = parseDate(startDate);

        if (!isNaN(startDateMilliseconds)) {
          let updatedNews: News = { ...news };
          updatedNews.from = startDateMilliseconds
          setNews(updatedNews)
        }
      } else {
        let updatedNews: News = { ...news };
        updatedNews.from = null
        setNews(updatedNews)
      }
    }
  }, [startDate]);

  useEffect(() => {
    if (news !== undefined) {
      if (endDate.length === 14) {
        const endDateMilliseconds = parseDate(endDate);

        if (!isNaN(endDateMilliseconds)) {
          let updatedNews: News = { ...news };
          updatedNews.to = endDateMilliseconds
          setNews(updatedNews)
        }
      } else {
        let updatedNews: News = { ...news };
        updatedNews.to = null
        setNews(updatedNews)
      }
    }

  }, [endDate]);

  // Transformation de la date saisie sous format JJ/MM/AAA 
  const autoFormatDate = (value) => {
    const numericValue = value.replace(/\D/g, ''); // Retire les caractères non numériques
    let formattedDate = '';

    if (numericValue.length <= 2) {
      formattedDate = numericValue;
    } else if (numericValue.length <= 4) {
      formattedDate = `${numericValue.slice(0, 2)} / ${numericValue.slice(2)}`;
    } else {
      formattedDate = `${numericValue.slice(0, 2)} / ${numericValue.slice(2, 4)} / ${numericValue.slice(4, 8)}`;
    }
    return formattedDate;
  };

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split(' / ');
    return new Date(`${year}-${month}-${day}`).getTime()
  };

  const getStartAndEndDate = () => {
    return (
      <View style={{ display: "flex", flexDirection: "row", width: "100%", height: appTheme.pixelPerfect(100) }}>
        <View style={{ width: "40%", marginRight: appTheme.pixelPerfect(10) }}>
          <Text>Date de début d'affichage</Text>
          <TextInput
            style={[inputStyle, { height: appTheme.pixelPerfect(30) }]}
            value={startDate}
            onChangeText={(text) => setStartDate(autoFormatDate(text))}
            placeholder="JJ / MM / AAAA"
            keyboardType="numeric"
            maxLength={14}
          />
        </View>
        <View style={{ width: "40%" }}>
          <Text>Date de fin d'affichage</Text>
          <TextInput
            style={[inputStyle, { height: appTheme.pixelPerfect(30) }]}
            value={endDate}
            onChangeText={(text) => setEndDate(autoFormatDate(text))}
            placeholder="JJ / MM / AAAA"
            keyboardType="numeric"
            maxLength={14}
          />
        </View>

      </View>
    )
  };

  //********** MODAL MESSAGE SI SUCCÈS ENREGISTREMENT OU UPDATE **********
  const closeModal = () => {
    setMessage("");
    goBack()
  }

  const getSaveSuccessMessage = () => {
    if (message !== "") {
      return (
        <View style={{ position: "fixed" as const, width: "80%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 1000 }}>
          <View style={{ backgroundColor: "#fff", padding: appTheme.pixelPerfect(20), borderRadius: appTheme.pixelPerfect(8), boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", maxWidth: appTheme.pixelPerfect(500), textAlign: "center" as const, zIndex: 1001 }}>
            <Text style={[h3Style, { marginVertical: appTheme.pixelPerfect(20) }]}>{message}</Text>
            <Button
              onPress={() => closeModal()}
              title="Fermer"
              style={{ height: appTheme.pixelPerfect(30), marginLeft: appTheme.pixelPerfect(10) }}
              textStyle={{ fontSize: appTheme.pixelPerfectForFont(6) }} />
          </View>
        </View>
      )
    } else {
      return <View />
    }
  };

  // ********** FONCTION DE VALIDATION DU FORMULAIRE OU D'UPDATE D'UNE NEWS EXISTANTE **********
  const dbAddOrUpdateNews = async () => {
    let updatedNews: News = { ...news };
    updatedNews.last_update = new Date().getTime();
    if (newNews === true && selectedNews === undefined) {
      try {
        const responseMessage = await addDBNews({ value: updatedNews })
        setMessage(responseMessage)
      } catch (error) {
        console.error(error.message);
      }
    };

    if (newNews === false && selectedNews.value !== undefined) {
      try {
        const responseMessage = await setDBNews({ key: selectedNews.value, value: updatedNews })
        setMessage(responseMessage)
      } catch (error) {
      }
    };

    //PERMET DE RÉINITIALISER TOUTE LA SELECTION
    setViewDidAppear(false)
  };

  const getNewsForm = () => {
    if (news === undefined) {
      return (<View />)
    };
    return (
      <View style={{ width: "100%", height: 1, backgroundColor: "#909090" }}>
        <Divider />
        <Divider />
        <View style={{ zIndex: 2 }}>
          <Text style={attributeTitleStyle}>Titre</Text>
          <Divider />
          {getNewTitleLanguageSelector()}
        </View>
        {getTitles()}
        <Divider />

        <Divider />
        <View style={{ zIndex: 1 }}>
          <Text style={attributeTitleStyle}>Body</Text>
          <Divider />
          {getNewBodyLanguageSelector()}
        </View>
        <View>
          {getBodies()}
        </View>
        <Divider />

        <Divider />
        <View style={{ zIndex: 1 }}>
          <Text style={attributeTitleStyle}>URL</Text>
          <Divider />
          {getNewUrlLanguageSelector()}
        </View>
        {getUrls()}
        <Divider />

        <Divider />
        <View style={{ width: "100%" }}>
          <Text style={attributeTitleStyle}>URL DE L'IMAGE</Text>
          <Divider />
          {getImageUrl()}
        </View>
        <Divider />

        <Divider />
        <View style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <View style={{ width: "50%" }}>
            <View>
              <Text style={attributeTitleStyle}>Status</Text>
              <Divider />
              {getSwitchButtonForStatus()}
            </View>
            <Divider />
          </View>
          <View style={{ width: "50%" }}>
            <View>
              <Text style={attributeTitleStyle}>Targets</Text>
              <Divider />
              {getTargets()}
            </View>
            <Divider />
          </View>
        </View>
        <Divider />

        <Divider />
        <View>
          <Text style={attributeTitleStyle}>Durée d'affichage</Text>
          <Divider />
          {getStartAndEndDate()}
        </View>
        <Divider />
        {/* <View style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
          <View style={{ width: "30%" }}>
            <View style={{}}>
              <Text style={attributeTitleStyle}>Status</Text>
              <Divider />
              {getSwitchButtonForStatus()}
            </View>
            <Divider />
          </View>
          <View style={{ width: "30%", }}>
            <View>
              <Text style={attributeTitleStyle}>A partir de</Text>
              <Divider />
              {getSwitchButtonForStatus()}
            </View>
            <Divider />
          </View>
          <View style={{ width: "30%" }}>
            <View>
              <Text style={attributeTitleStyle}>Jusqu'à</Text>
              <Divider />
              {getSwitchButtonForStatus()}
            </View>
            <Divider />
          </View>
        </View> */}
        <Divider />

        <Divider />
        <View style={{ width: "100%", display: "flex", alignItems: "center", paddingBottom: appTheme.pixelPerfect(30) }}>
          <Button
            style={{ width: appTheme.pixelPerfect(300) }}
            title={newNews === true ? "Enregistrer" : "Mettre à jour"}
            onPress={dbAddOrUpdateNews}
          />
        </View>
      </View>
    )
  };

  const createNewsButton = () => {
    return (
      <Button
        style={{ width: appTheme.pixelPerfect(300) }}
        title={"Créer une nouvelle News"}
        onPress={() => setNewNews(true)}
      />
    )
  };

  const getContent = () => {
    if (viewDidAppear === false) {
      return <View onLayout={onViewWillAppear} style={{ width: "100%", flex: 1, justifyContent: "center", alignItems: "center", }}>
        <ActivityIndicator color={appTheme.grow} loading={true} />
      </View>
    }
    return (
      <View style={{ width: "100%" }}>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          {createNewsButton()}
        </View>
        <Divider />
        <Text style={labelStyle}>
          Choix des News à venir
        </Text>
        {getNewsSelector()}
        <Divider size={5} />
        <Divider />
        <Divider />
        {getNewsForm()}
      </View>
    )
  };

  return (
    <View style={{ padding: appTheme.pixelPerfect(20) }}>
      {getSaveSuccessMessage()}
      <View style={{ flexDirection: "row", marginBottom: appTheme.pixelPerfect(10) }}>
        <TouchableOpacity onPress={() => goBack()}>
          <Text style={[aStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>Dashboard</Text>
        </TouchableOpacity>
        <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>{">"}</Text>
        <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>News</Text>
      </View>
      {getContent()}

    </View>
  )
};

export default LHQNewsManagement;
