import React, { ComponentProps } from "react";
import { useState, useEffect } from "react";

import {
    Image,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    ScrollView,
    Platform,
    Dimensions
} from 'react-native';

import { EventRegister } from "../../../shared/utils/EventListeners";

import AppTheme from "../../../shared/utils/Theme";
import * as Constants from "../../../shared/utils/Constants";
import MainOptions from "../../../shared/models/MainOptions";

// UI
import Images from "../../utils/Images";
import Style from "../../../shared/utils/Style";

// SVG
import SVGView from "../../../shared/utils/SvgView";
import { default as icBack } from "../../../shared/assets/svg/icons/solid/angle-left.svg";
import { default as icMenu } from "../../../shared/assets/svg/icons/solid/home.svg";
import { default as icDismiss } from "../../../shared/assets/svg/icons/solid/times.svg";

import { isUserATeacher } from "../../../shared/utils/LocalStorage";

import * as Navigation from "../../utils/Navigation/Navigation";


import { useNavigate, useLocation } from "react-router-dom";

// Component
import About from "../../../shared/components/About/About";
import UserProfile from "../../../shared/components/Profile/UserProfile";
import ConsentNotification from "../../../shared/components/Legal/ConcentNotification";
import Mood from "../../../shared/components/Mood/Mood";
import ActivityForm from "../../../shared/components/Admin/ActivityForm";
import Subscription from "../../../shared/components/Subscription/Subscription";
import LegalMentions from "../../../shared/components/Legal/LegalMentions";
import SuperRoom from "../../../shared/components/Rooms/SuperRoom";

import { motion } from "framer-motion/dist/framer-motion";
import ActivityIndicator from "../../../shared/designSystem/ActivityIndicator/ActivityIndicator";


import { replaceCustomVariablesInString } from "../../../shared/utils/StringUtils";
import MainStyle from "../../../shared/styles/game/MainStyle";
import User from "../../../shared/data/user/User";
import CategoryHomeStyle from "../../../shared/styles/categories/CategoryHomeStyle";
import i18n from "../../../shared/utils/Localization/Localization";
import Modal from "../Modal/Modal";

interface MainProps {
    mainOptions: MainOptions,
    children: any
}

const Main = (props: MainProps) => {

    const appTheme: AppTheme = new AppTheme();
    const { mainOptions, children } = props;
    const {
        key,
        componentId = "",
        canGoBack = false,
        showNavBar = false,
        safeArea = false,
        showMenu = false,
        canBeDismissed = false,
        canBeHidden = false,
        title = "",
        rightButtonIcon = undefined,
        rightButtonAction = undefined } = mainOptions;

    const mainBackgroundColor = ((mainOptions.backgroundColor !== undefined) && (mainOptions.backgroundColor.length > 0)) ? mainOptions.backgroundColor : appTheme.backgroundColor;
    const navigationColor = ((mainOptions.navigationColor !== undefined) && (mainOptions.navigationColor.length > 0)) ? mainOptions.navigationColor : appTheme.navigationColor;
    const navigationItemColor = ((mainOptions.navigationItemColor !== undefined) && (mainOptions.navigationItemColor.length > 0)) ? mainOptions.navigationItemColor : appTheme.navigationItemColor;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isTeacher, setIsTeacher] = useState<boolean | undefined>(undefined);

    const images: Images = new Images;
    const mainStyle: MainStyle = new MainStyle();
    const categoryHomeStyle: CategoryHomeStyle = new CategoryHomeStyle();
    const user: User = User.getInstance();


    const [backgroundColor, setBackgroundColor] = useState(appTheme.daySkyColor);
    const [modalOptions, setModalOptions] = useState(undefined)
    const [modalIsVisible, setModalIsVisible] = useState(false);

    const navBarHeightWithoutTop = appTheme.pixelPerfect(54);
    const navBarHeight = navBarHeightWithoutTop;

    let navigate = useNavigate();
    let location = useLocation();

    // ComponentDidMount
    useEffect(() => {
        EventRegister.addEventListener(Constants.EventShowModal, (item) => {
            setModalOptions(item);
        });
        EventRegister.addEventListener(Constants.EventDismissmodal, (item) => {
            setModalOptions(undefined);
        });
        return () => {
            EventRegister.removeEventListener(Constants.EventDismissmodal);
            EventRegister.removeEventListener(Constants.EventShowModal);
        };
    }, []);

    useEffect(() => {
        if (modalOptions === undefined) {
            setModalIsVisible(false);
        } else {
            setModalIsVisible(true);
        }
    }, [modalOptions]);

    const onViewDidAppear = () => {
        if (isLoading === true) {
            checkUserType();
        }
    }

    const checkUserType = async () => {
        const userType: boolean | undefined = await isUserATeacher();
        if (userType !== undefined) {
            setIsTeacher(userType);
        } else {
            setIsTeacher(false);
        }
    }

    useEffect(() => {
        if (isTeacher !== undefined) {
            if (isTeacher === true) {
                setBackgroundColor(appTheme.schoolColor);
            } else {
                setBackgroundColor(appTheme.homeColor);
            }
            setIsLoading(false);
        }
    }, [isTeacher]);

    const goBack = () => {
        Navigation.pop({
            componentId,
            navigate
        });
    }

    const goHome = () => {
        if (isTeacher === true) {
            navigate(Constants.ScreenAppMenu + "/" + Constants.CONTEXT.teacher);
        } else {
            navigate(Constants.ScreenAppMenu + "/" + Constants.CONTEXT.family);
        }
    }

    const dismissModal = () => {
        const { canBeHidden = false } = mainOptions;
        if (canBeHidden === true) {
            Navigation.dismissModalInView({
                componentId
            });
        } else {
            Navigation.dismissModal({
                componentId,
                navigate
            });
        }
    }

    // Nouvelle navbar
    const getLeftButton = () => {
        // Si on est en mobile, on a un bouton retour
        // En web un bouton nous ramenant au menu
        const buttonSize = Platform.OS === "web" ? appTheme.pixelPerfect(50) : appTheme.pixelPerfect(30);
        const rightButtonWidth = appTheme.pixelPerfect(120);
        let imageForLeftButton = images.icLiliAtSchoolCircle;
        if (user.profile === "family") {
            imageForLeftButton = images.icLiliAtHomeCircle;
        } else if (user.profile === "animator") {
            imageForLeftButton = images.icLiliAtExtracurricularCircle;
        }
        const leftButtonContent = <Image source={imageForLeftButton} style={{ width: buttonSize, height: buttonSize }} />
        return <TouchableOpacity onPress={goBack} style={[{ width: rightButtonWidth, height: buttonSize, justifyContent: "center", alignItems: "center" }]}>
            <View style={[mainStyle.shadowed, { borderRadius: buttonSize / 2 }]}>
                {leftButtonContent}
            </View>
        </TouchableOpacity>
    }

    const goToSuperRoom = () => {
        Navigation.showModalInView({
            componentId,
            name: Constants.ScreenSuperRoom
        });
    }

    const getRightButton = () => {
        const imageSize = appTheme.pixelPerfect(40);
        const rightButtonWidth = appTheme.pixelPerfect(120);
        const buttonTitle = user.profile === "teacher" ? i18n.t("appMenu.teachers.button.title") : user.profile === "animator" ? i18n.t("appMenu.animators.button.title") : i18n.t("appMenu.parents.button.title");
        const buttonImage = user.profile === "teacher" ? images.icSuperTeachersRoom : images.icSuperParentsRoom;
        const paddingBottom = appTheme.bottomInsets > 0 ? appTheme.bottomInsets : appTheme.pixelPerfect(20);
        return <TouchableOpacity onPress={goToSuperRoom} style={{ width: rightButtonWidth, justifyContent: "space-between", alignItems: "center", height: appTheme.pixelPerfect(50) }}>
            <View style={[mainStyle.shadowed, { borderRadius: imageSize / 2 }]}>
                <Image source={buttonImage} style={{ width: imageSize, height: imageSize }} resizeMode="cover" />
            </View>
            <Text style={{ fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(3), textAlign: "center" }}>{buttonTitle}</Text>
        </TouchableOpacity>
    }

    const getNavBar = () => {
        if (showNavBar === false) {
            return <View />;
        }
        const titleToDisplay = replaceCustomVariablesInString(title);
        return <View style={[mainStyle.shadowed, categoryHomeStyle.navigationBar(appTheme.white)]}>
            <View style={categoryHomeStyle.navigationBarContent}>
                {getLeftButton()}
                <Text numberOfLines={2} style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: "Lili", fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center", color: Platform.OS === "web" ? appTheme.darkBlue : clr.txt }}>{titleToDisplay.replace("\\n", "\n")}</Text>
                {getRightButton()}
            </View>
        </View>
    }

    const navBar = () => {
        if (showNavBar === false) {
            return <View />;
        }
        if ((canGoBack === false) && (showMenu === false) && (canBeDismissed === false) && (canBeHidden === false)) {
            return <View />
        }
        let leftButton = <View style={{ width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30) }} />;
        let rightButton = <View style={{ width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30) }} />;
        const navbarBackgroundColor = showNavBar === true ? navigationColor : "transparent";
        const buttonBackgroundColor = showNavBar === true ? navigationColor : navigationItemColor;
        const itemColor = showNavBar === true ? navigationItemColor : navigationColor;
        if (canGoBack === true) {
            leftButton = <TouchableOpacity
                onPress={goBack}
                style={{
                    marginBottom: appTheme.pixelPerfect(5),
                    backgroundColor: buttonBackgroundColor,
                    width: appTheme.pixelPerfect(30),
                    height: appTheme.pixelPerfect(30),
                    borderRadius: appTheme.pixelPerfect(20),
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <SVGView Component={icBack} size={appTheme.pixelPerfect(24)} color={itemColor} />
            </TouchableOpacity>;
        }
        // Sur le web, si le state de la page est deeplink, alors on mettre un bouton "menu" pour revenir à l'accueil
        if (location.state === "deeplink") {
            leftButton = <TouchableOpacity
                onPress={goHome}
                style={{
                    marginBottom: appTheme.pixelPerfect(5),
                    backgroundColor: buttonBackgroundColor,
                    width: appTheme.pixelPerfect(30),
                    height: appTheme.pixelPerfect(30),
                    borderRadius: appTheme.pixelPerfect(20),
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <SVGView Component={icBack} size={appTheme.pixelPerfect(24)} color={itemColor} />
            </TouchableOpacity>;
        }

        if ((canBeDismissed === true) || (canBeHidden === true)) {
            rightButton = <TouchableOpacity onPress={dismissModal} style={{ marginBottom: appTheme.pixelPerfect(5), backgroundColor: "#F00", width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(15), justifyContent: "center", alignItems: "center" }}>
                <SVGView Component={icDismiss} size={appTheme.pixelPerfect(20)} color={itemColor} />
            </TouchableOpacity>;
        } else if ((rightButtonIcon !== undefined) && (rightButtonAction !== undefined)) {
            rightButton = <TouchableOpacity onPress={rightButtonAction} style={{ marginBottom: appTheme.pixelPerfect(5), backgroundColor: buttonBackgroundColor, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(20), justifyContent: "center", alignItems: "center" }}>
                <SVGView Component={rightButtonIcon} size={appTheme.pixelPerfect(20)} color={itemColor} />
            </TouchableOpacity>;
        }
        const middleViewWidth = appTheme.getFullAppWidth() - appTheme.pixelPerfect(80);
        let middleView = <View style={{ width: middleViewWidth, height: navBarHeightWithoutTop }} />;
        if (showNavBar === true) {
            const textAlign = "center";
            const titleAlign = "center";
            middleView = <View style={[{ width: middleViewWidth, height: navBarHeightWithoutTop, justifyContent: "center", alignItems: titleAlign }]}>
                <Text style={[{ fontFamily: appTheme.primaryFont, color: navigationItemColor, textAlign, fontSize: appTheme.pixelPerfectForFont(12) }]} >{title.toLocaleUpperCase()}</Text>
            </View>;
        }

        const shadowStyle = showNavBar === true ? Style.navBarShadowed : {};

        return <View style={[styles.navBar, shadowStyle, { width: appTheme.getFullAppWidth(), backgroundColor: navbarBackgroundColor, height: navBarHeight }]}>
            <View style={{ flexDirection: "row", width: appTheme.getFullAppWidth(), backgroundColor: navbarBackgroundColor, height: navBarHeightWithoutTop, alignItems: "center", justifyContent: "space-evenly" }}>
                {leftButton}
                {middleView}
                {rightButton}
            </View>
        </View>
    }

    let rightAbsoluteButton = <View />;
    if (showNavBar === false) {
        if ((canBeDismissed === true) || (canBeHidden === true)) {
            rightAbsoluteButton = <TouchableOpacity onPress={dismissModal} style={{ position: "absolute", top: appTheme.pixelPerfect(5), right: appTheme.pixelPerfect(5), marginBottom: appTheme.pixelPerfect(5), backgroundColor: appTheme.white, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(20), justifyContent: "center", alignItems: "center" }}>
                <SVGView Component={icDismiss} width={appTheme.pixelPerfect(20)} height={appTheme.pixelPerfect(20)} color={navigationColor} />
            </TouchableOpacity>;
        } else if ((rightButtonIcon !== undefined) && (rightButtonAction !== undefined)) {
            rightAbsoluteButton = <TouchableOpacity onPress={rightButtonAction} style={{ position: "absolute", top: appTheme.pixelPerfect(5), right: appTheme.pixelPerfect(5), marginBottom: appTheme.pixelPerfect(5), backgroundColor: appTheme.white, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(20), justifyContent: "center", alignItems: "center" }}>
                <SVGView Component={rightButtonIcon} size={appTheme.pixelPerfect(20)} color={navigationColor} />
            </TouchableOpacity>;
        }
    }

    let leftAbsoluteButton = <View />;
    if (showNavBar === false) {
        if (canGoBack === true) {
            leftAbsoluteButton = <TouchableOpacity onPress={goBack} style={{ position: "absolute", top: appTheme.pixelPerfect(5), left: appTheme.pixelPerfect(5), marginBottom: appTheme.pixelPerfect(5), backgroundColor: appTheme.white, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(20), justifyContent: "center", alignItems: "center" }}>
                <SVGView Component={icBack} width={appTheme.pixelPerfect(20)} height={appTheme.pixelPerfect(20)} color={navigationColor} />
            </TouchableOpacity>;
            if (location.state === "deeplink") {
                leftAbsoluteButton = <TouchableOpacity
                    onPress={goHome}
                    style={{ position: "absolute", top: appTheme.pixelPerfect(5), left: appTheme.pixelPerfect(5), marginBottom: appTheme.pixelPerfect(5), backgroundColor: appTheme.white, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(20), justifyContent: "center", alignItems: "center" }}>
                    <SVGView Component={icBack} size={appTheme.pixelPerfect(24)} color={navigationColor} />
                </TouchableOpacity>;
            }
        }
    }

    const getBackground = () => {
        const position = Platform.OS === "web" ? "fixed" : "absolute";
        return (<View style={{ position, top: 0, left: 0, width: appTheme.getFullScreenWidth(), height: "100%", backgroundColor: appTheme.schoolColor }} />);

    }

    const getMainViewStyle = () => {
        const isTeacherForm: boolean = props.isTeacherForm ?? false;
        const marginTop: number = mainOptions.showMenu === true ? 60 : 0;
        const childrenCointainerWidth = appTheme.getFullAppWidth();
        let mainViewStyle: any[] = [{ justifyContent: "center", alignItems: "center", width: childrenCointainerWidth, marginTop, flex: 1 }];
        if (isTeacherForm === false) {
            mainViewStyle.unshift(styles.webViewShadow);
        }
        return mainViewStyle;
    }

    const getModalContent = () => {
        if ((modalIsVisible === true) && (modalOptions !== undefined)) {
            const props = modalOptions.passProps;
            if (modalOptions.name === Constants.ScreenAbout) {
                return <About {...props} />
            } else if (modalOptions.name === Constants.ScreenUserProfile) {
                return <UserProfile {...props} />
            } else if (modalOptions.name === Constants.ScreenConsentNotification) {
                return <ConsentNotification {...props} />
            } else if (modalOptions.name === Constants.ScreenMood) {
                return <Mood {...props} />
            } else if (modalOptions.name === Constants.ScreenActivityForm) {
                return <ActivityForm {...props} />
            } else if (modalOptions.name === Constants.ScreenSubscription) {
                return <Subscription {...props} />
            } else if (modalOptions.name === Constants.ScreenLegalMentions) {
                return <LegalMentions {...props} />
            } else if (modalOptions.name === Constants.ScreenSuperRoom) {
                return <SuperRoom {...props} />
            }
        }
        return <View />
    }

    const getChildrenContent = () => {
        if (isLoading === true) {
            return <View onLayout={onViewDidAppear} style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator color={appTheme.talk} />
            </View>
        }

        // Si on a une barre de navigation, on doit décaler la vue
        const marginTop: number = showNavBar === true ? navBarHeight : 0;
        return <View style={[getMainViewStyle(), { backgroundColor: mainBackgroundColor, marginTop, paddingBottom: marginTop }]}>
            {children}
            <Modal
                transparent={true}
                visible={modalIsVisible}
                animationType="slide">
                <View style={{ height: appTheme.getFullAppHeight() }}>
                    {getModalContent()}
                </View>
            </Modal>
            {rightAbsoluteButton}
            {leftAbsoluteButton}
        </View>;
    }

    const paddingTop: number = showNavBar === true ? appTheme.pixelPerfect(50) : 0;

    return (
        <View style={[styles.main, { width: appTheme.getFullScreenWidth(), paddingTop, height: appTheme.getFullAppHeight() }]}>
            {getBackground()}
            {getChildrenContent()}
            {getNavBar()}
        </View>
    )

    return (
        <motion.div className={key} key={key} initial={{ x: initialX, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: exitX, opacity: 0 }} style={{ height: appTheme.getFullScreenHeight(), width: appTheme.getFullScreenWidth() }}>
            <View onLayout={onViewDidAppear} style={[styles.main, { width: appTheme.getFullScreenWidth() }]}>
                {getBackground()}
                {getChildrenContent()}
                {navBar()}
            </View>
        </motion.div>
    )
}

export default Main;

const styles = StyleSheet.create({
    main: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    navBar: {
        position: 'absolute',
        flexDirection: "row",
        alignItems: "flex-end"
    },
    navBarShadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    webViewShadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    }
});