import React from "react";

import {
    Text,
    View
} from "react-native";
import { getLanguage } from "../../../utils/Localization/Localization";
import AppTheme from "../../../utils/Theme";

type localizedString = {
    fr?: string
}

interface ActivityCellTitleProps {
    title?: localizedString,
    duration?: number,
    tags?: string,
    color?: string,
    width: number,
    categoryTitle?: string
}

const ActivityCellTitle = (props: ActivityCellTitleProps) => {

    const appTheme: AppTheme = new AppTheme();

    const { title, duration, tags, color = appTheme.darkBlue, width, categoryTitle } = props;

    // Titre de la catégorie
    let categoryTitleView = <View />;
    if (categoryTitle !== undefined) {
        categoryTitleView = <Text style={[{ flex: 1, fontFamily: appTheme.primarySemiBoldFont, color: color, fontSize: appTheme.pixelPerfectForFont(10), textAlign:"center" }]}>
            {categoryTitle.toLocaleUpperCase()}
        </Text>;
    }
    // Titre
    let titleView = <View />;
    if ((title !== undefined) && (title[getLanguage()] !== undefined)) {
        titleView = <Text style={[{ flex: 1, fontFamily: appTheme.primaryBoldFont, color, fontSize: appTheme.pixelPerfectForFont(16), marginTop: appTheme.pixelPerfect(5), textAlign:"center" }]}>
            {title[getLanguage()].toLocaleUpperCase()}
        </Text>;
    }
    // Durée
    let durationView = <View />;
    if ((duration !== undefined) && (duration > 0)) {
        const durationText = (duration / 60).toString() + " MIN";
        durationView = <View style={[{ marginVertical: appTheme.pixelPerfect(2), backgroundColor: appTheme.white, width: appTheme.pixelPerfect(80), borderRadius: appTheme.pixelPerfect(10), flexDirection: "row", justifyContent: "center", alignItems: "center", paddingHorizontal: appTheme.pixelPerfect(5) }]}>
            <Text style={{
                fontSize: appTheme.pixelPerfectForFont(6),
                color,
                fontFamily: appTheme.secondaryFont
            }}>{durationText}</Text>
        </View>;
    }
    // Tags
    let tagsView = <View />;
    if ((tags !== undefined) && (tags.length > 0)) {
        tagsView = <Text style={{  width, fontSize: appTheme.pixelPerfectForFont(10), fontFamily: appTheme.secondaryFont, textAlign: "center", color, paddingHorizontal: appTheme.pixelPerfect(10), marginTop: appTheme.pixelPerfect(10) }}>
            {tags}
        </Text>;
    }
    return <View style={{ width, alignItems: "center", paddingHorizontal: appTheme.pixelPerfect(20) }}>
        {categoryTitleView}
        {titleView}
        {durationView}
        {tagsView}
    </View>
}

export default ActivityCellTitle;