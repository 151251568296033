import React from "react";
import { useState, useEffect, useContext } from "react";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import Main from "../../../specific/components/Main/Main";
import i18n, { getLanguage } from "../../utils/Localization/Localization";

import {
    View,
    Text,
    Platform,
    TouchableOpacity,
    Modal,
    Animated,
    useWindowDimensions,
    ViewStyle,
    Image
} from 'react-native';

import AppTheme from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// UI
import Style from "../../utils/Style";

// Assets
import Images from "../../../specific/utils/Images";

// Data
import { LocalDBContext } from "../../data/LocalDB";

// SVG
import SVGView from "../../utils/SvgView";
import { default as IcHeart } from "../../assets/svg/icons/solid/heart.svg";
import { default as IcDownload } from "../../assets/svg/icons/solid/download.svg";
import { default as IcVideo } from "../../assets/svg/icons/solid/film.svg";
import { default as IcEdit } from "../../assets/svg/icons/solid/edit.svg";
import { default as IcBack } from "../../assets/svg/lili/ic_back_arrow.svg";


import { useParams, useNavigate } from "react-router-dom";
import User from "../../data/user/User";
import { getLocalActivities, getLocalCategories, getLocalOfflineActivities, getLocalStorage } from "../../../specific/utils/LocalStorage";
import { EventRegister } from "../../utils/EventListeners";
import LiliVideoPlayer from "../../../specific/components/VideoPlayer/LiliVideoPlayer";
import Activity, { getActivityAvalability } from "../../models/Activity";
import ActivityCellTitle from "./ActivityCells/ActivityCellTitle";
import ActivityCellSummary from "./ActivityCells/ActivityCellSummary";
import ActivityCellBody from "./ActivityCells/ActivityCellBody";
import ActivityCellActionAudio from "./ActivityCells/ActivityCellActionAudio";
import ActivityCellUserActions from "./ActivityCells/ActivityCellUserActions";
import ActivityCellActionGame from "./ActivityCells/ActivityCellActionGame";
import ActivityCellDoc from "./ActivityCells/ActivityCellActionDoc";
import ActivityCellQuote from "./ActivityCells/ActivityCellQuote";
import ActivityCellDouble from "./ActivityCells/ActivityCellActionDouble";
import ActivityCellMedia from "./ActivityCells/ActivityCellMedia";
import { getAppTarget } from "../../../specific/utils/Navigation/Host";
import { getContextDocumentUrlForActivityId, getDocumentUrlForActivityId, getVideoUrlForMediaId } from "../../../specific/services/AppWrite/AppWriteDatabase";
import SVGIconButton from "../../designSystem/Buttons/SVGIconButton";
import { propertiesEventCategory, trackEvent } from "../../services/Tracking/Analytics";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import ActivityCellContext from "./ActivityCells/ActivityCellContext";

type localizedString = {
    fr: string
}

type activityCellData = {
    title?: localizedString,
    duration?: number,
    tags?: string,
    summary_title?: localizedString,
    summary_body?: localizedString[],
    body?: localizedString,
    urls?: string[],
    quote?: localizedString,
    showDownloadOption?: boolean
}

type activityCell = {
    type: string;
    sub_type?: string;
    data: activityCellData;
}

interface ActivitySheetProps {
    starting_point?: number,
    inPager?: boolean,
    menu?: string,
    activityKey: string,
    categoryKey?: string,
    sectionKey?: string,
    context?: string,
    activities?: Activity[],
    webCloseRequest?: () => void,
    onCloseRequest?: () => void
}

const ActivitySheet = (props: ActivitySheetProps) => {

    let propsData = props;
    if (Platform.OS === "web") {
        if ((props !== undefined) && (props.inPager !== undefined) && (props.inPager === true)) {
            propsData = props;
        } else {
            propsData = useParams();
        }
    }
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const { menu = "classic", inPager = false, activityKey, sectionKey, categoryKey, context, webCloseRequest = undefined, onCloseRequest = undefined, starting_point } = propsData;

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const user: User = User.getInstance();

    const screenWidth: number = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();

    const HEADER_MAX_HEIGHT = appTheme.pixelPerfect(240);

    const { width } = useWindowDimensions();
    const [headerImageWidth, setHeaderImageWidth] = useState(screenWidth);
    const [viewDidAppear, setViewDidAppear] = useState(false);
    const [editionModeValue, setEditionModeValue] = useState(false);
    const [innerViewHeight, setInnerViewHeight] = useState<number>(appTheme.getFullAppHeight());

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: "",
        body: "",
        icon: IcHeart
    });
    const [videoUrl, setVideoUrl] = useState("");
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [section, setSection] = useState(undefined);
    const [category, setCategory] = useState([]);
    const [activity, setActivity] = useState<Activity | undefined>(undefined);
    const [activityData, setActivityData] = useState<activityCell[]>([]);
    const [yOffset, setYOffset] = useState(new Animated.Value(0));
    const [localCategories, setLocalCategories] = useState({});
    const localDBData = useContext(LocalDBContext);

    const onViewDidAppear = async () => {
        // On va récupérer les données des activités locales
        const localActivitiesFromStorage = await getLocalActivities();
        const activities = localActivitiesFromStorage.data;
        if (activities[activityKey] !== undefined) {
            const anActivity: Activity = new Activity(activities[activityKey]);
            setActivity(anActivity);
        } else {
            Navigation.pop({
                componentId,
                navigate
            });
        }

        // On va calculer la taille des éléments sur l'écran
        const screenFlexDirection = appTheme.getFlexDirectionForSplitScreen();
        let newHeaderImageWidth = appTheme.getFullAppWidth();
        if (screenFlexDirection === "row") {
            newHeaderImageWidth = screenWidth;
        }
        setHeaderImageWidth(newHeaderImageWidth);

        // On va récupérer les donnnées admin si l'utilisateur est admin
        if (user.admin === true) {
            try {
                const adminDataString = await getLocalStorage({ key: Constants.keyAdminData });
                if (adminDataString === null) {
                    setEditionModeValue(false);
                } else {
                    const adminData = JSON.parse(adminDataString);
                    if (adminData.editionMode !== undefined) {
                        setEditionModeValue(adminData.editionMode);
                    } else {
                        setEditionModeValue(false);
                    }
                }
            } catch (error) {
                setEditionModeValue(false);
            }
        }
    }

    // On va surveiller les changements de taille de l'écran
    useEffect(() => {
        // On va calculer la taille des éléments sur l'écran-
        let newHeaderImageWidth = screenWidth;
        setHeaderImageWidth(newHeaderImageWidth);
    }, [width]);

    useEffect(() => {
        // Affichage de la petite modale pour le téléchargement ou la mise en favoris
        if (modalContent.title.length > 0) {
            setShowModal(true);
            setTimeout(() => {
                setModalContent({
                    title: "",
                    body: "",
                    icon: IcHeart
                });
            }, 3000);
        } else {
            setShowModal(false);
        }
    }, [modalContent]);

    useEffect(() => {
        if (activity !== undefined) {
            //On va découper l'activité en section
            let activityCellData: activityCell = activity.getActivityArrayForDisplay(context, menu);
            setActivityData(activityCellData);
            getLocalCategoriesFromStorage();
        }
    }, [activity]);

    useEffect(() => {
        const sections = localDBData.sections.data;
        if (sections[sectionKey] !== undefined) {
            setSection(sections[sectionKey]);
        }
        if (localCategories[categoryKey] !== undefined) {
            setCategory(localCategories[categoryKey]);
        }
    }, [localCategories]);

    useEffect(() => {
        if (Object.keys(category).length > 0) {
            if ((inPager === false) || (Platform.OS === "web")) {
                trackEvent("activity_viewed", {
                    [propertiesEventCategory]: category.analytics_key,
                    content: activity?.action.type,
                    access: activity?.isPremium() === true ? "premium" : "free",
                    activity_name: activity?.analytics_key,
                    starting_point
                });
            }
            setViewDidAppear(true);
        }
    }, [category]);

    const getLocalCategoriesFromStorage = async () => {
        const localCategoriesFromStorage = await getLocalCategories();
        setLocalCategories(localCategoriesFromStorage.data);
    }

    useEffect(() => {
        if (videoUrl.length > 0) {
            setShowVideoModal(true);
        } else {
            setShowVideoModal(false);
        }
    }, [videoUrl]);

    

    // ComponentDidMount
    useEffect(() => {
        EventRegister.addEventListener("activitiesUpdated", (data) => {
            onViewDidAppear();
        });
        return () => {
            EventRegister.removeEventListener("activitiesUpdated");
        };
    }, []);

    // MainOptions
    const { componentId = "", isModal = false } = propsData;
    let navigationColor = section !== undefined ? section.clr.main : appTheme.daySkyColor;
    let actionColor = section !== undefined ? section.clr.action : appTheme.darkBlue;
    let navigationItemColor = section !== undefined ? section.clr.txt : appTheme.darkBlue;
    if (sectionKey !== undefined) {
        if (sectionKey === "still") {
            navigationColor = "#A2F2F8";
            actionColor = appTheme.darkBlue;
            navigationItemColor = appTheme.darkBlue;
        } else if (sectionKey === "talk") {
            navigationColor = appTheme.talk;
            actionColor = appTheme.talk;
            navigationItemColor = appTheme.white;
        } else if (sectionKey === "grow") {
            navigationColor = appTheme.grow;
            actionColor = appTheme.grow;
            navigationItemColor = appTheme.white;
        } else if (sectionKey === "super_program") {
            navigationColor = appTheme.darkBlue;
            actionColor = appTheme.darkBlue;
            navigationItemColor = appTheme.white;
        }
    }
    const backgroundColor = appTheme.backgroundColor;
    let categoryTitle = ((category.title !== undefined) && (category.title[getLanguage()] !== undefined)) ? category.title[getLanguage()] : "";
    if (category[user.profile] !== undefined) {
        if (category[user.profile].title !== undefined) {
            if (category[user.profile].title[getLanguage()] !== undefined) {
                categoryTitle = category[user.profile].title[getLanguage()];
            }
        }
    }
    // On va définir la couleur de la barre de navigation en fonction du profile de l'utilisateur pour certaines catégories
    if (categoryKey === "onboarding_advices") {
        if (user.profile === "teacher") {
            navigationColor = appTheme.schoolColor;
        } else if (user.profile === "animator") {
            navigationColor = appTheme.schoolColor;
        } else if (user.profile === "family") {
            navigationColor = appTheme.homeColor;
        }
    }
    const mainOptions: MainOptions = new MainOptions({
        key: "activity_sheet",
        componentId,
        backgroundColor,
        navigationColor,
        navigationItemColor,
        showNavBar: false,
        showMenu: false,
        safeArea: false,
        canGoBack: inPager === true ? false : !isModal,
        canBeDismissed: inPager === true ? false : isModal,
        title: categoryTitle,
        contentSize: innerViewHeight
    });

    const onFavoriteStatusChanged = (activityAddedToFav: boolean) => {
        if (activityAddedToFav === true) {
            setModalContent({
                title: i18n.t("activities.favorites.addAsFavTitle"),
                body: i18n.t("activities.favorites.addAsFavBody"),
                icon: IcHeart
            });
        } else {
            setModalContent({
                title: i18n.t("activities.favorites.removeAsFavTitle"),
                body: i18n.t("activities.favorites.removeAsFavBody"),
                icon: IcHeart
            });
        }
    }

    const onDownloadStatusChanged = (activityDownloaded: boolean) => {
        if (activityDownloaded === true) {
            setModalContent({
                title: i18n.t("activities.downloads.addTitle"),
                body: i18n.t("activities.downloads.addBody"),
                icon: IcDownload
            });
        } else {
            setModalContent({
                title: i18n.t("activities.downloads.removeTitle"),
                body: i18n.t("activities.downloads.removeBody"),
                icon: IcDownload
            });
        }
    }

    const showVideo = (url: string) => {
        setVideoUrl(url);
    }

    const getModalContent = () => {
        return <View style={[Style.shadowed, { paddingTop: appTheme.pixelPerfect(20), paddingBottom: appTheme.bottomInsets + appTheme.pixelPerfect(20), backgroundColor: actionColor, width: headerImageWidth, minHeight: appTheme.pixelPerfect(100), borderRadius: appTheme.pixelPerfect(10), alignItems: "center" }]}>
            <SVGView Component={modalContent.icon} size={appTheme.pixelPerfect(20)} color={appTheme.white} />
            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(20), textAlign: "center", fontFamily: appTheme.primaryMediumFont, fontSize: appTheme.pixelPerfectForFont(14), color: appTheme.white }}>
                {modalContent.title}
            </Text>
            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(20), textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(14), color: appTheme.white }}>
                {modalContent.body}
            </Text>

        </View>;
    }

    const openEditMode = () => {
        Navigation.showModalInView({
            componentId,
            name: Constants.ScreenActivityForm,
            passProps: {
                activity
            }
        });
    }

    const closeTheVideoModal = () => {
        setVideoUrl("");
    }

    const getVideoModal = () => {
        if (videoUrl.length > 0) {
            return <LiliVideoPlayer
                repeat={true}
                backgroundColor={actionColor}
                url={videoUrl}
                closeTheDocument={closeTheVideoModal} />
        }
        return <View />;
    }

    const activitiesCell = (cellData: { item: activityCell, index: string }) => {
        const { item, index } = cellData;
        const { type, data, sub_type } = item;
        let isActivityAvailable = getActivityAvalability(activity);
        let color: string = actionColor;
        // On va faire une exception sur le choix de la couleur pour les activités qui sont liées au profil de l'utilisateur
        if (categoryKey === "onboarding_advices") {
            if (user.profile === "teacher") {
                color = appTheme.schoolColor;
            } else if (user.profile === "animator") {
                color = appTheme.schoolColor;
            } else if (user.profile === "family") {
                color = appTheme.homeColor;
            }
        }
        if (type === "title") {
            const { title, duration, tags } = data;
            return <ActivityCellTitle categoryTitle={categoryTitle} title={title} duration={duration} tags={tags} color={color} width={headerImageWidth} />
        } else if (type === "summary") {
            const { summary_body, summary_title } = data;
            return <ActivityCellSummary body={summary_body} title={summary_title} color={color} width={headerImageWidth} isEnable={isActivityAvailable} />
        } else if (type === "context") {
            const { pdf_link } = data;
            const appTarget: string = getAppTarget();
            let pdfUrl: string = pdf_link;
            if (appTarget !== "public") {
                pdfUrl = getContextDocumentUrlForActivityId({ activity_id: activity.key });
            }
            return <ActivityCellContext activity={activity} title={"Contexte du récit"} activity_title={activity?.title[getLanguage()]} sectionKey={activity?.sectionKey} pdf={pdfUrl} color={color} width={headerImageWidth} isEnable={isActivityAvailable} />
        } else if (type === "body") {
            const { body } = data;
            return <ActivityCellBody body={body} color={color} width={headerImageWidth} />
        } else if (type === "header") {
            return <View style={{ height: HEADER_MAX_HEIGHT }} />;
        } else if (type === "quote") {
            const { quote } = data;
            return <ActivityCellQuote quote={quote} color={color} width={headerImageWidth} isEnable={isActivityAvailable} />
        } else if (type === "action") {
            if ((sub_type === "audio") || (sub_type === "virelangue") || (sub_type === "audio_chapters")) {
                const { urls, title } = data;
                return <ActivityCellActionAudio
                    sub_type={sub_type}
                    activity={activity}
                    componentId={componentId}
                    categoryKey={activity?.categoryKeys[0]}
                    title={title}
                    imgUrl={activity.img_url}
                    localImgPath={images[activity.app_image]}
                    categoryAnalyticsKey={category !== undefined ? category.analytics_key : ""}
                    sectionAnalyticsKey={section !== undefined ? section.analytics_key : ""}
                    context={context}
                    urls={urls}
                    color={color}
                    width={headerImageWidth}
                    isEnable={isActivityAvailable}
                    starting_point={starting_point} />
            } else if ((sub_type === "game") || (sub_type === "game_img") || (sub_type === "tuto") || (sub_type === "web") || (sub_type === "video")) {
                const { title } = data;
                let rules = [];
                if ((category.rules !== undefined) && (category.rules[getLanguage()] !== undefined)) {
                    rules = category.rules[getLanguage()];
                }
                return <ActivityCellActionGame
                    sub_type={sub_type}
                    activity={activity}
                    activityAnalyticsKey={activity?.analytics_key}
                    categoryAnalyticsKey={category !== undefined ? category.analytics_key : ""}
                    sectionAnalyticsKey={section !== undefined ? section.analytics_key : ""}
                    context={context}
                    componentId={componentId}
                    isEnable={isActivityAvailable}
                    title={title}
                    color={color}
                    width={headerImageWidth}
                    rules={rules}
                    starting_point={starting_point} />
            } else if (sub_type === "doc") {
                const { title } = data;
                return <ActivityCellDoc
                    sub_type={sub_type}
                    activity={activity}
                    activityAnalyticsKey={activity?.analytics_key}
                    categoryAnalyticsKey={category !== undefined ? category.analytics_key : ""}
                    sectionAnalyticsKey={section !== undefined ? section.analytics_key : ""}
                    context={context}
                    componentId={componentId}
                    isEnable={isActivityAvailable}
                    title={title}
                    color={color}
                    width={headerImageWidth}
                    starting_point={starting_point} />
            } else if (sub_type === "audio_steps") {
                const { title, mainAction } = data;
                return <ActivityCellDouble
                    activity={activity}
                    localImgPath={images[activity.app_image]}
                    activityAnalyticsKey={activity?.analytics_key}
                    categoryAnalyticsKey={category !== undefined ? category.analytics_key : ""}
                    sectionAnalyticsKey={section !== undefined ? section.analytics_key : ""}
                    categoryKey={activity?.categoryKeys[0]}
                    context={context}
                    componentId={componentId}
                    mainAction={mainAction}
                    color={color}
                    width={headerImageWidth}
                    isEnable={isActivityAvailable}
                    starting_point={starting_point} />
            }
        } else if (type === "user_actions") {
            const { showDownloadOption } = data;
            return <ActivityCellUserActions
                showDownloadOption={showDownloadOption}
                componentId={componentId}
                categoryAnalyticsKey={category.analytics_key}
                activity={activity}
                color={color}
                width={headerImageWidth}
                onDownloadStatusChanged={onDownloadStatusChanged}
                onFavoriteStatusChanged={onFavoriteStatusChanged} />
        } else if ((type === "media") || (type === "media_title")) {
            let media = undefined;
            let title = "";
            if (type === "media") {
                if (data.media !== undefined) {
                    media = data.media;
                }
            } else {
                title = data.title;
            }
            return <ActivityCellMedia
                starting_point={starting_point}
                context={context}
                activity={activity}
                isEnable={isActivityAvailable}
                categoryKey={activity?.categoryKeys[0]}
                type={type}
                title={title}
                media={media}
                color={color}
                width={headerImageWidth} />
        }
        return <View />
    }

    const goBack = () => {
        if (webCloseRequest !== undefined) {
            webCloseRequest();
        } else if (onCloseRequest !== undefined) {
            onCloseRequest();
        }
    }

    const getActivityHeader = () => {
        if (activity === undefined) {
            return <View />
        }
        const imageWidth: number = Platform.OS === "web" ? screenWidth / 3 : screenWidth;
        const headerImageHeight: number = imageWidth * 27 / 36;
        let headerStyle: ViewStyle = {
            width: headerImageWidth,
            height: headerImageHeight + appTheme.pixelPerfect(20),
            alignItems: "center",
            backgroundColor: appTheme.white
        }
        let videoButton = <View />;
        if ((activity.header.type !== undefined) && (activity.header.type == "vid")) {
            if ((activity.header.url !== undefined) && ((activity.header.url.length > 0))) {
                const appTarget = getAppTarget();
                let videoUrl = activity.header.url;
                videoUrl = getVideoUrlForMediaId({ media_id: activity.key + "_header" });
                const rightPosition = Platform.OS === "web" ? imageWidth / 6 : appTheme.pixelPerfect(5);
                videoButton = <View style={{ position: "absolute", bottom: appTheme.pixelPerfect(5), right: rightPosition }}>
                    <SVGIconButton buttonSize={appTheme.pixelPerfect(30)} icon={IcVideo} onPress={() => showVideo(videoUrl)} color={navigationColor} />
                </View>;
            }
        }
        const editionButton = editionModeValue === false ? <View /> : <View style={{ position: "absolute", top: appTheme.pixelPerfect(10), right: appTheme.pixelPerfect(10) }}>
            <TouchableOpacity onPress={openEditMode} style={[Style.shadowed, { backgroundColor: appTheme.white, width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20) }]}>
                <View style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), justifyContent: "center", alignItems: "center", borderRadius: appTheme.pixelPerfect(20), backgroundColor: appTheme.white }}>
                    <SVGView Component={IcEdit} size={appTheme.pixelPerfect(20)} color={appTheme.talk} />
                </View>
            </TouchableOpacity>
        </View>;
        if (Platform.OS !== "web") {
            headerStyle.position = "absolute";
            headerStyle.top = 0;
            headerStyle.height = headerImageHeight;
        }
        const imageToDisplay = ((activity.app_image.length > 0) && (images[activity.app_image] !== undefined)) ? images[activity.app_image] : { uri: activity.img_url };
        const imageBorderRadius = Platform.OS == "web" ? appTheme.pixelPerfect(15) : 0;
        const backButton = Platform.OS == "web" ? <View style={{ position: "absolute", top: appTheme.pixelPerfect(10), left: appTheme.pixelPerfect(10) }}>
            <SVGIconButton buttonSize={appTheme.pixelPerfect(30)} icon={IcBack} onPress={goBack} color={"#354F84"} />
        </View> : <View />;
        return <Animated.View style={headerStyle}>
            <View style={{ backgroundColor: navigationColor, width: "100%", alignItems: "center", paddingTop: appTheme.topInsets }}>
                <Image nativeID={'image_' + activity.key + '_header'} source={imageToDisplay} style={{ width: imageWidth, height: headerImageHeight, borderRadius: imageBorderRadius }} resizeMode="cover" />
            </View>
            {videoButton}
            {editionButton}
            {backButton}
        </Animated.View>
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ flex: 1, width: screenWidth, flexDirection: "column-reverse" }}>
                <ActivityIndicator color={appTheme.darkBlue} />
            </View>
        }
        const borderRadius = Platform.OS === "web" ? appTheme.pixelPerfect(20) : 0;
        return <View style={{ borderRadius, overflow: "hidden" }}>
            <View style={{ flex: 1, width: screenWidth, flexDirection: "column-reverse" }}>
                <Animated.FlatList
                    bounces={false}
                    data={activityData}
                    renderItem={activitiesCell}
                    scrollEventThrottle={16}
                    style={{ backgroundColor: "#FFF", width: screenWidth, paddingBottom: appTheme.pixelPerfect(40), flex: 1 }}
                    onScroll={
                        Animated.event(
                            [
                                {
                                    nativeEvent: {
                                        contentOffset: { y: yOffset }
                                    }
                                }
                            ],
                            { useNativeDriver: false }
                        )
                    } />
                {getActivityHeader()}
            </View>
            <Modal animationType="slide" visible={showModal} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, alignItems: "center", backgroundColor: "transparent", width: appTheme.getFullScreenWidth() }}>
                    <View style={{ flex: 1, alignItems: "center", justifyContent: "flex-end", backgroundColor: "transparent", width: appTheme.getFullAppWidth() }}>
                        {getModalContent()}
                    </View>
                </View>
            </Modal>
            <Modal animationType="slide" visible={showVideoModal} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, alignItems: "center", backgroundColor: "transparent", width: appTheme.getFullScreenWidth() }}>
                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: actionColor + "80", height: appTheme.getFullAppHeight(), width: appTheme.getFullAppWidth() }}>
                        {getVideoModal()}
                    </View>
                </View>
            </Modal>
        </View>
    }

    if ((Platform.OS === "web") && (inPager === true)) {
        return getContent();
    }
    return (
        <Main mainOptions={mainOptions}>
            {getContent()}
        </Main>
    )
}

export default ActivitySheet;