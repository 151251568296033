module.exports = {
  "name": "lili",
  "version": "3.5.5",
  "build": "640",
  "description": "LILI WebApp written with RNWeb",
  "main": "index.tsx",
  "author": "Axel de Sainte Marie",
  "license": "MIT",
  "private": true,
  "scripts": {
    "clean": "rimraf dist",
    "start": "parcel serve ./public/index.html",
    "build": "parcel build ./public/index.html --no-cache --no-source-maps",
    "sharedlink": "yarn clean && rm -r ./src/shared && ln -s ../../jidokapp_core_mobile/app/shared ./src/shared",
    "sharedcopy": "yarn clean && rm -r ./src/shared && cp -r ../jidokapp_core_mobile/app/shared ./src/shared",
    "web": "yarn sharedlink && parcel serve ./public/index.html",
    "prod": "yarn sharedcopy && parcel serve ./public/index.html",
    "publish:centralperk": "firebase use lili-c7853 && firebase deploy --only hosting:centralperk && firebase use lili-dev-608f0",
    "publish:prod": "firebase use lili-c7853 && firebase deploy --only hosting:app && firebase use lili-dev-608f0",
    "publish:staging": "firebase use lili-dev-608f0 && firebase deploy --only hosting:staging"
  },
  "devDependencies": {
    "@babel/plugin-proposal-optional-chaining": "^7.21.0",
    "@babel/plugin-transform-runtime": "^7.21.4",
    "@babel/polyfill": "^7.12.1",
    "@babel/preset-react": "^7.18.6",
    "@babel/preset-typescript": "^7.18.6",
    "@parcel/config-default": "^2.12.0",
    "@parcel/transformer-typescript-tsc": "^2.12.0",
    "babel-loader": "^8.2.5",
    "babel-plugin-react-native-web": "^0.18.7",
    "parcel-bundler": "1.12.5",
    "parcel-plugin-static-files-copy": "^2.6.0",
    "regenerator-runtime": "^0.13.11",
    "ts-loader": "^9.2.6",
    "typescript": "^5.1.6",
    "url-loader": "^4.1.1"
  },
  "dependencies": {
    "@adjustcom/adjust-web-sdk": "^5.5.0",
    "@amplitude/analytics-browser": "^2.11.1",
    "@babel/core": "7.22.0",
    "@babel/plugin-proposal-class-properties": "^7.18.6",
    "@babel/plugin-proposal-private-methods": "^7.18.6",
    "@babel/preset-env": "^7.16.11",
    "@babel/runtime": "^7.21.5",
    "@ramonak/react-progress-bar": "^4.4.0",
    "@react-native-async-storage/async-storage": "1.17.0",
    "@stripe/react-stripe-js": "^2.1.0",
    "@stripe/stripe-js": "^1.52.1",
    "@types/react": "^16.8 || ^17.0 || ^18.0",
    "@types/react-dom": "^16.8 || ^17.0 || ^18.0",
    "@types/react-native": "^0.72.2",
    "appwrite": "^10.1.0",
    "babel-runtime": "^6.26.0",
    "base-64": "^1.0.0",
    "borodindk-react-js-pager": "^2.0.1",
    "core-js": "^3.30.2",
    "draft-js": "^0.11.7",
    "firebase": "^10.11.1",
    "framer-motion": "^10.12.10",
    "https-agent": "^1.0.0",
    "i18n-js": "3.8.0",
    "md5": "^2.3.0",
    "node-fetch": "2",
    "reac": "^0.0.0",
    "react": "^16.8.0 || ^17.0.0 || ^18.0.0",
    "react-alert": "^7.0.3",
    "react-calendar": "^5.0.0",
    "react-cas-client": "^1.1.0",
    "react-curved-text": "^1.0.0",
    "react-device-detect": "^2.2.2",
    "react-dom": "^16.8.0 || ^17.0.0 || ^18.0.0",
    "react-flipcard": "^0.2.1",
    "react-gradient": "^1.0.2",
    "react-h5-audio-player": "^3.8.5",
    "react-image-file-resizer": "^0.4.8",
    "react-modal": "^3.16.1",
    "react-native": "*",
    "react-native-qrcode-styled": "^0.3.0",
    "react-native-svg-web": "^1.0.9",
    "react-native-web": "^0.19.6",
    "react-native-web-hooks": "^3.0.2",
    "react-papaparse": "^4.1.0",
    "react-pdf": "^7.6.0",
    "react-redux": "^8.0.1",
    "react-router-dom": "6",
    "react-scripts": "^5.0.0",
    "react-select": "^5.8.0",
    "react-simple-video-player": "1.0.0",
    "react-sticky-el": "^2.0.9",
    "react-use-audio-player": "^1.2.5",
    "react-xml-parser": "^1.1.8",
    "redux": "^4",
    "remx": "*",
    "shorthash": "^0.0.2",
    "smoothscroll-polyfill": "^0.4.4",
    "type-detect": "^4.0.8"
  },
  "alias": {
    "react-native": "react-native-web",
    "react-native-svg": "react-native-svg-web"
  },
  "staticFiles": {
    "staticPath": "public",
    "watcherGlob": "**"
  }
}
;